/* eslint-disable prettier/prettier */
export const currencies = [
  { name: 'Algerian Dinar', value: 'DZD' },
  { name: 'Angolan Kwanza', value: 'AOA' },
  { name: 'Argentine Peso', value: 'ARS' },
  { name: 'Australian Dollar', value: 'AUD' },
  { name: 'Bahraini Dinar', value: 'BHD' },
  { name: 'Bangladeshi Taka', value: 'BDT' },
  { name: 'Botswana Pula', value: 'BWP' },
  { name: 'Brazilian Real', value: 'BRL' },
  { name: 'British Pound', value: 'GBP' },
  { name: 'Bulgarian Lev', value: 'BGN' },
  { name: 'Canadian Dollar', value: 'CAD' },
  { name: 'Central African CFA Franc', value: 'XAF' },
  { name: 'Chilean Peso', value: 'CLP' },
  { name: 'Chinese Yuan Renminbi', value: 'CNY' },
  { name: 'Colombian Peso', value: 'COP' },
  { name: 'Croatian Kuna', value: 'HRK' },
  { name: 'Czech Koruna', value: 'CZK' },
  { name: 'Danish Krone', value: 'DKK' },
  { name: 'Egyptian Pound', value: 'EGP' },
  { name: 'Ethiopian Birr', value: 'ETB' },
  { name: 'Euro', value: 'EUR' },
  { name: 'Ghanaian Cedi', value: 'GHS' },
  { name: 'Hong Kong Dollar', value: 'HKD' },
  { name: 'Hungarian Forint', value: 'HUF' },
  { name: 'Icelandic Krona', value: 'ISK' },
  { name: 'Indian Rupee', value: 'INR' },
  { name: 'Indonesian Rupiah', value: 'IDR' },
  { name: 'Iranian Rial', value: 'IRR' },
  { name: 'Iraqi Dinar', value: 'IQD' },
  { name: 'Israeli Shekel', value: 'ILS' },
  { name: 'Japanese Yen', value: 'JPY' },
  { name: 'Jordanian Dinar', value: 'JOD' },
  { name: 'Kenyan Shilling', value: 'KES' },
  { name: 'Kuwaiti Dinar', value: 'KWD' },
  { name: 'Lebanese Pound', value: 'LBP' },
  { name: 'Liberian Dollar', value: 'LRD' },
  { name: 'Libyan Dinar', value: 'LYD' },
  { name: 'Malagasy Ariary', value: 'MGA' },
  { name: 'Malaysian Ringgit', value: 'MYR' },
  { name: 'Mauritian Rupee', value: 'MUR' },
  { name: 'Mexican Peso', value: 'MXN' },
  { name: 'Moroccan Dirham', value: 'MAD' },
  { name: 'Mozambican Metical', value: 'MZN' },
  { name: 'Namibian Dollar', value: 'NAD' },
  { name: 'New Zealand Dollar', value: 'NZD' },
  { name: 'Nigerian Naira', value: 'NGN' },
  { name: 'Norwegian Krone', value: 'NOK' },
  { name: 'Omani Rial', value: 'OMR' },
  { name: 'Pakistani Rupee', value: 'PKR' },
  { name: 'Peruvian Sol', value: 'PEN' },
  { name: 'Philippine Peso', value: 'PHP' },
  { name: 'Polish Zloty', value: 'PLN' },
  { name: 'Qatari Riyal', value: 'QAR' },
  { name: 'Romanian Leu', value: 'RON' },
  { name: 'Russian Ruble', value: 'RUB' },
  { name: 'Rwandan Franc', value: 'RWF' },
  { name: 'Saudi Riyal', value: 'SAR' },
  { name: 'Seychellois Rupee', value: 'SCR' },
  { name: 'Singapore Dollar', value: 'SGD' },
  { name: 'South African Rand', value: 'ZAR' },
  { name: 'South Korean Won', value: 'KRW' },
  { name: 'Sri Lankan Rupee', value: 'LKR' },
  { name: 'Sudanese Pound', value: 'SDG' },
  { name: 'Swedish Krona', value: 'SEK' },
  { name: 'Swiss Franc', value: 'CHF' },
  { name: 'Syrian Pound', value: 'SYP' },
  { name: 'Tanzanian Shilling', value: 'TZS' },
  { name: 'Thai Baht', value: 'THB' },
  { name: 'Tunisian Dinar', value: 'TND' },
  { name: 'Turkish Lira', value: 'TRY' },
  { name: 'Ugandan Shilling', value: 'UGX' },
  { name: 'United Arab Emirates Dirham', value: 'AED' },
  { name: 'United States Dollar', value: 'USD' },
  { name: 'Vietnamese Dong', value: 'VND' },
  { name: 'West African CFA Franc', value: 'XOF' },
  { name: 'Zambian Kwacha', value: 'ZMW' },
  { name: 'Zimbabwean Dollar', value: 'ZWL' },
]
