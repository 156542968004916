import { useState } from 'react'
import { linkMerchantAsPartOfAnotherMerchant, linkMerchantToOrganisation } from '../api'
import { useAuth } from '../auth'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import { getOrgByType, useOrgScope } from '../org-scope'

/**
 * LinkMerchantToParent a component that will link a merchant to a parent (either a contract or another merchant)
 */
const LinkMerchantToParent = ({ type, merchant, onSuccess, onCancel }) => {
  const { token } = useAuth()
  const { byID } = useOrgScope() // org scope for lookup
  const typed = getOrgByType(byID)
  const organisations = typed['organisation'] ?? []
  const merchants = typed['merchant'] ?? []

  const childIDs = new Set(
    merchants.filter((x) => x.children).flatMap((x) => x.children.map((y) => y.id))
  )

  const groupMerchants = merchants.filter((m) => !childIDs.has(m.id) && m.id != merchant.id)

  // The selected parent
  const [parent, setParent] = useState(type == 'organisation' ? merchant.organisation_id : '')
  const [linkParentLoading, setLinkParentLoading] = useState()
  const [failedLinkParent, setFailedLinkParent] = useState()

  const onLinkAsPartOf = async () => {
    setFailedLinkParent(null)
    setLinkParentLoading(true)
    try {
      if (type === 'merchant') {
        await linkMerchantAsPartOfAnotherMerchant(token, merchant.id, parent)
      } else if (type === 'organisation') {
        await linkMerchantToOrganisation(token, merchant.id, parent)
      }
      setLinkParentLoading(false)
      onSuccess()
    } catch (e) {
      setLinkParentLoading(false)
      setFailedLinkParent(`There was a problem linking this merchant ${e}`)
    }
  }

  return (
    <div className='link-parent'>
      <h3>Link {merchant.registered_name || '(un-named merchant)'} to a parent organisation</h3>
      <div className='content'>
        {linkParentLoading && <p className='loading'>Updating...</p>}
        {failedLinkParent && (
          <p className='error'>
            {failedLinkParent && failedLinkParent.code ? (
              <span>Failed with code: {failedLinkParent && failedLinkParent.code}</span>
            ) : (
              <span>
                Something went wrong - please try again, or{' '}
                <Link to='/support'>contact support</Link>
              </span>
            )}
          </p>
        )}

        <p>
          <label>Parent Type </label>
          <select disabled value={type}>
            <option value='organisation'>Organisation</option>
            <option value='merchant'>Merchant</option>
          </select>
        </p>

        <p>
          {type && type === 'organisation' && (
            <>
              <label>Select </label>
              <select onChange={(e) => setParent(e.target.value)} value={parent}>
                {organisations.map((org) => (
                  <option key={org.id} value={org.id}>
                    {org.name}
                  </option>
                ))}
              </select>
            </>
          )}
          {type && type === 'merchant' && (
            <>
              <label>Select </label>
              <select onChange={(e) => setParent(e.target.value)}>
                <option key={'none'} value=''>
                  select a group merchant
                </option>
                {groupMerchants.map((merchant) => (
                  <option key={merchant.id} value={merchant.id}>
                    {merchant.name}
                  </option>
                ))}
              </select>
            </>
          )}
        </p>
      </div>
      <p className='warning'>
        <em>
          <strong>WARNING:</strong> Linking a merchant to a parent means the parent will be able to
          see all of this merchant&apos;s transactions
        </em>
      </p>
      <footer className='actions'>
        <button onClick={onLinkAsPartOf} disabled={!parent || parent == merchant.organisation_id}>
          Link up
        </button>
        <button onClick={onCancel}>Cancel</button>
      </footer>
    </div>
  )
}

LinkMerchantToParent.propTypes = {
  // Type of link to perform
  type: PropTypes.string,
  // The merchant object
  merchant: PropTypes.object.isRequired,
  // Success callback handler
  onSuccess: PropTypes.func,
  // Cancel callback handler
  onCancel: PropTypes.func,
}

export default LinkMerchantToParent
