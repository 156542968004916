import { has, any, toPairs, keys } from 'ramda'

// Utility: for a users roles (roles:{roleName -> contexts}) determine if this user is an operator (which is our admin)
export const isOperator = (roles = {}) => has('operator', roles)

// Utility: for a users roles (roles:{roleName -> contexts}) determine if this user is an controller (e.g. Celoxo)
export const isController = (roles = {}) => has('controller', roles)

// Utility: for a users roles (roles:{roleName -> contexts}) determine if this user is a rules admin
export const isRulesAdmin = (roles = {}) => has('rules-admin', roles)

// Utility: for a users roles (roles:{roleName -> contexts}) determine if this user is an controller (e.g. Celoxo)
export const isControllerNoMerchant = (roles = {}) => has('controller-no-merchant', roles)

export const hideMerchants = (roles = {}) =>
  isControllerNoMerchant(roles) && roles['back-office']?.length < 2

export const maskMerchants = (roles = {}) =>
  isControllerNoMerchant(roles) && roles['back-office']?.length > 1

// for a users roles (roles:{roleName -> contexts}) determine if this user has the GDPR role
export const isGDPR = (roles = {}) => has('gdpr', roles)

// Utility: for a users roles (roles:{roleName -> contexts}) determine if this user has the KYC role
export const isKYC = (roles = {}) => has('kyc', roles) || hasScopedPermission('kyc', roles)

export const isPureMerchantFromContract = (id, roles = {}, byId = {}) => {
  const roleKeys = keys(roles)
  if (roleKeys.length > 1 || roleKeys[0] != 'back-office') {
    return false
  }

  if (any((x) => x.t != 'MerchantID', roles['back-office'])) {
    return false
  }

  return any((x) => byId[x.id]['contract-id'] != id)
}

// Utility: Checks if the user has any 'contract' roles
export const hasContextContractID = (roles = {}) =>
  roles != null && any((pair) => any(({ t }) => t == 'ContractID', pair[1]), toPairs(roles))

// Utility: Checks if the user can access refunds
// Warning - this doesn't check which scope they have access on!
export const hasRefundAccess = (roles = {}) => {
  var hasScopedRefundAccess = false
  roles['back-office']?.length >= 1 &&
    roles['back-office'].forEach((role) => {
      if (role?.p !== undefined && role.p.includes('refund')) {
        hasScopedRefundAccess = true
      }
    })
  return hasScopedRefundAccess
}

export const hasScopedPermission = (permission, roles = {}) => {
  var hasScoped = false
  roles['back-office']?.length >= 1 &&
    roles['back-office'].forEach((role) => {
      if (role?.p !== undefined && role.p.includes(permission)) {
        hasScoped = true
      }
    })
  return hasScoped
}

export const isMintMyne = (email) => {
  return email == 'gareth+serod@innostream.co' || email?.includes('mintmyne')
}
