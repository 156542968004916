import { useEffect, useState } from 'react'
import { getMerchants } from '../api'
import { useAuth } from '../auth'
import { path, values } from 'ramda'
import { Link, useHistory, useLocation } from 'react-router-dom'
import { parseMerchantFilters, updateSearchParamsWithFilters } from './filters'
import ActiveStatus from '../ActiveStatus'
import MerchantDateTime from './MerchantDateTime'
import DownloadCSVLink from './DownloadCSVLink'
import Header from '../Header'
import Footer from '../Footer'
import Helmet from 'react-helmet'

/* Filters */
import MerchantOrganisationIDFilter from './filters/MerchantOrganisationIDFilter'
import MerchantNameFilter from './filters/MerchantNameFilter'
import MerchantName from './MerchantName'
import MerchantOrganisation from './MerchantOrganisation'
import GroupMerchantFilter from './filters/GroupMerchantFilter'
import { useOrgScope } from '../org-scope'
/*
 * ListMerchants renders a high-level merchant listing which displays a filtered, paginated list of merchants
 */
const ListMerchants = () => {
  const { token } = useAuth()

  // Merchant data
  const [merchants, setMerchants] = useState({ loading: true, failed: false, data: {} })
  const { byID } = useOrgScope() // org scope for organisation lookup

  // Parse parameters into filter object (which drives filters UI state) Note: We are using the URL as the "state" here,
  // and updates perform a history replacement (so as not to mess up "back" button behaviour too much - though we may
  // want to apply a heuristic like small changes replace history, big changes push history)
  const location = useLocation()
  const history = useHistory()
  const urlParams = new URLSearchParams(location.search)
  const filter = parseMerchantFilters(urlParams)
  updateSearchParamsWithFilters(urlParams, filter)

  // How we fetch merchants from the back-end
  const fetchMerchants = async () => {
    setMerchants({ ...merchants, loading: true, failed: false })
    try {
      const data = await getMerchants(token, {}) // All filtering done client side for now

      //Sorting
      data.results.sort(function (a, b) {
        let textA = a.registered_name.toUpperCase()
        let textB = b.registered_name.toUpperCase()
        return textA < textB ? -1 : textA > textB ? 1 : 0
      })

      setMerchants({ ...merchants, loading: false, failed: false, data })
    } catch (failed) {
      setMerchants({ ...merchants, loading: false, failed })
    }
  }

  const filterMerchants = (merchants, filter) => {
    if (!merchants || !merchants.data || !merchants.data.results) {
      return []
    }
    const filteredMerchants = merchants.data.results.filter((merchant) => {
      const { registered_name, status, part_of_id, organisation_id } = merchant
      const {
        name_cnts: filterName,
        status: filterStatus,
        merchant_id: filterMerchantId,
        organisation_id: filterOrganisationId,
      } = filter
      return (
        (!filterName || registered_name.toLowerCase().includes(filterName.toLowerCase())) &&
        (!filterStatus || status === filterStatus) &&
        (!filterMerchantId || part_of_id === filterMerchantId) &&
        (!filterOrganisationId || organisation_id === filterOrganisationId)
      )
    })
    return filteredMerchants
  }

  // Short-term solution until we have proper pagination: This
  // powers a "Shot xxx more" button at the bottom, and appends it
  // to the current set. Importantly, it also disables live loading,
  // otherwise things would get interesting. And of course, if you scroll
  // to the top and change filters, you have to scroll down again and "Load more"
  // if you want to, i.e. data is replaced.
  const fetchMoreMerchants = async (page) => {
    setMerchants({ ...merchants, loading: true, failed: false })
    try {
      const addMerchants = await getMerchants(token, { filter, page })
      // Append data. Note: This totally ignores any 'newer' merchants that
      // may have appeared, so we also don't update top-level counts.
      const data = {
        ...merchants.data,
        results: [...merchants.data.results, ...addMerchants.results],
      }
      setMerchants({ ...merchants, loading: false, failed: false, data })
    } catch (failed) {
      setMerchants({ ...merchants, loading: false, failed })
    }
  }

  const shownMerchants = filterMerchants(merchants, filter)
  const organisations = values(byID).filter((x) => x.type == 'organisation')

  // How many more merchants are available to load?
  const loadableCount = Math.min(
    100,
    merchants &&
      merchants.data &&
      merchants.data.results &&
      merchants.data.results.length &&
      merchants.data.page &&
      merchants.data.page.total_count
      ? merchants.data.page.total_count - merchants.data.results.length
      : 100
  )

  // Event handler for 'Show xxx more' button that was clicked
  const handleLoadMore = () => {
    const lastID =
      merchants && merchants.data && merchants.data.results && merchants.data.results.length
        ? merchants.data.results[merchants.data.results.length - 1].id
        : undefined
    const page = { after: lastID, count: loadableCount }
    fetchMoreMerchants(page)
  }

  // How we apply a filter update. Filter state is entirely in the URL query parameters
  const setFilter = (filter = {}) => {
    const updUrlParams = updateSearchParamsWithFilters(urlParams, filter)
    history.replace({ pathname: location.pathname, search: updUrlParams })
  }

  // Fetch merchants: Initially, and every time filters change
  useEffect(() => {
    fetchMerchants()
  }, [])

  return (
    <section className='merchants'>
      <Header />
      <Helmet>
        <title>Merchants</title>
      </Helmet>
      <div className='content'>
        {merchants.loading && !merchants && <p className='loading'>Loading...</p>}
        {!merchants.loading && merchants.failed && (
          <p className='error'>
            Something went wrong - please try again, or <Link to='/support'>contact support</Link>.
          </p>
        )}
        {!merchants.loading && !merchants.failed && shownMerchants && (
          <div>
            <header className='controls'>
              <span className='summary'>
                Showing{' '}
                <strong className='count page_count'>
                  {merchants.data.results.length.toLocaleString()}
                </strong>
                {merchants.data.page &&
                  merchants.data.results.length !== merchants.data.page.total_count && (
                    <span>
                      of
                      <strong className='count total_count'>
                        {merchants.data.page.total_count.toLocaleString()}
                      </strong>
                    </span>
                  )}
                merchants
                {merchants.loading && <span className='loading'>updating...</span>}
              </span>
              <DownloadCSVLink displayCount={shownMerchants.length} filter={filter} />
              <Link to={`/merchants/credential-manager`} className='button-link'>
                <button className='action-button standard-button-link'>Merchant Credentials</button>
              </Link>
            </header>
            <table className='merchants'>
              <thead>
                <tr>
                  <th className='num'>#</th>
                  <th className='registered-name'>
                    <h6>Name</h6>
                    <MerchantNameFilter filter={filter} setFilter={setFilter} />
                  </th>
                  <th className='status'>
                    <h6>Status</h6>
                    {/*<StatusFilter filter={filter} setFilter={setFilter} />*/}
                  </th>
                  <th className='org-parents'>
                    <h6>Group Merchant</h6>
                    <GroupMerchantFilter
                      merchants={merchants.data.results}
                      filter={filter}
                      setFilter={setFilter}
                    />
                  </th>
                  <th className='id'>
                    <h6>Organisation</h6>
                    <MerchantOrganisationIDFilter
                      filter={filter}
                      setFilter={setFilter}
                      organisations={organisations}
                    />
                  </th>
                  <th className='time'>
                    <h6>Created</h6>
                  </th>
                  <th className='time'>
                    <h6>Updated</h6>
                  </th>
                  <th className='actions'>
                    <button
                      title={'Add a new merchant'}
                      onClick={() => history.push('/create/merchant')}
                    >
                      <i className='fas fa-plus' />
                    </button>
                  </th>
                </tr>
              </thead>
              <tbody>
                {shownMerchants.map((merchant, i) => {
                  const detailURI = `/merchant/${merchant.id}`
                  return (
                    <tr className='merchant' key={i}>
                      <td className='num'>{i + 1}.</td>
                      <td className='name clickable' onClick={() => history.push(detailURI)}>
                        {merchant.registered_name || '-'}
                      </td>
                      <td className='status'>
                        <ActiveStatus {...merchant} />
                      </td>
                      <td className='part-of'>
                        {/* TODO Replace with tree from the context */}
                        {merchant.part_of_id ? (
                          <MerchantName
                            id={merchant.part_of_id}
                            merchants={merchants?.data?.results}
                          />
                        ) : (
                          <span>-</span>
                        )}
                      </td>
                      <td className='id'>
                        <MerchantOrganisation
                          merchantOrganisationId={merchant.organisation_id}
                          organisations={organisations}
                        />
                      </td>
                      <td className='time'>
                        <MerchantDateTime at={merchant.created_at} relativeTime={true} />
                      </td>
                      <td className='time'>
                        <MerchantDateTime at={merchant.last_updated_at} relativeTime={true} />
                      </td>
                      <td className='actions'>
                        <Link to={detailURI}>Detail</Link>
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
            <footer className='actions'>
              {merchants.data.page &&
                merchants.data.results.length !== merchants.data.page.total_count && (
                  <>
                    <button onClick={handleLoadMore} disabled={merchants.loading}>
                      {merchants.loading ? 'Loading...' : `Show ${loadableCount} more`}
                    </button>
                    {' or '}
                  </>
                )}
              <DownloadCSVLink
                displayCount={path(['page', 'total_count'], merchants.data)}
                filter={filter}
              />
            </footer>
          </div>
        )}
        {!merchants.loading &&
          !merchants.failed &&
          merchants.data &&
          merchants.data.results &&
          merchants.data.results.length === 0 && (
            <div className='no-results'>There are no merchants to show</div>
          )}
        <Footer />
      </div>
    </section>
  )
}

export default ListMerchants
