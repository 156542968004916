/* eslint-disable prettier/prettier */
import { createContext, useContext, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { getPSPs } from './api'
import { useAuth } from './auth'
import { isOperator, isController, isControllerNoMerchant, isMintMyne } from './auth-roles'

const PSPContext = createContext()

const mintMynePSPs = ['cardeye', 'checkout', 'intergiro', 'stripe', 'worldpay']

export const PSPProvider = ({ children }) => {
  const { token, expired, roles, email } = useAuth()
  const [psps, setPsps] = useState([])
  const isUserMintMyne = isMintMyne(email)

  const hasRequiredRoles = isOperator(roles) || isController(roles) || isControllerNoMerchant(roles)

  const fetchPSPs = async () => {
    if (hasRequiredRoles) {
      try {
        let result = await getPSPs(token)
        if (isUserMintMyne) {
          result = result.filter((x) => mintMynePSPs.includes(x.id))
        }
        setPsps(result.sort((a, b) => a.id.localeCompare(b.id)))
      } catch (error) {
        console.error('Failed to fetch PSPs', error)
      }
    } else {
      setPsps([])
    }
  }

  useEffect(() => {
    if (token && !expired) {
      fetchPSPs()
    }
  }, [token, expired])

  return <PSPContext.Provider value={{ psps }}>{children}</PSPContext.Provider>
}

PSPProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

export const usePSP = () => useContext(PSPContext)
