import { useRef } from 'react'
import PropTypes from 'prop-types'
import { useAuth } from '../../auth'
import { getAllowDenyCSVURL } from '../../api'

/** Renders a widget that, given a `filter` specification, allows the current user to download
 * allow/deny matching the filter, using the user's auth token. */
const DownloadCSVLink = ({ scope, filter, displayCount }) => {
  const { token } = useAuth()
  const formRef = useRef()
  if (displayCount === 0) {
    return null
  }

  let csvURL = getAllowDenyCSVURL(displayCount, filter, scope)
  return (
    <span className='download-csv'>
      <form method='POST' action={csvURL} target='_blank' ref={formRef}>
        <input type='hidden' name='auth_token' value={token} />
        <i className='fas fa-database download-csv-icon-color' />{' '}
        <a
          href=''
          title='Download all data matching the current filter as CSV'
          onClick={(e) => {
            e.preventDefault()
            if (formRef.current) {
              formRef.current.submit()
            }
            return false
          }}
        >
          Download {displayCount != null && displayCount.toLocaleString()} as CSV
        </a>
      </form>
    </span>
  )
}

DownloadCSVLink.propTypes = {
  filter: PropTypes.string,
  displayCount: PropTypes.number,
  scope: PropTypes.string,
}

export default DownloadCSVLink
