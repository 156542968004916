/* eslint-disable prettier/prettier */
import { useEffect, useState, useCallback } from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import Header from '../Header'
import Helmet from 'react-helmet'
import { getTransactions } from '../api'
import TimeFilter from '../filters/TimeFilter'
import { parseTransactionFilters, updateSearchParamsWithFilters } from '../txns/filters'
import { useAuth } from '../auth'
import { DateTime as DT } from 'luxon'
import DateTime from '../DateTime'
import TxnStatus from '../txns/TxnStatus'
import TxnID from '../txns/TxnID'
import { Link } from 'react-router-dom'
import PSPFilter from '../txns/filters/PSPFilter'
import { isControllerNoMerchant, isMintMyne, isOperator } from '../auth-roles'

const ReconList = () => {
  const { token, roles, email } = useAuth()
  const location = useLocation()
  const history = useHistory()
  const urlParams = new URLSearchParams(location.search)
  const filter = parseTransactionFilters(urlParams)
  const timezone = DT.local().toFormat('ZZZZ')
  const inputDebounceTime = 2000
  const isUserOperator = isOperator(roles)
  const shouldShowMerchant = !isControllerNoMerchant(roles) && !isMintMyne(email)

  const [loading, setLoading] = useState(false)
  const [txns, setTxns] = useState()
  const [failed, setFailed] = useState(null)
  const [txnIdInput, setTxnIdInput] = useState(filter.txn_id || '')
  const [pspTxnIdInput, setPspTxnIdInput] = useState(filter.psp_txn_id || '')
  const [showMIDs, setShowMIDs] = useState(false)

  const recon = new Map()
  recon.set('not_found,mismatch_amount,mismatch_currency,mismatch_status', 'All')
  recon.set('not_found', 'Not Found')
  recon.set('mismatch_status', 'Mismatch [Status]')
  recon.set('mismatch_currency', 'Mismatch [Currency]')
  recon.set('mismatch_amount', 'Mismatch [Amount]')

  const getReconStatusClass = (status) => {
    switch (status) {
      case 'not-found':
        return 'recon-status-not-found'
      case 'mismatch-status':
        return 'recon-status-mismatch-status'
      case 'mismatch-currency':
        return 'recon-status-mismatch-currency'
      case 'mismatch-amount':
        return 'recon-status-mismatch-amount'
      default:
        return ''
    }
  }

  const loadableCount = Math.min(
    100,
    txns && txns.results && txns.results.length && txns.page && txns.page.total_count
      ? txns.page.total_count - txns.results.length
      : 100
  )

  const setFilter = useCallback(
    (newFilter = {}) => {
      const updatedUrlParams = updateSearchParamsWithFilters(urlParams, newFilter)
      history.replace({ pathname: location.pathname, search: updatedUrlParams })
    },
    [history, location.pathname, urlParams]
  )

  const fetchTxns = useCallback(async () => {
    setLoading(true)
    try {
      const result = await getTransactions(token, { email, filter })
      if (result) {
        setTxns(result)
      }
    } catch (error) {
      setFailed(error)
    } finally {
      setLoading(false)
    }
  }, [filter, token])

  const handleReconStatusChange = (e) => {
    const newStatus = e.target.value.split(',')
    setFilter({ ...filter, recon_status_any: newStatus })
  }

  const handleTxnIdChange = (e) => setTxnIdInput(e.target.value)
  const handlePspTxnIdChange = (e) => setPspTxnIdInput(e.target.value)

  const handleLoadMore = () => {
    const lastID =
      txns && txns.results && txns.results.length
        ? txns.results[txns.results.length - 1].transaction_id
        : undefined
    const page = { after: lastID, count: loadableCount }
    fetchMoreTxns(page)
  }

  const fetchMoreTxns = async (page) => {
    setLoading(true)
    setFailed(false)
    try {
      const addTxns = await getTransactions(token, { email, filter: filter, page })
      const newTxns = {
        ...txns,
        results: [...txns.results, ...addTxns.results],
      }
      setTxns(newTxns)
    } catch (failed) {
      setFailed(failed)
    }
    setLoading(false)
  }

  useEffect(() => {
    const debounceTimer = setTimeout(() => {
      setFilter({ ...filter, txn_id: txnIdInput })
    }, inputDebounceTime)

    return () => clearTimeout(debounceTimer)
  }, [txnIdInput])

  useEffect(() => {
    const debounceTimer = setTimeout(() => {
      setFilter({ ...filter, psp_txn_id: pspTxnIdInput })
    }, inputDebounceTime)

    return () => clearTimeout(debounceTimer)
  }, [pspTxnIdInput])

  useEffect(() => {
    fetchTxns()
  }, [location.search])

  return (
    <section>
      <Header />
      <Helmet>
        <title>Recons</title>
      </Helmet>
      <div className='content'>
        {!failed && txns && txns.results && (
          <div>
            <header className='controls'>
              <span className='summary'>
                Showing{' '}
                <strong className='count page_count'>{txns.results.length.toLocaleString()}</strong>
                {txns.page && txns.results.length !== txns.page.total_count && (
                  <span className='total_count_transaction_header'>
                    of
                    <strong className='count total_count'>
                      {txns.page.total_count.toLocaleString()}
                    </strong>
                  </span>
                )}
                {loading && <span className='loading'>updating...</span>}
              </span>
            </header>
            <table className='txns'>
              <thead>
                <tr className='table-filter-row'>
                  <th className='time'>
                    <h6>Time ({timezone})</h6>
                    <TimeFilter filter={filter} setFilter={setFilter} />
                  </th>
                  {shouldShowMerchant && (
                    <th className='merchant'>
                      <h6>Merchant</h6>
                    </th>
                  )}
                  <th className='ID'>
                    <h6>Txn ID</h6>
                    <div className='filter'>
                      <input
                        type='text'
                        value={txnIdInput}
                        onChange={handleTxnIdChange}
                        placeholder='Transaction ID'
                      />
                    </div>
                  </th>
                  <th className='ID'>
                    <h6>Psp Txn ID</h6>
                    <div className='filter'>
                      <input
                        type='text'
                        value={pspTxnIdInput}
                        onChange={handlePspTxnIdChange}
                        placeholder='PSP Transaction ID'
                      />
                    </div>
                  </th>
                  <th>
                    <h6>
                      PSP
                      {isUserOperator ? (
                        <span
                          className='clickable psp-mid-toggle'
                          onClick={() => setShowMIDs(!showMIDs)}
                        >
                          <i className={showMIDs ? 'fas fa-eye-slash' : 'fas fa-eye'} />
                        </span>
                      ) : null}
                    </h6>
                    <PSPFilter filter={filter} setFilter={setFilter} />
                  </th>
                  <th className='ID'>
                    <h6>Txn Status</h6>
                  </th>
                  <th className='ID'>
                    <h6>Recon Status</h6>
                    <div className='filter status'>
                      <select
                        value={(filter.recon_status_any ?? []).sort().join(',')}
                        onChange={handleReconStatusChange}
                      >
                        {Array.from(recon.entries()).map(([key, value]) => (
                          <option key={key} value={key}>
                            {value}
                          </option>
                        ))}
                      </select>
                    </div>
                  </th>
                  <th></th>
                </tr>
              </thead>
              {txns?.results?.length !== 0 && (
                <>
                  {txns?.results?.map((txn) => {
                    const detailURI = `/transaction/${txn.transaction_id}`
                    return (
                      <tr className='txn' key={txn.transaction_id}>
                        <td
                          className='time clickable'
                          onClick={() => history.push(`/transaction/${txn.transaction_id}`)}
                        >
                          <DateTime at={txn.created_at} />
                        </td>
                        {shouldShowMerchant && <td className='merchant'>{txn.merchant_name}</td>}
                        <td className='id'>
                          <TxnID transaction_id={txn.transaction_id} order_ref={txn.order_ref} />
                        </td>
                        <td className='id'>
                          <div className='id-container'>
                            <div className='txn_id' title='Psp transaction id'>
                              <span className='id'>{txn.psp_transaction_id || '-'}</span>
                            </div>
                          </div>
                        </td>
                        <td>
                          {(showMIDs ? txn.psp_credential_id ?? txn.psp_id : txn.psp_id) ?? '-'}
                        </td>
                        <td className='status'>
                          <TxnStatus {...txn} />
                        </td>
                        <td className={`Recon Status ${getReconStatusClass(txn.recon_status)}`}>
                          <strong>{txn.recon_status}</strong>
                        </td>
                        <td className='actions'>
                          <Link to={detailURI}>Detail</Link>
                        </td>
                      </tr>
                    )
                  })}
                </>
              )}
            </table>
          </div>
        )}
        {!txns || !txns.results ? (
          <div className='loading'>Loading...</div>
        ) : (
          <>
            {txns?.results?.length === 0 && (
              <h3 className='no-recon'>No recon mismatches for selection.</h3>
            )}
            {failed && <p className='error'>Failed to load transactions: {failed.message}</p>}
            <footer className='actions'>
              {txns?.page && txns?.results.length !== txns?.page.total_count && (
                <>
                  <button onClick={handleLoadMore} disabled={loading}>
                    {loading ? 'Loading...' : `Show ${loadableCount} more`}
                  </button>
                </>
              )}
            </footer>
          </>
        )}
      </div>
    </section>
  )
}

export default ReconList
