import './routingRules.scss'
import Header from '.././Header'
import Helmet from 'react-helmet'
import { useEffect, useState } from 'react'
import { getFlexibleRules, getFlexibleRulesById, setRulesLive } from '../api'
import { useAuth } from '../auth'
import LoadingSpinner from '../loading-spinner/loading-spinner'
import { parseRules } from './grule-editor/utils'
import { useParams, useHistory } from 'react-router-dom'
import CurrentRules from './tabs/CurrentRules'
import { formatRules } from './grule-editor/utils'
import { isOperator } from '../auth-roles'
import { OrgScopeRequired, getOrgByType, useOrgScope } from '../org-scope'

export const RoutingRules = () => {
  const { token } = useAuth()
  const { ruleset, id } = useParams()
  const history = useHistory()

  const [reload, setReload] = useState(0)
  const [loading, setLoading] = useState(true)
  const [routingRulesList, setRoutingRulesList] = useState()

  const [error, setError] = useState('')

  const forceReload = () => {
    setReload(reload + 1)
  }

  const loadRuleset = async (ruleset) => {
    // Loads the list of rule-versions for the given ruleset.
    setLoading(true)
    try {
      const result = await getFlexibleRules(token, ruleset)
      const rulesets = result?.rulesets
      setRoutingRulesList(rulesets)

      if (rulesets && rulesets.length > 0) {
        if (id === 'current') {
          console.log('current ruleset requested, finding best option')
          let rule = rulesets.find((x) => x.live)
          if (!rule) {
            rule = rulesets[0]
          }

          if (rule) {
            history.replace(`/routing-rules/${ruleset}/${rule.id}`)
          }
        }
        setLoading(false)

        return rulesets
      } else {
        history.replace(`/routing-rules/${ruleset}/new`)
        setLoading(false)
      }
    } catch (e) {
      setError(`Error loading rulesets: ${e?.details ?? 'no detail'}`)
    }
    setLoading(false)
  }

  const loadNewlySavedRulesVersion = async () => {
    const rulesVersions = await loadRuleset(ruleset)
    if (rulesVersions) {
      history.push(`/routing-rules/${ruleset}/${rulesVersions[0].id}`)
    }
  }

  useEffect(() => {
    ;(async () => {
      loadRuleset(ruleset)
    })()
  }, [ruleset, reload])

  if (error && error != '') {
    return <div className='error'>{error}</div>
  }

  return (
    <section>
      <Header />
      <Helmet>
        <title>Routing Rules</title>
      </Helmet>
      {loading ? (
        <div className='routing-spinner'>
          <LoadingSpinner />
        </div>
      ) : (
        <OrgScopeRequired>
          <RoutingRulesView
            routingRulesList={routingRulesList}
            reloadRoutingRulesList={forceReload}
            loadNewlySavedRulesVersion={loadNewlySavedRulesVersion}
          ></RoutingRulesView>
        </OrgScopeRequired>
      )}
    </section>
  )
}

export const getRulesOrganisations = (isUserOperator, byID, roles) => {
  // Fetch all the organisations the user has access to from the org tree.
  let typed = getOrgByType(byID)
  let organisations = typed['organisation'] ?? []

  if (
    !isUserOperator &&
    organisations &&
    Object.hasOwn(roles, 'back-office') &&
    Object.hasOwn(roles, 'rules-admin')
  ) {
    // For non-operators; we filter this list further by matching auth roles with a rules-admin permission.
    organisations = organisations.filter((org) =>
      roles['back-office'].some((role) => Object.hasOwn(role, 'id') && role.id == org.id)
    )
  }

  return organisations
}

const RoutingRulesView = ({
  routingRulesList,
  reloadRoutingRulesList,
  loadNewlySavedRulesVersion,
}) => {
  const { token, roles } = useAuth()
  const isUserOperator = isOperator(roles)
  const { byID } = useOrgScope()

  const { ruleset, id } = useParams()

  const [routingRules, setRoutingRules] = useState({ loaded: false })

  const [loading, setLoading] = useState(false)
  const [description, setDescription] = useState()
  const [errorHandler, setErrorHandler] = useState()
  const [editor, setEditor] = useState('editor')
  const [editingMode, setEditingMode] = useState('editor')

  const organisations = getRulesOrganisations(isUserOperator, byID, roles)

  const getRulesById = async (id) => {
    const numeric = parseInt(id, 10)
    if (numeric) {
      const rule = routingRulesList.find((x) => x.id === numeric)
      console.log(rule)
      setLoading(true)
      try {
        setRoutingRules({ loaded: false })
        const result = await getFlexibleRulesById(token, ruleset, rule.id)

        setDescription(rule?.description)
        if (result.type == 'grule') {
          setRoutingRules({
            loaded: true,
            rules: parseRules(result.text),
            plaintext: result.text,
            type: 'grule',
          })
        } else {
          setRoutingRules({
            loaded: true,
            plaintext: JSON.stringify(result.text, null, 4),
            type: 'json',
          })
          setEditingMode('plaintext')
          setEditor('plaintext')
        }
        setErrorHandler()
      } catch (e) {
        setErrorHandler(e?.details)
      }
      setLoading(false)
    }
  }

  const updateRoutingRules = (rules) => {
    setRoutingRules({ ...routingRules, rules: rules })
  }

  const updateRoutingPlaintext = (plaintext) => {
    setRoutingRules({ ...routingRules, plaintext: plaintext })
  }

  const changeEditor = (changeTo) => {
    if (editingMode !== 'plaintext' && changeTo === 'plaintext' && routingRules?.type === 'grule') {
      // convert current editing rules to plaintext
      setRoutingRules({
        ...routingRules,
        plaintext: formatRules(routingRules.rules, !ruleset.startsWith('scoring')),
        type: 'grule',
      })
      setEditingMode('plaintext')
    } else if (
      editingMode !== 'editor' &&
      changeTo === 'editor' &&
      routingRules?.type === 'grule'
    ) {
      // convert current editing rules to grule
      let rules = parseRules(routingRules.plaintext)
      if (rules.length === 0) {
        rules = [{ title: '', salience: 1000, description: '', when: '', then: [''], id: 1 }]
      }
      setRoutingRules({ ...routingRules, rules: rules, type: 'grule' })
      setEditingMode('editor')
    }

    setEditor(changeTo)
  }

  useEffect(() => {
    if (id == 'new') {
      // Populate with a placeholder ruleset, force to Grule for all new rules
      setRoutingRules({
        loaded: true,
        rules: [{ title: '', salience: 1000, description: '', when: '', then: [''], id: 1 }],
        plaintext: '',
        type: 'grule',
      })
      setLoading(false)
    } else if (id != 'current') {
      getRulesById(id)
    }
  }, [id])

  return (
    <div className='routing-content'>
      <div className='routing-rules-upload-section'>
        <h1 className='routing-rules-header'>Rules</h1>
        <CurrentRulesetSelect
          loading={loading}
          isUserOperator={isUserOperator}
          organisations={organisations}
        />
        <div className='routing-text-wrapper'>
          <div className='routing-pos-relative routing-col'>
            <RoutingRulesList
              routingRulesList={routingRulesList}
              reloadRoutingRulesList={reloadRoutingRulesList}
            />
          </div>
          <div className='routing-pos-relative routing-col'>
            <div className='Tabs'>
              <div className='nav-container'>
                <ul className='nav'>
                  {routingRules && routingRules.loaded && routingRules.type === 'json' ? (
                    <li
                      className={editor === 'plaintext' ? 'active-item' : ''}
                      onClick={() => changeEditor('plaintext')}
                    >
                      Ruleset
                    </li>
                  ) : null}
                  {routingRules && routingRules.loaded && routingRules.type === 'grule' ? (
                    <li
                      className={editor === 'editor' ? 'active-item' : ''}
                      onClick={() => changeEditor('editor')}
                    >
                      Editor
                    </li>
                  ) : null}
                  {routingRules && routingRules.loaded && routingRules.type === 'grule' ? (
                    <li
                      className={editor === 'plaintext' ? 'active-item' : ''}
                      onClick={() => changeEditor('plaintext')}
                    >
                      Plaintext
                    </li>
                  ) : null}
                </ul>
                <ul className='nav'>
                  {routingRules && routingRules.loaded && routingRules.type === 'grule' ? (
                    <>
                      <li
                        className={editor === 'merchant' ? 'active-item' : ''}
                        onClick={() => changeEditor('merchant')}
                      >
                        Merchants
                      </li>
                      <li
                        className={editor === 'routes' ? 'active-item' : ''}
                        onClick={() => changeEditor('routes')}
                      >
                        Routes
                      </li>
                      <li
                        className={editor === 'minor-codes' ? 'active-item' : ''}
                        onClick={() => changeEditor('minor-codes')}
                      >
                        Minor Codes
                      </li>
                    </>
                  ) : null}
                </ul>
              </div>
              <div
                className={`outlet ${
                  editor == 'editor' || editor == 'merchant' || editor == 'routes' || editor == 'minor-codes' ? 'scroll' : ''
                }`}
              >
                {loading ? (
                  <div className='routing-spinner'>
                    <LoadingSpinner />
                  </div>
                ) : null}
                {!loading ? (
                  <CurrentRules
                    routingRules={routingRules}
                    updateRoutingRules={updateRoutingRules}
                    updateRoutingPlaintext={updateRoutingPlaintext}
                    mode={editor}
                    loadNewlySavedRulesVersion={loadNewlySavedRulesVersion}
                    description={description}
                    setDescription={setDescription}
                    errorHandler={errorHandler}
                    setErrorHandler={setErrorHandler}
                  />
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const CurrentRulesetSelect = ({ loading, isUserOperator, organisations }) => {
  const history = useHistory()
  const { ruleset } = useParams()

  return (
    <select
      disabled={loading}
      value={ruleset}
      onChange={(e) => {
        history.push(`/routing-rules/${e.target.value}/current`)
      }}
      className='routing-rules-dropdown'
      name='rule-type'
    >
      {isUserOperator && (
        <optgroup label='Classification'>
          <option value='Classification'>Classification</option>
        </optgroup>
      )}
      <RulesOptgroup
        label='Routing'
        prefix='routing'
        isUserOperator={isUserOperator}
        organisations={organisations}
      />
      <RulesOptgroup
        label='Scoring'
        prefix='scoring'
        isUserOperator={isUserOperator}
        organisations={organisations}
      />
      {isUserOperator && (
        <optgroup label='Contracts'>
          <option value='Contract-1'>Contracts (1)</option>
          <option value='Contract-2'>Contracts (2)</option>
        </optgroup>
      )}
      <RulesOptgroup
        label='KYC'
        prefix='kyc'
        isUserOperator={isUserOperator}
        organisations={organisations}
      />
      {/* TODO:1629 Should fall away once all rules have been migrated to stages */}
      {isUserOperator && (
        <optgroup label='KYC (Legacy)'>
          <option value='KYC-1'>KYC (1)</option>
          <option value='KYC-2'>KYC (2)</option>
        </optgroup>
      )}
    </select>
  )
}

const RulesOptgroup = ({ label, prefix, isUserOperator, organisations }) => {
  const prologue = `${prefix}-prologue`
  const epilogue = `${prefix}-epilogue`
  return (
    <optgroup label={label}>
      {isUserOperator && <option value={prologue}>Prologue</option>}

      {organisations?.map((org) => {
        const value = `${prefix}-${org.id}`
        return (
          <option key={org.id} value={value}>
            {org.name} ({org.id})
          </option>
        )
      })}

      {isUserOperator && <option value={epilogue}>Epilogue</option>}
    </optgroup>
  )
}

const RoutingRulesList = ({ routingRulesList, reloadRoutingRulesList }) => {
  const { token } = useAuth()

  const history = useHistory()
  const { ruleset, id } = useParams()

  const numeric = parseInt(id, 10)

  const setLive = async (id) => {
    try {
      await setRulesLive(token, ruleset, id)
      reloadRoutingRulesList()
    } catch (failed) {
      console.log('could not set rules live: ', failed)
    }
  }

  return (
    <div className='table-container'>
      <table>
        <thead>
          <tr>
            <td className='routing-table-header'>Status</td>
            <td className='routing-table-header'>Creation Date</td>
            <td className='routing-table-header'>Deployment Date</td>
            <td className='routing-table-header'>Description</td>
            <td className='routing-table-header'>Action</td>
          </tr>
        </thead>
        <tbody>
          {routingRulesList?.map((rule) => {
            return (
              <tr key={rule.id} className={rule?.id === numeric ? 'active-rule-view' : null}>
                <td className='routing-table-data'>
                  <div
                    className={`${rule?.live ? 'status-green' : 'status-grey'} status-indicator`}
                  ></div>
                </td>
                <td className='routing-table-data'>{rule.created_at}</td>
                <td className='routing-table-data'>{rule.deployed_at}</td>
                <td className='routing-table-data'>{rule.description}</td>
                <td className='routing-table-data'>
                  <div className='routing-table-action-items'>
                    <button
                      onClick={() => history.push(`/routing-rules/${ruleset}/${rule.id}`)}
                      className='routing-table-action-button'
                      title='Display ruleset'
                    >
                      <i className='fas fa-eye'></i>
                    </button>
                    <button
                      onClick={() => setLive(rule.id)}
                      className='routing-table-action-button'
                      disabled={rule?.live}
                      title='Deploy ruleset'
                    >
                      <i className='fas fa-globe'></i>
                    </button>
                  </div>
                </td>
              </tr>
            )
          })}
        </tbody>
      </table>
    </div>
  )
}
