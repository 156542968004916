import DashboardIcon from 'jsx:./styles/themes/alt-toolbar-icons/Dashboard.svg'
import TransactionsIcon from 'jsx:./styles/themes/alt-toolbar-icons/Transactions.svg'
import AccountIcon from 'jsx:./styles/themes/alt-toolbar-icons/Account.svg'
import KYCIcon from 'jsx:./styles/themes/alt-toolbar-icons/KYC.svg'
import MerchantsIcon from 'jsx:./styles/themes/alt-toolbar-icons/Merchants.svg'
import WatchlistIcon from 'jsx:./styles/themes/alt-toolbar-icons/Watchlist.svg'
import SupportIcon from 'jsx:./styles/themes/alt-toolbar-icons/Support.svg'
import UsersIcon from 'jsx:./styles/themes/alt-toolbar-icons/Users2.svg'
import RulesIcon from 'jsx:./styles/themes/alt-toolbar-icons/Rules.svg'
import SideMenu from 'jsx:./styles/themes/alt-toolbar-icons/SideMenu.svg'
import RefundsIcon from 'jsx:./styles/themes/alt-toolbar-icons/Refunds.svg'
import ReconIcon from 'jsx:./styles/themes/alt-toolbar-icons/Recon.svg'

export const GetToolbarIcon = (theme, iconName) => {
  if (theme === 'pagora' || theme === 'leikur' || theme === 'luminarpay') {
    switch (iconName) {
      case 'dashboard':
        return (
          <div className='toolbar-icon'>
            <DashboardIcon />
          </div>
        )

      case 'transactions':
        return (
          <div className='toolbar-icon'>
            <TransactionsIcon />
          </div>
        )
      case 'refunds':
        return (
          <div className='toolbar-icon'>
            <RefundsIcon />
          </div>
        )

      case 'recon':
        return (
          <div className='toolbar-icon'>
            <ReconIcon />
          </div>
        )

      case 'kyc':
        return (
          <div className='toolbar-icon'>
            <KYCIcon />
          </div>
        )

      case 'merchants':
        return (
          <div className='toolbar-icon'>
            <MerchantsIcon />
          </div>
        )
      case 'users':
        return (
          <div className='toolbar-icon'>
            <UsersIcon />
          </div>
        )
      case 'rules':
        return (
          <div className='toolbar-icon'>
            <RulesIcon />
          </div>
        )
      case 'watchlist':
        return (
          <div className='toolbar-icon'>
            <WatchlistIcon />
          </div>
        )
      case 'support':
        return (
          <div className='toolbar-icon'>
            <SupportIcon />
          </div>
        )
      case 'account':
        return (
          <div className='toolbar-icon'>
            <AccountIcon />
          </div>
        )
      case 'mobile-menu':
        return (
          <div className='toolbar-icon mobile-menu-button'>
            <SideMenu />
          </div>
        )
      default:
        return <div>-</div>
    }
  } else {
    switch (iconName) {
      case 'dashboard':
        return <i className='fas fa-chart-line' />
      case 'transactions':
        return <i className='fas fa-th-list' />
      case 'refunds':
        return <i className='fas fas fa-credit-card' />
      case 'recon':
        return <i className='fas fa-sync' />
      case 'kyc':
        return <i className='fas fa-user-check' />
      case 'merchants':
        return <i className='fas fa-store' />
      case 'users':
        return <i className='fas fa-users' />
      case 'rules':
        return <i className='fas fa-globe' />
      case 'watchlist':
        return <i className='fas fa-eye'></i>
      case 'support':
        return <i className='fas fa-hands-helping' />
      case 'account':
        return <i className='fas fa-user' />
      case 'mobile-menu':
        return <i className='fas fa-bars mobile-menu-button' />

      default:
        return <div>-</div>
    }
  }
}
