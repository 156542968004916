import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Link, useHistory } from 'react-router-dom'
import { authenticateUser } from './api'
import { useAuth } from './auth'
import { useTheme } from './theme'
import Helmet from 'react-helmet'

/** SignIn requests user authentication, and after authentication, routes
  the user to a previously-recorded destination - or to the "home" view if
  none were set. */
const SignIn = ({ location }) => {
  const history = useHistory()
  const auth = useAuth()
  const urlParams = new URLSearchParams(location.search)
  const [email, setEmail] = useState(undefined)
  const [password, setPassword] = useState(undefined)
  const [loginError, setLoginError] = useState('')
  const theme = useTheme()
  const { fromURL } = location.state || { fromURL: '/' }

  // Strip return targets (used by sign-out from expired API token) from the URL. They're ugly.
  useEffect(() => {
    if (urlParams.get('from')) {
      history.push('/sign-in', { fromURL: urlParams.get('from') })
    }
  }, [window.location.search])

  // Handle login (which can be from this or another tab)
  useEffect(() => {
    if (!auth.expired) {
      history.push(fromURL)
    }
  }, [auth.token])

  const handleSubmit = () => {
    if (email && password) {
      setLoginError(null)
      authenticateUser(email, password)
        .then((res) => {
          if (!res || res.failure_code) {
            if (res.failure_code === 3) {
              setLoginError('Too many incorrect login attempts, please try again in 30 minutes')
            } else if (res.failure_code === 4) {
              setLoginError('Your password has expired after 90 days, please reset your password')
            } else {
              setLoginError('That did not work, please try again')
            }
          } else {
            auth.setToken(res.token, email)
          }
        })
        .catch((err) => {
          console.error(err)
        })
    } else {
      setLoginError('Please provide both email and password')
    }
  }

  const handleKeyPressed = (e) => {
    if (e.key === 'Enter') {
      handleSubmit()
    }
  }

  return (
    // TODO: See if `AuthenticatedRoute` set a user destination, and if so,
    // route user there upon login.
    <section className='sign-in'>
      <Helmet>
        <title>Sign In</title>
      </Helmet>
      <div className='content'>
        <h1>Sign In</h1>
        <div className='field'>
          <label htmlFor='email'>email</label>
          <input
            type='email'
            id='email'
            onChange={(e) => setEmail(e.target.value)}
            onKeyPress={handleKeyPressed}
            autoFocus
          />
        </div>
        <div className='field'>
          <label htmlFor='password'>password</label>
          <input
            type='password'
            id='password'
            onChange={(e) => setPassword(e.target.value)}
            onKeyPress={handleKeyPressed}
          />
        </div>
        <footer className='actions'>
          <button onClick={handleSubmit}>Submit</button>
          {loginError !== '' && <div className='error'>{loginError}</div>}
        </footer>
        <footer className='actions'>
          {theme.showRequestUserAccount && <Link to='/request-account'>Request user account</Link>}
          <Link to='/password-reset'>Reset password</Link>
        </footer>
      </div>
    </section>
  )
}
SignIn.propTypes = {
  location: PropTypes.object.isRequired,
}

export default SignIn
