import { useState } from 'react'
import PropTypes from 'prop-types'
import { toPairs, sortBy, path, reverse, compose } from 'ramda'
import classnames from 'classnames'
import { statusNames } from './txns/TxnStatus'
import { isOperator } from './auth-roles'
import { useAuth } from './auth'

const CountByStatus = ({ cnt_by_status = {}, cnt_by_recon_status = {} }) => {
  const toSortedCounts = compose(reverse, sortBy(path([1])), toPairs)
  if (!cnt_by_status) {
    return null
  }
  if (!cnt_by_recon_status) {
    return null
  }
  const [status, setStatus] = useState('status')
  // Sort from most to least
  const counts = toSortedCounts(cnt_by_status.value).filter(
    ([status]) => status !== 'debounce_failed'
  )
  const recons = toSortedCounts(cnt_by_recon_status.value)
  // Calc percentage of each
  // ...
  const { roles } = useAuth()
  const isUserOperator = isOperator(roles)
  const countWithPer = counts.map(([status, count]) => [
    status,
    count,
    (1 / cnt_by_status.total) * count,
  ])

  const reconWithPer = recons.map(([status, recon]) => [
    status,
    recon,
    (1 / cnt_by_status.total) * recon,
  ])

  // Don't render if there are no statuses
  if (counts.length === 0) {
    return null
  }

  const onChangeStatus = (e) => {
    setStatus(e.target.value)
  }

  const statusSelector = (
    <select value={status} onChange={onChangeStatus}>
      <option value='status'>Status</option>
      {isUserOperator && <option value='recon'>Recon</option>}
    </select>
  )

  return (
    <div className={`module count-by-status`}>
      {isUserOperator && (
        <label>
          Transactions by:
          <span className='controls'>{statusSelector}</span>
        </label>
      )}
      {!isUserOperator && <label>Transactions by Status</label>}
      {status == 'status' &&
        countWithPer.map(([status, count, percent]) => (
          <div key={status} className={classnames({ count: true, [status]: true })}>
            <span className='status'>{statusNames[status] || status}</span>
            <span className='percent'>
              {percent.toLocaleString(undefined, { style: 'percent' })}
            </span>
            <span className='val'>{count != null ? count.toLocaleString() : '-'}</span>
          </div>
        ))}
      {status == 'recon' &&
        reconWithPer.map(([status, count, percent]) => (
          <div key={status} className={classnames({ count: true, [status]: true })}>
            <span className='status'>{statusNames[status] || status}</span>
            <span className='percent'>
              {percent.toLocaleString(undefined, { style: 'percent' })}
            </span>
            <span className='val'>{count != null ? count.toLocaleString() : '-'}</span>
          </div>
        ))}
    </div>
  )
}
CountByStatus.propTypes = {
  cnt_by_status: PropTypes.object,
  cnt_by_recon_status: PropTypes.object,
}

export default CountByStatus
