import { useHistory, useLocation } from 'react-router-dom'

const KycOwnerSelect = ({ owner, owners }) => {
  const history = useHistory()
  const location = useLocation()

  const changeOwner = (owner) => {
    if (owner) {
      const currentPath = location.pathname
      const basePath = currentPath.split('/').slice(0, -1).join('/')
      const newPath = `${basePath}/${owner}`
      history.push(newPath)
    }
  }

  const orgOwners = owners.filter((x) => x.type == 'organisation')
  const merchantOwners = owners.filter((x) => x.type == 'merchant')

  return (
    <div className='kyc-owner-select'>
      <select value={owner} onChange={(event) => changeOwner(event.target.value)}>
        {orgOwners.length > 0 ? (
          <optgroup label='Organisations'>
            {orgOwners.map((o) => (
              <option key={o.id} value={o.id}>
                {o.name}
              </option>
            ))}
          </optgroup>
        ) : null}
        {merchantOwners.length > 0 ? (
          <optgroup label='Merchants'>
            {merchantOwners.map((o) => (
              <option key={o.id} value={o.id}>
                {o.name}
              </option>
            ))}
          </optgroup>
        ) : null}
      </select>
    </div>
  )
}

export default KycOwnerSelect
