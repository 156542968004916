import PropTypes from 'prop-types'
import classnames from 'classnames'
import { useOrgScope } from '../org-scope'

/**
 * OrganisationDescription renders the name of the organisation.
 */
const OrganisationDescription = ({ id }) => {
  const { byID } = useOrgScope()
  // if we can find the contract id, try and return contract name otherwise fallback to showing only id
  const description = (id) => {
    if (!id) {
      return '-'
    }

    return byID[id]?.name || id
  }

  return (
    <span className={classnames({ contract: true })}>
      <span className='name'>{description(id)}</span>
    </span>
  )
}

OrganisationDescription.propTypes = {
  id: PropTypes.string,
}

export default OrganisationDescription
