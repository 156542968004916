import { useState } from 'react'
import { getOrgByType, useOrgScope } from '../../org-scope'

export const MerchantListHelper = ({}) => {
  const { byID } = useOrgScope()

  const [selected, setSelected] = useState<string[]>([])

  // Fetch all the organisations the user has access to from the org tree.
  let typed = getOrgByType(byID)
  let merchants = typed['merchant'] ?? []

  return (
    <div>
      <h1>Merchants</h1>
      <div className='merchant-list'>
        {merchants.map((x) => (
          <label>
            <input
              type='checkbox'
              checked={selected.includes(x.id)}
              value={x.id}
              onChange={(e) => {
                // add or remove from selected on checkbox change
                if (selected.includes(x.id)) {
                  setSelected(selected.filter((y) => y !== x.id))
                } else {
                  setSelected([...selected, x.id])
                }
              }}
            ></input>{' '}
            {x.name}
          </label>
        ))}
      </div>
      <label className='merchant-list-helper'>
        Merchant List:{' '}
        <input
          type='text'
          readOnly={true}
          value={merchants
            .filter((x) => selected.includes(x.id))
            .map((x) => `"${x.name}"`)
            .join(', ')}
        ></input>
      </label>
    </div>
  )
}
