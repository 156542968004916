import PropTypes from 'prop-types'
import classnames from 'classnames'
import { RadialBarChart, RadialBar, Legend } from 'recharts'

/** Given the adjustment factor of a given category, and the total adjustment,
 * allocates "good", "ok" or "bad" based on the impact on the total adjustment.
 * This is because we don't have any categorization from the back-end.
 */
const classifyCategory = (adj, totalAdj) => {
  if (adj > 0) {
    return 'plus'
  }
  if (adj == 0 || totalAdj == 0) {
    return 'good'
  }
  const percent = (Math.abs(adj) / Math.abs(totalAdj)) * 100
  return percent > 25 ? 'bad' : percent > 5 ? 'ok' : 'good'
}

const ScoringResultsSummary = ({ grade, result = 0, max = 0, categories = [] }) => {
  const totalAdj = max - result
  categories.sort((a, b) => a.key.localeCompare(b.key))
  /* Score data */
  const data = categories.map(({ key, adj }) => {
    const cls = classifyCategory(adj, totalAdj)
    return {
      name: key,
      uv: Math.abs(adj),
      pv: result,
      fill: cls === 'plus' || cls === 'good' ? '#98E798' : cls === 'ok' ? '#E4E798' : '#E79899',
    }
  })

  return (
    <div className='score-result-summary'>
      {grade && max && (
        <div className={classnames({ grade: true, [grade]: true })}>
          <RadialBarChart
            width={300}
            height={150}
            cx={70}
            cy={70}
            innerRadius={10}
            outerRadius={70}
            barSize={8}
            data={data}
          >
            <RadialBar
              minAngle={15}
              /*label={{ position: 'insideStart', fill: '#fff' }}*/
              background
              clockWise
              dataKey='uv'
            />
            <Legend
              iconSize={10}
              iconType='circle'
              width={150}
              height={100}
              layout='vertical'
              verticalAlign='middle'
              wrapperStyle={{
                top: 0,
                left: 200,
              }}
            />
          </RadialBarChart>
        </div>
      )}
      {categories && categories.length ? (
        <div className='breakdown'>
          <table className='categories'>
            {categories.map(({ key, adj, rules }) => {
              const cat = classifyCategory(adj, totalAdj)
              return (
                <tbody className='summary' key={key}>
                  <tr
                    key={key}
                    className={classnames({
                      category: true,
                      [cat]: true,
                    })}
                  >
                    <td className='key'>
                      <i
                        className={classnames({
                          fas: true,
                          'fa-ban': cat === 'bad',
                          'fa-check': cat === 'good' || cat == 'plus',
                          'fa-thumbs-up': cat === 'ok',
                        })}
                      />{' '}
                      {key}
                    </td>
                    <td className='adj'>
                      {adj > 0 && '+'}
                      {adj}
                    </td>
                  </tr>
                  {rules &&
                    rules.length > 0 &&
                    rules.map(({ rule, adj }) => (
                      <tr key={rule} className={classnames({ detail: true, [cat]: true })}>
                        <td className='rule'>{rule}</td>
                        <td className='adj'>
                          {adj > 0 && '+'}
                          {adj}
                        </td>
                      </tr>
                    ))}
                </tbody>
              )
            })}
          </table>
        </div>
      ) : null}
      {/*
        <div className='categories'>
          {categories.map(({ key, adj }) => (
            <div
              key={key}
              className={classnames({ category: true, [classifyCategory(adj, totalAdj)]: true })}
            >
              <div className='key'>{key}</div>
              <div className='adj'>{adj}</div>
            </div>
          ))}
        </div>
        */}
    </div>
  )
}
ScoringResultsSummary.propTypes = {
  grade: PropTypes.string,
  result: PropTypes.number,
  max: PropTypes.number,
  categories: PropTypes.array,
}

export default ScoringResultsSummary
