import PropTypes from 'prop-types'

const MerchantOrganisation = ({ organisations, merchantOrganisationId }) => {
  const org = organisations.find((x) => x.id == merchantOrganisationId)

  return (
    <div className='id-container'>
      <div className='merchant_organisation_id' title={merchantOrganisationId}>
        <span className='id'>{org?.name ?? '-'}</span>
      </div>
    </div>
  )
}

MerchantOrganisation.propTypes = {
  organisations: PropTypes.array.isRequired,
  merchantOrganisationId: PropTypes.string,
}

export default MerchantOrganisation
