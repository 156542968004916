import Header from '../Header'
import Helmet from 'react-helmet'
import { Route, Redirect, Switch, NavLink, useRouteMatch } from 'react-router-dom'
import AuthenticatedRoute from '../AuthenticatedRoute'
import { useOrgScope, TYPE_ROOT, OrgScopeRequired } from '../org-scope'
import { useAuth } from '../auth'
import { isOperator, hasContextContractID } from '../auth-roles'

// Watchlist sections
import Forex from './forex/Forex'
import AllowDeny from './allowdeny/AllowDeny'

/** High-level container for the 'settings' section, which presents a separate side
 * menu, with nested sections, available to different roles.
 */
const Watchlist = () => {
  const { roles } = useAuth()
  const isUserOperator = isOperator(roles)
  const isContractUser = hasContextContractID(roles)

  const { path, url } = useRouteMatch()
  const allowDenyMatch = useRouteMatch(`${url}/allowdeny/:scope`)
  // Figure out what is the top-most org that current user can access, in order
  // to build e.g. direct links to sections. Otherwise, we have to rely on each section
  // doing some sort of redirect, which is messy.
  const { tree } = useOrgScope()
  const rootOrgID = tree ? tree[0]?.id : TYPE_ROOT // ID of the highest org the current user can see
  return (
    <section className='settings'>
      <Helmet>
        <title>Watchlist</title>
      </Helmet>
      <Header />
      <OrgScopeRequired>
        <div className='layout'>
          <nav>
            {(isUserOperator || isContractUser) && (
              <NavLink to={`${url}/allowdeny/${rootOrgID}`} isActive={() => allowDenyMatch != null}>
                <i className='fas fa-ban' />
                <span style={{ color: '#000' }}>Allow / Deny</span>
              </NavLink>
            )}
            {isUserOperator && (
              <NavLink to={`${url}/forex`}>
                <i className='fas fa-shekel-sign' />
                <span style={{ color: '#000' }}>Forex rates</span>
              </NavLink>
            )}
          </nav>
          <div className='content'>
            <Switch>
              <Route exact path={`${path}`}>
                {/* TODO: What if you aren't allowed to see allow/deny, where to direct to? */}
                <Redirect to={`${path}/allowdeny/${rootOrgID}`} />
              </Route>
              <AuthenticatedRoute path={`${path}/allowdeny/:scope`} component={AllowDeny} />
              <AuthenticatedRoute path={`${path}/forex`} component={Forex} />
            </Switch>
          </div>
        </div>
      </OrgScopeRequired>
    </section>
  )
}

export default Watchlist
