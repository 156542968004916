import { useState } from 'react'
import Header from '../Header'
import Helmet from 'react-helmet'
import { Link, useHistory } from 'react-router-dom'
import { registerMerchant, updateMerchantCurrencies } from '../api'
import { useAuth } from '../auth'
import { split, trim } from 'ramda'
import { useOrgScope, getOrgByType } from '../org-scope'
import MultiSelect from '../components/MultiSelect'
import { currencies } from '../utils/Currencies'

/** CreateMerchant probably renders a create merchant component */
const CreateMerchant = () => {
  const { token } = useAuth()
  const history = useHistory()
  const { byID } = useOrgScope() // org scope for lookup
  const typed = getOrgByType(byID)
  const organisations = typed['organisation'] ?? []
  const merchants = typed['merchant'] ?? []

  const childIDs = new Set(
    merchants.filter((x) => x.children).flatMap((x) => x.children.map((y) => y.id))
  )

  const groupMerchants = merchants.filter((m) => !childIDs.has(m.id))

  const [saving, setSaving] = useState(false)
  const [failed, setFailed] = useState()
  const [registeredName, setRegisteredName] = useState('')
  const [tradingAs, setTradingAs] = useState('')
  const [contacts, setContacts] = useState('')
  const [website, setWebsite] = useState('')
  const [link, setLink] = useState('')
  const [selectedOptions, setSelectedOptions] = useState([])

  // check parent link is valid, not set or set to contract/merchant and appropriate link is set
  const isLinkToParentValid = link !== ''

  const isEmailValid = (contacts ?? '').match(/^\S+@\S+(,\S+@\S+)*$/) != null

  const registerNewMerchant = async () => {
    setFailed(false)
    setSaving(true)
    try {
      // map to back-end style with contacts in the strange mailto format
      const emails = split(',', contacts)
      const contactURIs = emails.map((e) => `mailto:${trim(e)}`)
      const linkComponents = split('-', link)
      const merchant = {
        registered_name: registeredName,
        trading_as: tradingAs,
        contact_uri: contactURIs,
        uri: website,
        parent_org_node_type: linkComponents[0],
        parent_org_node_id: linkComponents[1],
      }
      const response = await registerMerchant(token, merchant)
      if (response?.merchant?.id && selectedOptions.length > 0) {
        let defaultCurrency = selectedOptions.includes('EUR') ? 'EUR' : selectedOptions[0]
        await updateMerchantCurrencies(
          token,
          selectedOptions,
          defaultCurrency,
          response.merchant.id
        )
      }
      history.push(`/merchant/${response.merchant.id}`)
    } catch (e) {
      setFailed(e)
    }
    setSaving(false)
  }

  return (
    <section className='create-merchant'>
      <Header />
      <Helmet>
        <title>Register a merchant</title>
      </Helmet>
      <div className='content'>
        <div className='merchant'>
          <h3>Register a new merchant</h3>
          <table>
            <tbody>
              <tr className='merchant_name'>
                <td className='key'>Registered Name</td>
                <td className='val'>
                  <input
                    type='text'
                    value={registeredName}
                    onChange={(e) => setRegisteredName(e.target.value)}
                    autoFocus={true}
                  />
                </td>
              </tr>
              <tr className='trading_as'>
                <td className='key'>Trading As</td>
                <td className='val'>
                  <input
                    type='text'
                    value={tradingAs}
                    onChange={(e) => setTradingAs(e.target.value)}
                  />
                </td>
              </tr>
              <tr className='contact_uri'>
                <td className='key'>Contact Emails</td>
                <td className='val'>
                  <input
                    type='text'
                    value={contacts}
                    pattern='\S+@\S+(,\S+@\S+)*'
                    onChange={(e) => setContacts(e.target.value)}
                  />
                </td>
              </tr>
              <tr className='uri'>
                <td className='key'>Website</td>
                <td className='val'>
                  <input type='text' value={website} onChange={(e) => setWebsite(e.target.value)} />
                </td>
              </tr>
              <tr>
                <td className='key'>Currencies</td>
                <td className='val'>
                  <MultiSelect
                    options={currencies}
                    selectedOptions={selectedOptions}
                    onChange={setSelectedOptions}
                  />
                </td>
              </tr>
              <tr className='uri'>
                <td className='key'>Link to parent</td>
                <td className='val'>
                  <select
                    value={link}
                    onChange={(e) => {
                      setLink(e.target.value)
                    }}
                  >
                    <option>Select a parent</option>
                    <optgroup label='Organisations'>
                      {organisations.map((org) => (
                        <option key={`organisation-${org.id}`} value={`organisation-${org.id}`}>
                          {org.name}
                        </option>
                      ))}
                    </optgroup>
                    <optgroup label='Merchants'>
                      {groupMerchants.map((merchant) => (
                        <option
                          key={`organisation-${merchant.id}`}
                          value={`merchant-${merchant.id}`}
                        >
                          {merchant.name}
                        </option>
                      ))}
                    </optgroup>
                  </select>
                </td>
              </tr>
            </tbody>
          </table>
          {saving && <p className='saving'>Saving...</p>}
          {failed && (
            <p className='error'>
              {failed && failed.message ? (
                <span>Failed with message: {failed.message}</span>
              ) : (
                <span>
                  Something went wrong - please try again, or{' '}
                  <Link to='/support'>contact support</Link>
                </span>
              )}
            </p>
          )}

          <footer className='actions'>
            <button
              onClick={registerNewMerchant}
              disabled={
                !registeredName || !tradingAs || !contacts || !isLinkToParentValid || !isEmailValid
              }
            >
              <i className='fas fa-plus-circle' /> Create merchant
            </button>
          </footer>
        </div>
      </div>
    </section>
  )
}

export default CreateMerchant
