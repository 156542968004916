import PropTypes from 'prop-types'
import { formatCurrency } from '../../utils/Formatters'

const LargestUserTable = ({ data, failed, loading, title }) => {
  return data && Object.keys(data).length && !loading && !failed ? (
    <>
      <div className={'sub-header-table-view-wrapper'}>
        <div className='graph-title-container'>
          <span className='table-title'>{title}</span>
        </div>
        <table className='psp-transaction-spend-table' cellPadding='0' cellSpacing='0'>
          <thead className='header-row'>
            <tr>
              <th>Email</th>
              <th>Transaction Count</th>
              <th>Number of Cards</th>
              <th>Average Amount</th>
              <th>Total Amount</th>
            </tr>
          </thead>
          <tbody>
            {data.map((s) => {
              return (
                <tr key={s?.email}>
                  <td className={`table-data-left-alignment`}>
                    <span className='truncate-text'>{s?.email}</span>
                  </td>
                  <td>{s?.number_of_transactions}</td>
                  <td>{s?.number_of_cards}</td>
                  <td>€ {formatCurrency(s?.average_total)}</td>
                  <td>€ {formatCurrency(s?.total)}</td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
    </>
  ) : !data?.length && !loading && !failed ? (
    <div className='stacked-bar-chart-wrapper'>
      <span className='table-title'>{title}</span>
      <div className='no-data-dashboard'>
        <h1>NO DATA</h1>
      </div>
    </div>
  ) : null
}

LargestUserTable.propTypes = {
  data: PropTypes.array,
  failed: PropTypes.bool,
  loading: PropTypes.bool,
  title: PropTypes.string,
}

export default LargestUserTable
