import {
  BarChart,
  Bar,
  Brush,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  LabelList,
  Legend,
} from 'recharts'
import LoadingSpinner from '../../loading-spinner/loading-spinner'
import { useTheme } from '../../theme'
import PropTypes from 'prop-types'

const renderTooltipContent = (o) => {
  const { payload } = o
  const labelData = payload[0]?.payload

  if (!labelData) {
    return (
      <div className='customized-tooltip-content'>
        <p>No Data</p>
      </div>
    )
  }

  return (
    <div className='customized-tooltip-content'>
      <p>Brand: {labelData.brand}</p>
      <p>Ok: {labelData.ok}</p>
      <p>Failed: {labelData.failed}</p>
      <p>Abandoned - KYC: {labelData.abandoned_kyc}</p>
      <p>Abandoned - 3DS: {labelData.abandoned_3ds}</p>
      <p>Abandoned - FP: {labelData.abandoned_fp}</p>
      <p>In Progress: {labelData.in_progress}</p>
    </div>
  )
}

const CardBrandsChart = ({ data, failed, loading, title }) => {
  const theme = useTheme()
  //const isMobile = window.matchMedia('(max-width: 420px)').matches

  // Brush allows filtering the shown range of data; we calculate a sensible start that filters
  // small items if the viewport would clamp them too much. User can override.
  const estimatedRowSize = 24
  const graphWrapper = document.getElementById('dashboard-graphs-wrapper')
  const initialRowsShown = graphWrapper
    ? Math.floor((graphWrapper.clientHeight - 114) / estimatedRowSize)
    : data?.length ?? 0
  const brushStartIndex = Math.max((data?.length ?? 0) - initialRowsShown, 0)

  if (failed) {
    return (
      <div className='loading-spinner-wrapper'>
        <div>
          <LoadingSpinner color='red' />
          <div>could not retrieve data</div>
        </div>
      </div>
    )
  }

  if (loading) {
    return (
      <div className='loading-spinner-wrapper'>
        <LoadingSpinner />
      </div>
    )
  }

  if (!data?.length) {
    return (
      <div className='stacked-bar-chart-wrapper'>
        <span className='table-title'>{title}</span>
        <div className='no-data-dashboard'>
          <h1>NO DATA</h1>
        </div>
      </div>
    )
  }

  return (
    <div className='stacked-bar-chart-wrapper'>
      <div className='graph-title-container'>
        <span className='table-title'>{title}</span>
      </div>
      <ResponsiveContainer>
        <BarChart layout='vertical' data={data}>
          <CartesianGrid horizontalCoordinatesGenerator={() => [5]} />
          <XAxis type='number' />
          <YAxis dataKey='brand' type='category' hide={true} />
          <Tooltip content={renderTooltipContent} />
          <Brush
            travellerWidth={10}
            startIndex={brushStartIndex}
            dataKey='country'
            height={20}
            stroke={theme?.graphColours?.ok}
            className='theme-brush'
          />
          <Bar dataKey='ok' stackId='a' fill={theme?.graphColours.ok} />
          <Bar dataKey='failed' stackId='a' fill={theme?.graphColours.failed} />
          <Bar dataKey='abandoned' stackId='a' fill={theme?.graphColours.abandoned} />
          <Bar dataKey='in_progress' stackId='a' name='in progress' fill={theme?.graphColours.pending} />
          <Bar dataKey='' stackId='d' fill='#fff'>
            <LabelList dataKey='brand' position='insideLeft' />
          </Bar>
          <Legend verticalAlign='bottom' wrapperStyle={{ lineHeight: '40px' }} />
        </BarChart>
      </ResponsiveContainer>
    </div>
  )
}

CardBrandsChart.propTypes = {
  data: PropTypes.array,
  failed: PropTypes.bool,
  loading: PropTypes.bool,
  title: PropTypes.string,
}

export default CardBrandsChart
