export const MinorCodeListHelper = ({}) => {
    return (
        <div>
            <h1>Minor Codes</h1>
            <table className='route-list std-table'>
                <thead>
                    <tr>
                        <th>Code</th>
                        <th>Detail</th>
                    </tr>
                </thead>
                <tbody>
                    <tr className='std-row'>
                        <td>6</td><td>Unsupported Brand</td>
                    </tr>
                    <tr className='std-row'>
                        <td>7</td><td>Limit Exceeded</td>
                    </tr>
                    <tr className='std-row'>
                        <td>9</td><td>Do Not Honor</td>
                    </tr>
                    <tr className='std-row'>
                        <td>12</td><td>Blocked BIN</td>
                    </tr>
                    <tr className='std-row'>
                        <td>14</td><td>Restricted Customer</td>
                    </tr>
                    <tr className='std-row'>
                        <td>104</td><td>Restricted Card</td>
                    </tr>
                    <tr className='std-row'>
                        <td>119</td><td>Not Permitted To Cardholder</td>
                    </tr>
                    <tr className='std-row'>
                        <td>124</td><td>Violation of Law</td>
                    </tr>
                    <tr className='std-row'>
                        <td>182</td><td>Blocked Card</td>
                    </tr>
                </tbody>
            </table>
        </div>
    )
}

