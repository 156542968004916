import { useState } from 'react'
import Header from '../Header'
import Helmet from 'react-helmet'
import { useParams, useHistory, Link } from 'react-router-dom'
import { getTransaction, refundTransaction } from '../api'
import { useAuth } from '../auth'
//import { isOperator, hasContextContractID } from '../auth-roles'
import { useInterval } from '../timing/use-interval'
import AmountWithCurrency from '../AmountWithCurrency'

const LIVE_UPDATE_INTERVAL = 30000 // ms

/** RefundTxn allows a controller or operator to attempt a refund on the selected transaction.
 * Links back to ViewTxn upon success or cancellation. */
const RefundTxn = () => {
  const { token } = useAuth()
  const params = useParams()
  const history = useHistory()

  // Form: Change status
  const [changeStatus, setChangeStatus] = useState({
    req: { status: '', transaction_id: params.id, amount: { amount: 0, currency: '' } },
    res: null,
    saving: false,
    failed: null,
  })

  // Transaction data
  const [loading, setLoading] = useState(false)
  const [failed, setFailed] = useState()
  const [txn, setTxn] = useState()

  const fetchTxn = async () => {
    if (!params.id) {
      return
    }
    setLoading(true)
    setFailed(false)
    try {
      const result = await getTransaction(token, params.id)
      setTxn(result)
      //We update the request object. We use the status object to also help with state of the transaction
      setChangeStatus({
        ...changeStatus,
        req: { ...changeStatus.req, status: result.status, amount: result.total_processed },
      })
    } catch (failed) {
      setFailed(failed)
    }
    setLoading(false)
  }

  useInterval(fetchTxn, LIVE_UPDATE_INTERVAL, true)

  // Submit button
  const handleRefund = async () => {
    try {
      setChangeStatus({ ...changeStatus, saving: true, failed: null })
      const res = await refundTransaction(token, changeStatus.req)
      if (!res.failure_code) {
        setChangeStatus({ ...changeStatus, saving: false, res })
        // Auto-navigate to transactoin detail view
        history.push(`/transaction/${changeStatus.req?.transaction_id}`)
      } else {
        setChangeStatus({ ...changeStatus, saving: false, failed: res })
      }
    } catch (failed) {
      console.log(failed)
      setChangeStatus({ ...changeStatus, saving: false, failed })
    }
  }

  return (
    <section className='transaction edit-status'>
      <Header />
      <Helmet>
        <title>Transaction {txn ? txn.transaction_id : ''}</title>
      </Helmet>
      <div className='content'>
        {loading && !txn && <p className='loading'>Loading...</p>}
        {failed && (
          <p className='error'>
            Something went wrong - please try again, or <Link to='/support'>contact support</Link>.
          </p>
        )}
        {!failed && txn && (
          <>
            <div className='txn-status-change'>
              <h1>
                <i className='fas fas fa-credit-card'></i> Direct Refund of transaction
              </h1>
              <p>
                Transaction&nbsp;
                <strong className='txn-id'>{txn.transaction_id}</strong> from customer&nbsp;
                <strong className='cardholder-email'>
                  {txn.sale_request?.card_holder?.contact?.email || '(unknown)'}
                </strong>
                {''} will be refunded for the amount of:
              </p>
              <div className='statuses'>
                <label>
                  <em>
                    <strong>
                      <AmountWithCurrency {...txn.total_processed} />
                    </strong>
                  </em>
                </label>
              </div>
              <p>
                <em>
                  Note: This action will attempt to refund the selected transaction by contacting
                  the upstream PSP. <br /> Should the PSP not have refund functionality implemented
                  the transaction will still be marked as refunded but with the status of{' '}
                  <strong>open</strong> for reporting purposes. If you are unsure, please cancel.
                </em>
              </p>

              {changeStatus.failed && (
                <>
                  <p className='error'>{RefundFailureReason(changeStatus.failed.failure_code)}</p>
                  <p className='error'>
                    That didn&apos;t work - please try again or{' '}
                    <Link to='/support'>contact support</Link>.
                  </p>
                </>
              )}
            </div>
            <footer className='actions'>
              <Link to={`/transaction/${txn.transaction_id}`}>
                <button className='cancel'>Cancel</button>
              </Link>
              <button onClick={handleRefund}>
                {changeStatus.saving ? 'Contacting PSP...' : 'Attempt to refund'}
              </button>
            </footer>
          </>
        )}
      </div>
    </section>
  )
}

const RefundFailureReason = (code) => {
  switch (code) {
    case 1:
      return <span>Transaction not found</span>
    case 2:
      return <span>Refund already processed</span>
    case 3:
      return <span>Transaction not OK</span>
    case 4:
      return <span>Declined by PSP</span>
    case 5:
      return <span>PSP does not support refunds</span>
    case 6:
      return <span>PSP credentials (MID) inactive</span>
    case 7:
      return <span>Upstream error communicating with PSP</span>
    case 8:
      return <span>Currency mismatch</span>
  }

  return <span>Unknown error!</span>
}

export default RefundTxn
