export const monthlyFrequencyOfUseList = [
  { label: '1 - 5 times', value: '1 - 5 times' },
  { label: '6 - 15 times', value: '6 - 15 times' },
  { label: 'More than 15 times', value: 'More than 15 times' },
]

export const monthlyTransactionAmountList = [
  { label: '0 – 350 EUR (€)', value: '0 - 350 EUR' },
  { label: '351 – 700 EUR (€)', value: '351 - 700 EUR' },
  { label: 'More than 700 EUR (€)', value: 'More than 700 EUR' },
]

export const mainSourceOfFundsList = [
  { label: 'Salary', value: 'Salary' },
  { label: 'Savings', value: 'Savings' },
  { label: 'Pension', value: 'Pension' },
  { label: 'Other', value: 'Other' },
]

export const monthlyAmountList = [
  { label: '0 – 2 000 EUR (€)', value: '0 - 2000 EUR' },
  { label: '2 001 – 5 000 EUR (€)', value: '2001 - 5000 EUR' },
  { label: 'More than 5000 EUR (€)', value: 'More than 5000 EUR' },
]

export const ReasonsOfUse = [
  { label: 'Make payments', value: 'Make payments' },
  { label: 'Trade and invest', value: 'Trade and invest' },
]
