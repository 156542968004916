import PropTypes from 'prop-types'
import { path } from 'ramda'
import { usePSP } from '../../PspContext'

const PSPFilter = ({ filter, setFilter, field = 'psp_any' }) => {
  const { psps } = usePSP()

  const pspName = path([field, 0], filter)

  const changed = (e) => {
    const newPSP = e.target.value
    const newFilter = { ...filter, psp_mid_id: [] }
    newFilter[field] = newPSP ? [newPSP] : []
    setFilter(newFilter)
  }

  if (!psps || psps.length === 0) {
    return null
  }

  return (
    <div className='filter'>
      <select value={pspName || ''} onChange={changed} className='psp-filter-select'>
        <option value=''>Any</option>
        {psps &&
          psps.map((s) => (
            <option key={s.id} value={s.id}>
              {s.id}
            </option>
          ))}
      </select>
    </div>
  )
}

PSPFilter.propTypes = {
  filter: PropTypes.object.isRequired,
  setFilter: PropTypes.func.isRequired,
  field: PropTypes.string,
}

export default PSPFilter
