import { acceptancePercent } from './txns/stats'
import PropTypes from 'prop-types'
import AmountWithCurrency from './AmountWithCurrency'
const AcceptanceRateSettledValue = ({
  cnt_by_status = {},
  net_vol_by_settle_ccy = {},
  non_eur_settlement = false,
}) => {
  const rate = acceptancePercent(cnt_by_status)
  if (rate == null) {
    return null
  }

  return (
    <div className={`module acceptance-rate`}>
      <label>Acceptance rate</label>
      <div className='rate'>{rate.toLocaleString(undefined, { style: 'percent' })}</div>
      {net_vol_by_settle_ccy?.REPORT_EUR ? (
        <>
          <label title='Funds that were successfully processed by upstream'>Settled value</label>
          <div className='settled-amount'>
            {non_eur_settlement ? (
              <label title='Total includes non-EUR settled amount values from nominal exchange rates. Real value may be lower due to upstream margin and fees.'>
                <i className='fas fa-exclamation-triangle' />
                &nbsp;
              </label>
            ) : null}
            <AmountWithCurrency amount={net_vol_by_settle_ccy.REPORT_EUR} currency='EUR' />
          </div>
        </>
      ) : null}
    </div>
  )
}
AcceptanceRateSettledValue.propTypes = {
  cnt_by_status: PropTypes.object,
  net_vol_by_settle_ccy: PropTypes.object,
  non_eur_settlement: PropTypes.bool,
}

export default AcceptanceRateSettledValue
