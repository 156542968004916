[
  {
    "alpha2": "aa",
    "alpha3": "aar",
    "bibliographic": "",
    "name": "Afar"
  },
  {
    "alpha2": "ab",
    "alpha3": "abk",
    "bibliographic": "",
    "name": "Abkhazian"
  },
  {
    "alpha2": "",
    "alpha3": "ace",
    "bibliographic": "",
    "name": "Achinese"
  },
  {
    "alpha2": "",
    "alpha3": "ach",
    "bibliographic": "",
    "name": "Acoli"
  },
  {
    "alpha2": "",
    "alpha3": "ada",
    "bibliographic": "",
    "name": "Adangme"
  },
  {
    "alpha2": "",
    "alpha3": "ady",
    "bibliographic": "",
    "name": "Adygei"
  },
  {
    "alpha2": "",
    "alpha3": "ady",
    "bibliographic": "",
    "name": "Adyghe"
  },
  {
    "alpha2": "",
    "alpha3": "afa",
    "bibliographic": "",
    "name": "Afro-Asiatic languages"
  },
  {
    "alpha2": "",
    "alpha3": "afh",
    "bibliographic": "",
    "name": "Afrihili"
  },
  {
    "alpha2": "af",
    "alpha3": "afr",
    "bibliographic": "",
    "name": "Afrikaans"
  },
  {
    "alpha2": "",
    "alpha3": "ain",
    "bibliographic": "",
    "name": "Ainu"
  },
  {
    "alpha2": "ak",
    "alpha3": "aka",
    "bibliographic": "",
    "name": "Akan"
  },
  {
    "alpha2": "",
    "alpha3": "akk",
    "bibliographic": "",
    "name": "Akkadian"
  },
  {
    "alpha2": "",
    "alpha3": "ale",
    "bibliographic": "",
    "name": "Aleut"
  },
  {
    "alpha2": "",
    "alpha3": "alg",
    "bibliographic": "",
    "name": "Algonquian languages"
  },
  {
    "alpha2": "",
    "alpha3": "alt",
    "bibliographic": "",
    "name": "Southern Altai"
  },
  {
    "alpha2": "am",
    "alpha3": "amh",
    "bibliographic": "",
    "name": "Amharic"
  },
  {
    "alpha2": "",
    "alpha3": "ang",
    "bibliographic": "",
    "name": "English, Old (ca.450-1100)"
  },
  {
    "alpha2": "",
    "alpha3": "anp",
    "bibliographic": "",
    "name": "Angika"
  },
  {
    "alpha2": "",
    "alpha3": "apa",
    "bibliographic": "",
    "name": "Apache languages"
  },
  {
    "alpha2": "ar",
    "alpha3": "ara",
    "bibliographic": "",
    "name": "Arabic"
  },
  {
    "alpha2": "",
    "alpha3": "arc",
    "bibliographic": "",
    "name": "Imperial Aramaic (700-300 BCE)"
  },
  {
    "alpha2": "",
    "alpha3": "arc",
    "bibliographic": "",
    "name": "Official Aramaic (700-300 BCE)"
  },
  {
    "alpha2": "an",
    "alpha3": "arg",
    "bibliographic": "",
    "name": "Aragonese"
  },
  {
    "alpha2": "",
    "alpha3": "arn",
    "bibliographic": "",
    "name": "Mapuche"
  },
  {
    "alpha2": "",
    "alpha3": "arn",
    "bibliographic": "",
    "name": "Mapudungun"
  },
  {
    "alpha2": "",
    "alpha3": "arp",
    "bibliographic": "",
    "name": "Arapaho"
  },
  {
    "alpha2": "",
    "alpha3": "art",
    "bibliographic": "",
    "name": "Artificial languages"
  },
  {
    "alpha2": "",
    "alpha3": "arw",
    "bibliographic": "",
    "name": "Arawak"
  },
  {
    "alpha2": "as",
    "alpha3": "asm",
    "bibliographic": "",
    "name": "Assamese"
  },
  {
    "alpha2": "",
    "alpha3": "ast",
    "bibliographic": "",
    "name": "Asturian"
  },
  {
    "alpha2": "",
    "alpha3": "ast",
    "bibliographic": "",
    "name": "Asturleonese"
  },
  {
    "alpha2": "",
    "alpha3": "ast",
    "bibliographic": "",
    "name": "Bable"
  },
  {
    "alpha2": "",
    "alpha3": "ast",
    "bibliographic": "",
    "name": "Leonese"
  },
  {
    "alpha2": "",
    "alpha3": "ath",
    "bibliographic": "",
    "name": "Athapascan languages"
  },
  {
    "alpha2": "",
    "alpha3": "aus",
    "bibliographic": "",
    "name": "Australian languages"
  },
  {
    "alpha2": "av",
    "alpha3": "ava",
    "bibliographic": "",
    "name": "Avaric"
  },
  {
    "alpha2": "ae",
    "alpha3": "ave",
    "bibliographic": "",
    "name": "Avestan"
  },
  {
    "alpha2": "",
    "alpha3": "awa",
    "bibliographic": "",
    "name": "Awadhi"
  },
  {
    "alpha2": "ay",
    "alpha3": "aym",
    "bibliographic": "",
    "name": "Aymara"
  },
  {
    "alpha2": "az",
    "alpha3": "aze",
    "bibliographic": "",
    "name": "Azerbaijani"
  },
  {
    "alpha2": "",
    "alpha3": "bad",
    "bibliographic": "",
    "name": "Banda languages"
  },
  {
    "alpha2": "",
    "alpha3": "bai",
    "bibliographic": "",
    "name": "Bamileke languages"
  },
  {
    "alpha2": "ba",
    "alpha3": "bak",
    "bibliographic": "",
    "name": "Bashkir"
  },
  {
    "alpha2": "",
    "alpha3": "bal",
    "bibliographic": "",
    "name": "Baluchi"
  },
  {
    "alpha2": "bm",
    "alpha3": "bam",
    "bibliographic": "",
    "name": "Bambara"
  },
  {
    "alpha2": "",
    "alpha3": "ban",
    "bibliographic": "",
    "name": "Balinese"
  },
  {
    "alpha2": "",
    "alpha3": "bas",
    "bibliographic": "",
    "name": "Basa"
  },
  {
    "alpha2": "",
    "alpha3": "bat",
    "bibliographic": "",
    "name": "Baltic languages"
  },
  {
    "alpha2": "",
    "alpha3": "bej",
    "bibliographic": "",
    "name": "Bedawiyet"
  },
  {
    "alpha2": "",
    "alpha3": "bej",
    "bibliographic": "",
    "name": "Beja"
  },
  {
    "alpha2": "be",
    "alpha3": "bel",
    "bibliographic": "",
    "name": "Belarusian"
  },
  {
    "alpha2": "",
    "alpha3": "bem",
    "bibliographic": "",
    "name": "Bemba"
  },
  {
    "alpha2": "bn",
    "alpha3": "ben",
    "bibliographic": "",
    "name": "Bengali"
  },
  {
    "alpha2": "",
    "alpha3": "ber",
    "bibliographic": "",
    "name": "Berber languages"
  },
  {
    "alpha2": "",
    "alpha3": "bho",
    "bibliographic": "",
    "name": "Bhojpuri"
  },
  {
    "alpha2": "bh",
    "alpha3": "bih",
    "bibliographic": "",
    "name": "Bihari languages"
  },
  {
    "alpha2": "",
    "alpha3": "bik",
    "bibliographic": "",
    "name": "Bikol"
  },
  {
    "alpha2": "",
    "alpha3": "bin",
    "bibliographic": "",
    "name": "Bini"
  },
  {
    "alpha2": "",
    "alpha3": "bin",
    "bibliographic": "",
    "name": "Edo"
  },
  {
    "alpha2": "bi",
    "alpha3": "bis",
    "bibliographic": "",
    "name": "Bislama"
  },
  {
    "alpha2": "",
    "alpha3": "bla",
    "bibliographic": "",
    "name": "Siksika"
  },
  {
    "alpha2": "",
    "alpha3": "bnt",
    "bibliographic": "",
    "name": "Bantu languages"
  },
  {
    "alpha2": "bo",
    "alpha3": "bod",
    "bibliographic": "tib",
    "name": "Tibetan"
  },
  {
    "alpha2": "bs",
    "alpha3": "bos",
    "bibliographic": "",
    "name": "Bosnian"
  },
  {
    "alpha2": "",
    "alpha3": "bra",
    "bibliographic": "",
    "name": "Braj"
  },
  {
    "alpha2": "br",
    "alpha3": "bre",
    "bibliographic": "",
    "name": "Breton"
  },
  {
    "alpha2": "",
    "alpha3": "btk",
    "bibliographic": "",
    "name": "Batak languages"
  },
  {
    "alpha2": "",
    "alpha3": "bua",
    "bibliographic": "",
    "name": "Buriat"
  },
  {
    "alpha2": "",
    "alpha3": "bug",
    "bibliographic": "",
    "name": "Buginese"
  },
  {
    "alpha2": "bg",
    "alpha3": "bul",
    "bibliographic": "",
    "name": "Bulgarian"
  },
  {
    "alpha2": "",
    "alpha3": "byn",
    "bibliographic": "",
    "name": "Bilin"
  },
  {
    "alpha2": "",
    "alpha3": "byn",
    "bibliographic": "",
    "name": "Blin"
  },
  {
    "alpha2": "",
    "alpha3": "cad",
    "bibliographic": "",
    "name": "Caddo"
  },
  {
    "alpha2": "",
    "alpha3": "cai",
    "bibliographic": "",
    "name": "Central American Indian languages"
  },
  {
    "alpha2": "",
    "alpha3": "car",
    "bibliographic": "",
    "name": "Galibi Carib"
  },
  {
    "alpha2": "ca",
    "alpha3": "cat",
    "bibliographic": "",
    "name": "Catalan"
  },
  {
    "alpha2": "ca",
    "alpha3": "cat",
    "bibliographic": "",
    "name": "Valencian"
  },
  {
    "alpha2": "",
    "alpha3": "cau",
    "bibliographic": "",
    "name": "Caucasian languages"
  },
  {
    "alpha2": "",
    "alpha3": "ceb",
    "bibliographic": "",
    "name": "Cebuano"
  },
  {
    "alpha2": "",
    "alpha3": "cel",
    "bibliographic": "",
    "name": "Celtic languages"
  },
  {
    "alpha2": "cs",
    "alpha3": "ces",
    "bibliographic": "cze",
    "name": "Czech"
  },
  {
    "alpha2": "ch",
    "alpha3": "cha",
    "bibliographic": "",
    "name": "Chamorro"
  },
  {
    "alpha2": "",
    "alpha3": "chb",
    "bibliographic": "",
    "name": "Chibcha"
  },
  {
    "alpha2": "ce",
    "alpha3": "che",
    "bibliographic": "",
    "name": "Chechen"
  },
  {
    "alpha2": "",
    "alpha3": "chg",
    "bibliographic": "",
    "name": "Chagatai"
  },
  {
    "alpha2": "",
    "alpha3": "chk",
    "bibliographic": "",
    "name": "Chuukese"
  },
  {
    "alpha2": "",
    "alpha3": "chm",
    "bibliographic": "",
    "name": "Mari"
  },
  {
    "alpha2": "",
    "alpha3": "chn",
    "bibliographic": "",
    "name": "Chinook jargon"
  },
  {
    "alpha2": "",
    "alpha3": "cho",
    "bibliographic": "",
    "name": "Choctaw"
  },
  {
    "alpha2": "",
    "alpha3": "chp",
    "bibliographic": "",
    "name": "Chipewyan"
  },
  {
    "alpha2": "",
    "alpha3": "chp",
    "bibliographic": "",
    "name": "Dene Suline"
  },
  {
    "alpha2": "",
    "alpha3": "chr",
    "bibliographic": "",
    "name": "Cherokee"
  },
  {
    "alpha2": "cu",
    "alpha3": "chu",
    "bibliographic": "",
    "name": "Church Slavic"
  },
  {
    "alpha2": "cu",
    "alpha3": "chu",
    "bibliographic": "",
    "name": "Church Slavonic"
  },
  {
    "alpha2": "cu",
    "alpha3": "chu",
    "bibliographic": "",
    "name": "Old Bulgarian"
  },
  {
    "alpha2": "cu",
    "alpha3": "chu",
    "bibliographic": "",
    "name": "Old Church Slavonic"
  },
  {
    "alpha2": "cu",
    "alpha3": "chu",
    "bibliographic": "",
    "name": "Old Slavonic"
  },
  {
    "alpha2": "cv",
    "alpha3": "chv",
    "bibliographic": "",
    "name": "Chuvash"
  },
  {
    "alpha2": "",
    "alpha3": "chy",
    "bibliographic": "",
    "name": "Cheyenne"
  },
  {
    "alpha2": "",
    "alpha3": "cmc",
    "bibliographic": "",
    "name": "Chamic languages"
  },
  {
    "alpha2": "",
    "alpha3": "cop",
    "bibliographic": "",
    "name": "Coptic"
  },
  {
    "alpha2": "kw",
    "alpha3": "cor",
    "bibliographic": "",
    "name": "Cornish"
  },
  {
    "alpha2": "co",
    "alpha3": "cos",
    "bibliographic": "",
    "name": "Corsican"
  },
  {
    "alpha2": "",
    "alpha3": "cpe",
    "bibliographic": "",
    "name": "Creoles and pidgins, English based"
  },
  {
    "alpha2": "",
    "alpha3": "cpf",
    "bibliographic": "",
    "name": "Creoles and pidgins, French-based"
  },
  {
    "alpha2": "",
    "alpha3": "cpp",
    "bibliographic": "",
    "name": "Creoles and pidgins, Portuguese-based"
  },
  {
    "alpha2": "cr",
    "alpha3": "cre",
    "bibliographic": "",
    "name": "Cree"
  },
  {
    "alpha2": "",
    "alpha3": "crh",
    "bibliographic": "",
    "name": "Crimean Tatar"
  },
  {
    "alpha2": "",
    "alpha3": "crh",
    "bibliographic": "",
    "name": "Crimean Turkish"
  },
  {
    "alpha2": "",
    "alpha3": "crp",
    "bibliographic": "",
    "name": "Creoles and pidgins"
  },
  {
    "alpha2": "",
    "alpha3": "csb",
    "bibliographic": "",
    "name": "Kashubian"
  },
  {
    "alpha2": "",
    "alpha3": "cus",
    "bibliographic": "",
    "name": "Cushitic languages"
  },
  {
    "alpha2": "cy",
    "alpha3": "cym",
    "bibliographic": "wel",
    "name": "Welsh"
  },
  {
    "alpha2": "",
    "alpha3": "dak",
    "bibliographic": "",
    "name": "Dakota"
  },
  {
    "alpha2": "da",
    "alpha3": "dan",
    "bibliographic": "",
    "name": "Danish"
  },
  {
    "alpha2": "",
    "alpha3": "dar",
    "bibliographic": "",
    "name": "Dargwa"
  },
  {
    "alpha2": "",
    "alpha3": "day",
    "bibliographic": "",
    "name": "Land Dayak languages"
  },
  {
    "alpha2": "",
    "alpha3": "del",
    "bibliographic": "",
    "name": "Delaware"
  },
  {
    "alpha2": "",
    "alpha3": "den",
    "bibliographic": "",
    "name": "Slave (Athapascan)"
  },
  {
    "alpha2": "de",
    "alpha3": "deu",
    "bibliographic": "ger",
    "name": "German"
  },
  {
    "alpha2": "",
    "alpha3": "dgr",
    "bibliographic": "",
    "name": "Dogrib"
  },
  {
    "alpha2": "",
    "alpha3": "din",
    "bibliographic": "",
    "name": "Dinka"
  },
  {
    "alpha2": "dv",
    "alpha3": "div",
    "bibliographic": "",
    "name": "Dhivehi"
  },
  {
    "alpha2": "dv",
    "alpha3": "div",
    "bibliographic": "",
    "name": "Divehi"
  },
  {
    "alpha2": "dv",
    "alpha3": "div",
    "bibliographic": "",
    "name": "Maldivian"
  },
  {
    "alpha2": "",
    "alpha3": "doi",
    "bibliographic": "",
    "name": "Dogri"
  },
  {
    "alpha2": "",
    "alpha3": "dra",
    "bibliographic": "",
    "name": "Dravidian languages"
  },
  {
    "alpha2": "",
    "alpha3": "dsb",
    "bibliographic": "",
    "name": "Lower Sorbian"
  },
  {
    "alpha2": "",
    "alpha3": "dua",
    "bibliographic": "",
    "name": "Duala"
  },
  {
    "alpha2": "",
    "alpha3": "dum",
    "bibliographic": "",
    "name": "Dutch, Middle (ca.1050-1350)"
  },
  {
    "alpha2": "",
    "alpha3": "dyu",
    "bibliographic": "",
    "name": "Dyula"
  },
  {
    "alpha2": "dz",
    "alpha3": "dzo",
    "bibliographic": "",
    "name": "Dzongkha"
  },
  {
    "alpha2": "",
    "alpha3": "efi",
    "bibliographic": "",
    "name": "Efik"
  },
  {
    "alpha2": "",
    "alpha3": "egy",
    "bibliographic": "",
    "name": "Egyptian (Ancient)"
  },
  {
    "alpha2": "",
    "alpha3": "eka",
    "bibliographic": "",
    "name": "Ekajuk"
  },
  {
    "alpha2": "el",
    "alpha3": "ell",
    "bibliographic": "gre",
    "name": "Greek, Modern (1453-)"
  },
  {
    "alpha2": "",
    "alpha3": "elx",
    "bibliographic": "",
    "name": "Elamite"
  },
  {
    "alpha2": "en",
    "alpha3": "eng",
    "bibliographic": "",
    "name": "English"
  },
  {
    "alpha2": "",
    "alpha3": "enm",
    "bibliographic": "",
    "name": "English, Middle (1100-1500)"
  },
  {
    "alpha2": "eo",
    "alpha3": "epo",
    "bibliographic": "",
    "name": "Esperanto"
  },
  {
    "alpha2": "et",
    "alpha3": "est",
    "bibliographic": "",
    "name": "Estonian"
  },
  {
    "alpha2": "eu",
    "alpha3": "eus",
    "bibliographic": "baq",
    "name": "Basque"
  },
  {
    "alpha2": "ee",
    "alpha3": "ewe",
    "bibliographic": "",
    "name": "Ewe"
  },
  {
    "alpha2": "",
    "alpha3": "ewo",
    "bibliographic": "",
    "name": "Ewondo"
  },
  {
    "alpha2": "",
    "alpha3": "fan",
    "bibliographic": "",
    "name": "Fang"
  },
  {
    "alpha2": "fo",
    "alpha3": "fao",
    "bibliographic": "",
    "name": "Faroese"
  },
  {
    "alpha2": "fa",
    "alpha3": "fas",
    "bibliographic": "per",
    "name": "Persian"
  },
  {
    "alpha2": "",
    "alpha3": "fat",
    "bibliographic": "",
    "name": "Fanti"
  },
  {
    "alpha2": "fj",
    "alpha3": "fij",
    "bibliographic": "",
    "name": "Fijian"
  },
  {
    "alpha2": "",
    "alpha3": "fil",
    "bibliographic": "",
    "name": "Filipino"
  },
  {
    "alpha2": "",
    "alpha3": "fil",
    "bibliographic": "",
    "name": "Pilipino"
  },
  {
    "alpha2": "fi",
    "alpha3": "fin",
    "bibliographic": "",
    "name": "Finnish"
  },
  {
    "alpha2": "",
    "alpha3": "fiu",
    "bibliographic": "",
    "name": "Finno-Ugrian languages"
  },
  {
    "alpha2": "",
    "alpha3": "fon",
    "bibliographic": "",
    "name": "Fon"
  },
  {
    "alpha2": "fr",
    "alpha3": "fra",
    "bibliographic": "fre",
    "name": "French"
  },
  {
    "alpha2": "",
    "alpha3": "frm",
    "bibliographic": "",
    "name": "French, Middle (ca.1400-1600)"
  },
  {
    "alpha2": "",
    "alpha3": "fro",
    "bibliographic": "",
    "name": "French, Old (842-ca.1400)"
  },
  {
    "alpha2": "",
    "alpha3": "frr",
    "bibliographic": "",
    "name": "Northern Frisian"
  },
  {
    "alpha2": "",
    "alpha3": "frs",
    "bibliographic": "",
    "name": "Eastern Frisian"
  },
  {
    "alpha2": "fy",
    "alpha3": "fry",
    "bibliographic": "",
    "name": "Western Frisian"
  },
  {
    "alpha2": "ff",
    "alpha3": "ful",
    "bibliographic": "",
    "name": "Fulah"
  },
  {
    "alpha2": "",
    "alpha3": "fur",
    "bibliographic": "",
    "name": "Friulian"
  },
  {
    "alpha2": "",
    "alpha3": "gaa",
    "bibliographic": "",
    "name": "Ga"
  },
  {
    "alpha2": "",
    "alpha3": "gay",
    "bibliographic": "",
    "name": "Gayo"
  },
  {
    "alpha2": "",
    "alpha3": "gba",
    "bibliographic": "",
    "name": "Gbaya"
  },
  {
    "alpha2": "",
    "alpha3": "gem",
    "bibliographic": "",
    "name": "Germanic languages"
  },
  {
    "alpha2": "",
    "alpha3": "gez",
    "bibliographic": "",
    "name": "Geez"
  },
  {
    "alpha2": "",
    "alpha3": "gil",
    "bibliographic": "",
    "name": "Gilbertese"
  },
  {
    "alpha2": "gd",
    "alpha3": "gla",
    "bibliographic": "",
    "name": "Gaelic"
  },
  {
    "alpha2": "gd",
    "alpha3": "gla",
    "bibliographic": "",
    "name": "Scottish Gaelic"
  },
  {
    "alpha2": "ga",
    "alpha3": "gle",
    "bibliographic": "",
    "name": "Irish"
  },
  {
    "alpha2": "gl",
    "alpha3": "glg",
    "bibliographic": "",
    "name": "Galician"
  },
  {
    "alpha2": "gv",
    "alpha3": "glv",
    "bibliographic": "",
    "name": "Manx"
  },
  {
    "alpha2": "",
    "alpha3": "gmh",
    "bibliographic": "",
    "name": "German, Middle High (ca.1050-1500)"
  },
  {
    "alpha2": "",
    "alpha3": "goh",
    "bibliographic": "",
    "name": "German, Old High (ca.750-1050)"
  },
  {
    "alpha2": "",
    "alpha3": "gon",
    "bibliographic": "",
    "name": "Gondi"
  },
  {
    "alpha2": "",
    "alpha3": "gor",
    "bibliographic": "",
    "name": "Gorontalo"
  },
  {
    "alpha2": "",
    "alpha3": "got",
    "bibliographic": "",
    "name": "Gothic"
  },
  {
    "alpha2": "",
    "alpha3": "grb",
    "bibliographic": "",
    "name": "Grebo"
  },
  {
    "alpha2": "",
    "alpha3": "grc",
    "bibliographic": "",
    "name": "Greek, Ancient (to 1453)"
  },
  {
    "alpha2": "gn",
    "alpha3": "grn",
    "bibliographic": "",
    "name": "Guarani"
  },
  {
    "alpha2": "",
    "alpha3": "gsw",
    "bibliographic": "",
    "name": "Alemannic"
  },
  {
    "alpha2": "",
    "alpha3": "gsw",
    "bibliographic": "",
    "name": "Alsatian"
  },
  {
    "alpha2": "",
    "alpha3": "gsw",
    "bibliographic": "",
    "name": "Swiss German"
  },
  {
    "alpha2": "gu",
    "alpha3": "guj",
    "bibliographic": "",
    "name": "Gujarati"
  },
  {
    "alpha2": "",
    "alpha3": "gwi",
    "bibliographic": "",
    "name": "Gwich'in"
  },
  {
    "alpha2": "",
    "alpha3": "hai",
    "bibliographic": "",
    "name": "Haida"
  },
  {
    "alpha2": "ht",
    "alpha3": "hat",
    "bibliographic": "",
    "name": "Haitian"
  },
  {
    "alpha2": "ht",
    "alpha3": "hat",
    "bibliographic": "",
    "name": "Haitian Creole"
  },
  {
    "alpha2": "ha",
    "alpha3": "hau",
    "bibliographic": "",
    "name": "Hausa"
  },
  {
    "alpha2": "",
    "alpha3": "haw",
    "bibliographic": "",
    "name": "Hawaiian"
  },
  {
    "alpha2": "he",
    "alpha3": "heb",
    "bibliographic": "",
    "name": "Hebrew"
  },
  {
    "alpha2": "hz",
    "alpha3": "her",
    "bibliographic": "",
    "name": "Herero"
  },
  {
    "alpha2": "",
    "alpha3": "hil",
    "bibliographic": "",
    "name": "Hiligaynon"
  },
  {
    "alpha2": "",
    "alpha3": "him",
    "bibliographic": "",
    "name": "Himachali languages"
  },
  {
    "alpha2": "",
    "alpha3": "him",
    "bibliographic": "",
    "name": "Western Pahari languages"
  },
  {
    "alpha2": "hi",
    "alpha3": "hin",
    "bibliographic": "",
    "name": "Hindi"
  },
  {
    "alpha2": "",
    "alpha3": "hit",
    "bibliographic": "",
    "name": "Hittite"
  },
  {
    "alpha2": "",
    "alpha3": "hmn",
    "bibliographic": "",
    "name": "Hmong"
  },
  {
    "alpha2": "",
    "alpha3": "hmn",
    "bibliographic": "",
    "name": "Mong"
  },
  {
    "alpha2": "ho",
    "alpha3": "hmo",
    "bibliographic": "",
    "name": "Hiri Motu"
  },
  {
    "alpha2": "hr",
    "alpha3": "hrv",
    "bibliographic": "",
    "name": "Croatian"
  },
  {
    "alpha2": "",
    "alpha3": "hsb",
    "bibliographic": "",
    "name": "Upper Sorbian"
  },
  {
    "alpha2": "hu",
    "alpha3": "hun",
    "bibliographic": "",
    "name": "Hungarian"
  },
  {
    "alpha2": "",
    "alpha3": "hup",
    "bibliographic": "",
    "name": "Hupa"
  },
  {
    "alpha2": "hy",
    "alpha3": "hye",
    "bibliographic": "arm",
    "name": "Armenian"
  },
  {
    "alpha2": "",
    "alpha3": "iba",
    "bibliographic": "",
    "name": "Iban"
  },
  {
    "alpha2": "ig",
    "alpha3": "ibo",
    "bibliographic": "",
    "name": "Igbo"
  },
  {
    "alpha2": "io",
    "alpha3": "ido",
    "bibliographic": "",
    "name": "Ido"
  },
  {
    "alpha2": "ii",
    "alpha3": "iii",
    "bibliographic": "",
    "name": "Nuosu"
  },
  {
    "alpha2": "ii",
    "alpha3": "iii",
    "bibliographic": "",
    "name": "Sichuan Yi"
  },
  {
    "alpha2": "",
    "alpha3": "ijo",
    "bibliographic": "",
    "name": "Ijo languages"
  },
  {
    "alpha2": "iu",
    "alpha3": "iku",
    "bibliographic": "",
    "name": "Inuktitut"
  },
  {
    "alpha2": "ie",
    "alpha3": "ile",
    "bibliographic": "",
    "name": "Interlingue"
  },
  {
    "alpha2": "ie",
    "alpha3": "ile",
    "bibliographic": "",
    "name": "Occidental"
  },
  {
    "alpha2": "",
    "alpha3": "ilo",
    "bibliographic": "",
    "name": "Iloko"
  },
  {
    "alpha2": "ia",
    "alpha3": "ina",
    "bibliographic": "",
    "name": "Interlingua (International Auxiliary Language Association)"
  },
  {
    "alpha2": "",
    "alpha3": "inc",
    "bibliographic": "",
    "name": "Indic languages"
  },
  {
    "alpha2": "id",
    "alpha3": "ind",
    "bibliographic": "",
    "name": "Indonesian"
  },
  {
    "alpha2": "",
    "alpha3": "ine",
    "bibliographic": "",
    "name": "Indo-European languages"
  },
  {
    "alpha2": "",
    "alpha3": "inh",
    "bibliographic": "",
    "name": "Ingush"
  },
  {
    "alpha2": "ik",
    "alpha3": "ipk",
    "bibliographic": "",
    "name": "Inupiaq"
  },
  {
    "alpha2": "",
    "alpha3": "ira",
    "bibliographic": "",
    "name": "Iranian languages"
  },
  {
    "alpha2": "",
    "alpha3": "iro",
    "bibliographic": "",
    "name": "Iroquoian languages"
  },
  {
    "alpha2": "is",
    "alpha3": "isl",
    "bibliographic": "ice",
    "name": "Icelandic"
  },
  {
    "alpha2": "it",
    "alpha3": "ita",
    "bibliographic": "",
    "name": "Italian"
  },
  {
    "alpha2": "jv",
    "alpha3": "jav",
    "bibliographic": "",
    "name": "Javanese"
  },
  {
    "alpha2": "",
    "alpha3": "jbo",
    "bibliographic": "",
    "name": "Lojban"
  },
  {
    "alpha2": "ja",
    "alpha3": "jpn",
    "bibliographic": "",
    "name": "Japanese"
  },
  {
    "alpha2": "",
    "alpha3": "jpr",
    "bibliographic": "",
    "name": "Judeo-Persian"
  },
  {
    "alpha2": "",
    "alpha3": "jrb",
    "bibliographic": "",
    "name": "Judeo-Arabic"
  },
  {
    "alpha2": "",
    "alpha3": "kaa",
    "bibliographic": "",
    "name": "Kara-Kalpak"
  },
  {
    "alpha2": "",
    "alpha3": "kab",
    "bibliographic": "",
    "name": "Kabyle"
  },
  {
    "alpha2": "",
    "alpha3": "kac",
    "bibliographic": "",
    "name": "Jingpho"
  },
  {
    "alpha2": "",
    "alpha3": "kac",
    "bibliographic": "",
    "name": "Kachin"
  },
  {
    "alpha2": "kl",
    "alpha3": "kal",
    "bibliographic": "",
    "name": "Greenlandic"
  },
  {
    "alpha2": "kl",
    "alpha3": "kal",
    "bibliographic": "",
    "name": "Kalaallisut"
  },
  {
    "alpha2": "",
    "alpha3": "kam",
    "bibliographic": "",
    "name": "Kamba"
  },
  {
    "alpha2": "kn",
    "alpha3": "kan",
    "bibliographic": "",
    "name": "Kannada"
  },
  {
    "alpha2": "",
    "alpha3": "kar",
    "bibliographic": "",
    "name": "Karen languages"
  },
  {
    "alpha2": "ks",
    "alpha3": "kas",
    "bibliographic": "",
    "name": "Kashmiri"
  },
  {
    "alpha2": "ka",
    "alpha3": "kat",
    "bibliographic": "geo",
    "name": "Georgian"
  },
  {
    "alpha2": "kr",
    "alpha3": "kau",
    "bibliographic": "",
    "name": "Kanuri"
  },
  {
    "alpha2": "",
    "alpha3": "kaw",
    "bibliographic": "",
    "name": "Kawi"
  },
  {
    "alpha2": "kk",
    "alpha3": "kaz",
    "bibliographic": "",
    "name": "Kazakh"
  },
  {
    "alpha2": "",
    "alpha3": "kbd",
    "bibliographic": "",
    "name": "Kabardian"
  },
  {
    "alpha2": "",
    "alpha3": "kha",
    "bibliographic": "",
    "name": "Khasi"
  },
  {
    "alpha2": "",
    "alpha3": "khi",
    "bibliographic": "",
    "name": "Khoisan languages"
  },
  {
    "alpha2": "km",
    "alpha3": "khm",
    "bibliographic": "",
    "name": "Central Khmer"
  },
  {
    "alpha2": "",
    "alpha3": "kho",
    "bibliographic": "",
    "name": "Khotanese"
  },
  {
    "alpha2": "",
    "alpha3": "kho",
    "bibliographic": "",
    "name": "Sakan"
  },
  {
    "alpha2": "ki",
    "alpha3": "kik",
    "bibliographic": "",
    "name": "Gikuyu"
  },
  {
    "alpha2": "ki",
    "alpha3": "kik",
    "bibliographic": "",
    "name": "Kikuyu"
  },
  {
    "alpha2": "rw",
    "alpha3": "kin",
    "bibliographic": "",
    "name": "Kinyarwanda"
  },
  {
    "alpha2": "ky",
    "alpha3": "kir",
    "bibliographic": "",
    "name": "Kirghiz"
  },
  {
    "alpha2": "ky",
    "alpha3": "kir",
    "bibliographic": "",
    "name": "Kyrgyz"
  },
  {
    "alpha2": "",
    "alpha3": "kmb",
    "bibliographic": "",
    "name": "Kimbundu"
  },
  {
    "alpha2": "",
    "alpha3": "kok",
    "bibliographic": "",
    "name": "Konkani"
  },
  {
    "alpha2": "kv",
    "alpha3": "kom",
    "bibliographic": "",
    "name": "Komi"
  },
  {
    "alpha2": "kg",
    "alpha3": "kon",
    "bibliographic": "",
    "name": "Kongo"
  },
  {
    "alpha2": "ko",
    "alpha3": "kor",
    "bibliographic": "",
    "name": "Korean"
  },
  {
    "alpha2": "",
    "alpha3": "kos",
    "bibliographic": "",
    "name": "Kosraean"
  },
  {
    "alpha2": "",
    "alpha3": "kpe",
    "bibliographic": "",
    "name": "Kpelle"
  },
  {
    "alpha2": "",
    "alpha3": "krc",
    "bibliographic": "",
    "name": "Karachay-Balkar"
  },
  {
    "alpha2": "",
    "alpha3": "krl",
    "bibliographic": "",
    "name": "Karelian"
  },
  {
    "alpha2": "",
    "alpha3": "kro",
    "bibliographic": "",
    "name": "Kru languages"
  },
  {
    "alpha2": "",
    "alpha3": "kru",
    "bibliographic": "",
    "name": "Kurukh"
  },
  {
    "alpha2": "kj",
    "alpha3": "kua",
    "bibliographic": "",
    "name": "Kuanyama"
  },
  {
    "alpha2": "kj",
    "alpha3": "kua",
    "bibliographic": "",
    "name": "Kwanyama"
  },
  {
    "alpha2": "",
    "alpha3": "kum",
    "bibliographic": "",
    "name": "Kumyk"
  },
  {
    "alpha2": "ku",
    "alpha3": "kur",
    "bibliographic": "",
    "name": "Kurdish"
  },
  {
    "alpha2": "",
    "alpha3": "kut",
    "bibliographic": "",
    "name": "Kutenai"
  },
  {
    "alpha2": "",
    "alpha3": "lad",
    "bibliographic": "",
    "name": "Ladino"
  },
  {
    "alpha2": "",
    "alpha3": "lah",
    "bibliographic": "",
    "name": "Lahnda"
  },
  {
    "alpha2": "",
    "alpha3": "lam",
    "bibliographic": "",
    "name": "Lamba"
  },
  {
    "alpha2": "lo",
    "alpha3": "lao",
    "bibliographic": "",
    "name": "Lao"
  },
  {
    "alpha2": "la",
    "alpha3": "lat",
    "bibliographic": "",
    "name": "Latin"
  },
  {
    "alpha2": "lv",
    "alpha3": "lav",
    "bibliographic": "",
    "name": "Latvian"
  },
  {
    "alpha2": "",
    "alpha3": "lez",
    "bibliographic": "",
    "name": "Lezghian"
  },
  {
    "alpha2": "li",
    "alpha3": "lim",
    "bibliographic": "",
    "name": "Limburgan"
  },
  {
    "alpha2": "li",
    "alpha3": "lim",
    "bibliographic": "",
    "name": "Limburger"
  },
  {
    "alpha2": "li",
    "alpha3": "lim",
    "bibliographic": "",
    "name": "Limburgish"
  },
  {
    "alpha2": "ln",
    "alpha3": "lin",
    "bibliographic": "",
    "name": "Lingala"
  },
  {
    "alpha2": "lt",
    "alpha3": "lit",
    "bibliographic": "",
    "name": "Lithuanian"
  },
  {
    "alpha2": "",
    "alpha3": "lol",
    "bibliographic": "",
    "name": "Mongo"
  },
  {
    "alpha2": "",
    "alpha3": "loz",
    "bibliographic": "",
    "name": "Lozi"
  },
  {
    "alpha2": "lb",
    "alpha3": "ltz",
    "bibliographic": "",
    "name": "Letzeburgesch"
  },
  {
    "alpha2": "lb",
    "alpha3": "ltz",
    "bibliographic": "",
    "name": "Luxembourgish"
  },
  {
    "alpha2": "",
    "alpha3": "lua",
    "bibliographic": "",
    "name": "Luba-Lulua"
  },
  {
    "alpha2": "lu",
    "alpha3": "lub",
    "bibliographic": "",
    "name": "Luba-Katanga"
  },
  {
    "alpha2": "lg",
    "alpha3": "lug",
    "bibliographic": "",
    "name": "Ganda"
  },
  {
    "alpha2": "",
    "alpha3": "lui",
    "bibliographic": "",
    "name": "Luiseno"
  },
  {
    "alpha2": "",
    "alpha3": "lun",
    "bibliographic": "",
    "name": "Lunda"
  },
  {
    "alpha2": "",
    "alpha3": "luo",
    "bibliographic": "",
    "name": "Luo (Kenya and Tanzania)"
  },
  {
    "alpha2": "",
    "alpha3": "lus",
    "bibliographic": "",
    "name": "Lushai"
  },
  {
    "alpha2": "",
    "alpha3": "mad",
    "bibliographic": "",
    "name": "Madurese"
  },
  {
    "alpha2": "",
    "alpha3": "mag",
    "bibliographic": "",
    "name": "Magahi"
  },
  {
    "alpha2": "mh",
    "alpha3": "mah",
    "bibliographic": "",
    "name": "Marshallese"
  },
  {
    "alpha2": "",
    "alpha3": "mai",
    "bibliographic": "",
    "name": "Maithili"
  },
  {
    "alpha2": "",
    "alpha3": "mak",
    "bibliographic": "",
    "name": "Makasar"
  },
  {
    "alpha2": "ml",
    "alpha3": "mal",
    "bibliographic": "",
    "name": "Malayalam"
  },
  {
    "alpha2": "",
    "alpha3": "man",
    "bibliographic": "",
    "name": "Mandingo"
  },
  {
    "alpha2": "",
    "alpha3": "map",
    "bibliographic": "",
    "name": "Austronesian languages"
  },
  {
    "alpha2": "mr",
    "alpha3": "mar",
    "bibliographic": "",
    "name": "Marathi"
  },
  {
    "alpha2": "",
    "alpha3": "mas",
    "bibliographic": "",
    "name": "Masai"
  },
  {
    "alpha2": "",
    "alpha3": "mdf",
    "bibliographic": "",
    "name": "Moksha"
  },
  {
    "alpha2": "",
    "alpha3": "mdr",
    "bibliographic": "",
    "name": "Mandar"
  },
  {
    "alpha2": "",
    "alpha3": "men",
    "bibliographic": "",
    "name": "Mende"
  },
  {
    "alpha2": "",
    "alpha3": "mga",
    "bibliographic": "",
    "name": "Irish, Middle (900-1200)"
  },
  {
    "alpha2": "",
    "alpha3": "mic",
    "bibliographic": "",
    "name": "Mi'kmaq"
  },
  {
    "alpha2": "",
    "alpha3": "mic",
    "bibliographic": "",
    "name": "Micmac"
  },
  {
    "alpha2": "",
    "alpha3": "min",
    "bibliographic": "",
    "name": "Minangkabau"
  },
  {
    "alpha2": "",
    "alpha3": "mis",
    "bibliographic": "",
    "name": "Uncoded languages"
  },
  {
    "alpha2": "mk",
    "alpha3": "mkd",
    "bibliographic": "mac",
    "name": "Macedonian"
  },
  {
    "alpha2": "",
    "alpha3": "mkh",
    "bibliographic": "",
    "name": "Mon-Khmer languages"
  },
  {
    "alpha2": "mg",
    "alpha3": "mlg",
    "bibliographic": "",
    "name": "Malagasy"
  },
  {
    "alpha2": "mt",
    "alpha3": "mlt",
    "bibliographic": "",
    "name": "Maltese"
  },
  {
    "alpha2": "",
    "alpha3": "mnc",
    "bibliographic": "",
    "name": "Manchu"
  },
  {
    "alpha2": "",
    "alpha3": "mni",
    "bibliographic": "",
    "name": "Manipuri"
  },
  {
    "alpha2": "",
    "alpha3": "mno",
    "bibliographic": "",
    "name": "Manobo languages"
  },
  {
    "alpha2": "",
    "alpha3": "moh",
    "bibliographic": "",
    "name": "Mohawk"
  },
  {
    "alpha2": "mn",
    "alpha3": "mon",
    "bibliographic": "",
    "name": "Mongolian"
  },
  {
    "alpha2": "",
    "alpha3": "mos",
    "bibliographic": "",
    "name": "Mossi"
  },
  {
    "alpha2": "",
    "alpha3": "mot",
    "bibliographic": "",
    "name": "Montenegrin"
  },
  {
    "alpha2": "mi",
    "alpha3": "mri",
    "bibliographic": "mao",
    "name": "Maori"
  },
  {
    "alpha2": "ms",
    "alpha3": "msa",
    "bibliographic": "may",
    "name": "Malay"
  },
  {
    "alpha2": "",
    "alpha3": "mul",
    "bibliographic": "",
    "name": "Multiple languages"
  },
  {
    "alpha2": "",
    "alpha3": "mun",
    "bibliographic": "",
    "name": "Munda languages"
  },
  {
    "alpha2": "",
    "alpha3": "mus",
    "bibliographic": "",
    "name": "Creek"
  },
  {
    "alpha2": "",
    "alpha3": "mwl",
    "bibliographic": "",
    "name": "Mirandese"
  },
  {
    "alpha2": "",
    "alpha3": "mwr",
    "bibliographic": "",
    "name": "Marwari"
  },
  {
    "alpha2": "my",
    "alpha3": "mya",
    "bibliographic": "bur",
    "name": "Burmese"
  },
  {
    "alpha2": "",
    "alpha3": "myn",
    "bibliographic": "",
    "name": "Mayan languages"
  },
  {
    "alpha2": "",
    "alpha3": "myv",
    "bibliographic": "",
    "name": "Erzya"
  },
  {
    "alpha2": "",
    "alpha3": "nah",
    "bibliographic": "",
    "name": "Nahuatl languages"
  },
  {
    "alpha2": "",
    "alpha3": "nai",
    "bibliographic": "",
    "name": "North American Indian languages"
  },
  {
    "alpha2": "",
    "alpha3": "nap",
    "bibliographic": "",
    "name": "Neapolitan"
  },
  {
    "alpha2": "na",
    "alpha3": "nau",
    "bibliographic": "",
    "name": "Nauru"
  },
  {
    "alpha2": "nv",
    "alpha3": "nav",
    "bibliographic": "",
    "name": "Navaho"
  },
  {
    "alpha2": "nv",
    "alpha3": "nav",
    "bibliographic": "",
    "name": "Navajo"
  },
  {
    "alpha2": "nr",
    "alpha3": "nbl",
    "bibliographic": "",
    "name": "Ndebele, South"
  },
  {
    "alpha2": "nr",
    "alpha3": "nbl",
    "bibliographic": "",
    "name": "South Ndebele"
  },
  {
    "alpha2": "nd",
    "alpha3": "nde",
    "bibliographic": "",
    "name": "Ndebele, North"
  },
  {
    "alpha2": "nd",
    "alpha3": "nde",
    "bibliographic": "",
    "name": "North Ndebele"
  },
  {
    "alpha2": "ng",
    "alpha3": "ndo",
    "bibliographic": "",
    "name": "Ndonga"
  },
  {
    "alpha2": "",
    "alpha3": "nds",
    "bibliographic": "",
    "name": "German, Low"
  },
  {
    "alpha2": "",
    "alpha3": "nds",
    "bibliographic": "",
    "name": "Low German"
  },
  {
    "alpha2": "",
    "alpha3": "nds",
    "bibliographic": "",
    "name": "Low Saxon"
  },
  {
    "alpha2": "",
    "alpha3": "nds",
    "bibliographic": "",
    "name": "Saxon, Low"
  },
  {
    "alpha2": "ne",
    "alpha3": "nep",
    "bibliographic": "",
    "name": "Nepali"
  },
  {
    "alpha2": "",
    "alpha3": "new",
    "bibliographic": "",
    "name": "Nepal Bhasa"
  },
  {
    "alpha2": "",
    "alpha3": "new",
    "bibliographic": "",
    "name": "Newari"
  },
  {
    "alpha2": "",
    "alpha3": "nia",
    "bibliographic": "",
    "name": "Nias"
  },
  {
    "alpha2": "",
    "alpha3": "nic",
    "bibliographic": "",
    "name": "Niger-Kordofanian languages"
  },
  {
    "alpha2": "",
    "alpha3": "niu",
    "bibliographic": "",
    "name": "Niuean"
  },
  {
    "alpha2": "nl",
    "alpha3": "nld",
    "bibliographic": "dut",
    "name": "Dutch"
  },
  {
    "alpha2": "nl",
    "alpha3": "nld",
    "bibliographic": "dut",
    "name": "Flemish"
  },
  {
    "alpha2": "nn",
    "alpha3": "nno",
    "bibliographic": "",
    "name": "Norwegian Nynorsk"
  },
  {
    "alpha2": "nn",
    "alpha3": "nno",
    "bibliographic": "",
    "name": "Nynorsk, Norwegian"
  },
  {
    "alpha2": "nb",
    "alpha3": "nob",
    "bibliographic": "",
    "name": "Bokmål, Norwegian"
  },
  {
    "alpha2": "nb",
    "alpha3": "nob",
    "bibliographic": "",
    "name": "Norwegian Bokmål"
  },
  {
    "alpha2": "",
    "alpha3": "nog",
    "bibliographic": "",
    "name": "Nogai"
  },
  {
    "alpha2": "",
    "alpha3": "non",
    "bibliographic": "",
    "name": "Norse, Old"
  },
  {
    "alpha2": "no",
    "alpha3": "nor",
    "bibliographic": "",
    "name": "Norwegian"
  },
  {
    "alpha2": "",
    "alpha3": "nqo",
    "bibliographic": "",
    "name": "N'Ko"
  },
  {
    "alpha2": "",
    "alpha3": "nso",
    "bibliographic": "",
    "name": "Northern Sotho"
  },
  {
    "alpha2": "",
    "alpha3": "nso",
    "bibliographic": "",
    "name": "Pedi"
  },
  {
    "alpha2": "",
    "alpha3": "nso",
    "bibliographic": "",
    "name": "Sepedi"
  },
  {
    "alpha2": "",
    "alpha3": "nso",
    "bibliographic": "",
    "name": "Sotho, Northern"
  },
  {
    "alpha2": "",
    "alpha3": "nub",
    "bibliographic": "",
    "name": "Nubian languages"
  },
  {
    "alpha2": "",
    "alpha3": "nwc",
    "bibliographic": "",
    "name": "Classical Nepal Bhasa"
  },
  {
    "alpha2": "",
    "alpha3": "nwc",
    "bibliographic": "",
    "name": "Classical Newari"
  },
  {
    "alpha2": "",
    "alpha3": "nwc",
    "bibliographic": "",
    "name": "Old Newari"
  },
  {
    "alpha2": "ny",
    "alpha3": "nya",
    "bibliographic": "",
    "name": "Chewa"
  },
  {
    "alpha2": "ny",
    "alpha3": "nya",
    "bibliographic": "",
    "name": "Chichewa"
  },
  {
    "alpha2": "ny",
    "alpha3": "nya",
    "bibliographic": "",
    "name": "Nyanja"
  },
  {
    "alpha2": "",
    "alpha3": "nym",
    "bibliographic": "",
    "name": "Nyamwezi"
  },
  {
    "alpha2": "",
    "alpha3": "nyn",
    "bibliographic": "",
    "name": "Nyankole"
  },
  {
    "alpha2": "",
    "alpha3": "nyo",
    "bibliographic": "",
    "name": "Nyoro"
  },
  {
    "alpha2": "",
    "alpha3": "nzi",
    "bibliographic": "",
    "name": "Nzima"
  },
  {
    "alpha2": "oc",
    "alpha3": "oci",
    "bibliographic": "",
    "name": "Occitan (post 1500)"
  },
  {
    "alpha2": "oj",
    "alpha3": "oji",
    "bibliographic": "",
    "name": "Ojibwa"
  },
  {
    "alpha2": "or",
    "alpha3": "ori",
    "bibliographic": "",
    "name": "Oriya"
  },
  {
    "alpha2": "om",
    "alpha3": "orm",
    "bibliographic": "",
    "name": "Oromo"
  },
  {
    "alpha2": "",
    "alpha3": "osa",
    "bibliographic": "",
    "name": "Osage"
  },
  {
    "alpha2": "os",
    "alpha3": "oss",
    "bibliographic": "",
    "name": "Ossetian"
  },
  {
    "alpha2": "os",
    "alpha3": "oss",
    "bibliographic": "",
    "name": "Ossetic"
  },
  {
    "alpha2": "",
    "alpha3": "ota",
    "bibliographic": "",
    "name": "Turkish, Ottoman (1500-1928)"
  },
  {
    "alpha2": "",
    "alpha3": "oto",
    "bibliographic": "",
    "name": "Otomian languages"
  },
  {
    "alpha2": "",
    "alpha3": "paa",
    "bibliographic": "",
    "name": "Papuan languages"
  },
  {
    "alpha2": "",
    "alpha3": "pag",
    "bibliographic": "",
    "name": "Pangasinan"
  },
  {
    "alpha2": "",
    "alpha3": "pal",
    "bibliographic": "",
    "name": "Pahlavi"
  },
  {
    "alpha2": "",
    "alpha3": "pam",
    "bibliographic": "",
    "name": "Kapampangan"
  },
  {
    "alpha2": "",
    "alpha3": "pam",
    "bibliographic": "",
    "name": "Pampanga"
  },
  {
    "alpha2": "pa",
    "alpha3": "pan",
    "bibliographic": "",
    "name": "Panjabi"
  },
  {
    "alpha2": "pa",
    "alpha3": "pan",
    "bibliographic": "",
    "name": "Punjabi"
  },
  {
    "alpha2": "",
    "alpha3": "pap",
    "bibliographic": "",
    "name": "Papiamento"
  },
  {
    "alpha2": "",
    "alpha3": "pau",
    "bibliographic": "",
    "name": "Palauan"
  },
  {
    "alpha2": "",
    "alpha3": "peo",
    "bibliographic": "",
    "name": "Persian, Old (ca.600-400 B.C.)"
  },
  {
    "alpha2": "",
    "alpha3": "phi",
    "bibliographic": "",
    "name": "Philippine languages"
  },
  {
    "alpha2": "",
    "alpha3": "phn",
    "bibliographic": "",
    "name": "Phoenician"
  },
  {
    "alpha2": "pi",
    "alpha3": "pli",
    "bibliographic": "",
    "name": "Pali"
  },
  {
    "alpha2": "pl",
    "alpha3": "pol",
    "bibliographic": "",
    "name": "Polish"
  },
  {
    "alpha2": "",
    "alpha3": "pon",
    "bibliographic": "",
    "name": "Pohnpeian"
  },
  {
    "alpha2": "pt",
    "alpha3": "por",
    "bibliographic": "",
    "name": "Portuguese"
  },
  {
    "alpha2": "",
    "alpha3": "pra",
    "bibliographic": "",
    "name": "Prakrit languages"
  },
  {
    "alpha2": "",
    "alpha3": "pro",
    "bibliographic": "",
    "name": "Occitan, Old (to 1500)"
  },
  {
    "alpha2": "",
    "alpha3": "pro",
    "bibliographic": "",
    "name": "Provençal, Old (to 1500)"
  },
  {
    "alpha2": "ps",
    "alpha3": "pus",
    "bibliographic": "",
    "name": "Pashto"
  },
  {
    "alpha2": "ps",
    "alpha3": "pus",
    "bibliographic": "",
    "name": "Pushto"
  },
  {
    "alpha2": "qu",
    "alpha3": "que",
    "bibliographic": "",
    "name": "Quechua"
  },
  {
    "alpha2": "",
    "alpha3": "raj",
    "bibliographic": "",
    "name": "Rajasthani"
  },
  {
    "alpha2": "",
    "alpha3": "rap",
    "bibliographic": "",
    "name": "Rapanui"
  },
  {
    "alpha2": "",
    "alpha3": "rar",
    "bibliographic": "",
    "name": "Cook Islands Maori"
  },
  {
    "alpha2": "",
    "alpha3": "rar",
    "bibliographic": "",
    "name": "Rarotongan"
  },
  {
    "alpha2": "",
    "alpha3": "roa",
    "bibliographic": "",
    "name": "Romance languages"
  },
  {
    "alpha2": "rm",
    "alpha3": "roh",
    "bibliographic": "",
    "name": "Romansh"
  },
  {
    "alpha2": "",
    "alpha3": "rom",
    "bibliographic": "",
    "name": "Romany"
  },
  {
    "alpha2": "ro",
    "alpha3": "ron",
    "bibliographic": "rum",
    "name": "Moldavian"
  },
  {
    "alpha2": "ro",
    "alpha3": "ron",
    "bibliographic": "rum",
    "name": "Romanian"
  },
  {
    "alpha2": "rn",
    "alpha3": "run",
    "bibliographic": "",
    "name": "Rundi"
  },
  {
    "alpha2": "",
    "alpha3": "rup",
    "bibliographic": "",
    "name": "Aromanian"
  },
  {
    "alpha2": "",
    "alpha3": "rup",
    "bibliographic": "",
    "name": "Arumanian"
  },
  {
    "alpha2": "",
    "alpha3": "rup",
    "bibliographic": "",
    "name": "Macedo-Romanian"
  },
  {
    "alpha2": "ru",
    "alpha3": "rus",
    "bibliographic": "",
    "name": "Russian"
  },
  {
    "alpha2": "",
    "alpha3": "sad",
    "bibliographic": "",
    "name": "Sandawe"
  },
  {
    "alpha2": "sg",
    "alpha3": "sag",
    "bibliographic": "",
    "name": "Sango"
  },
  {
    "alpha2": "",
    "alpha3": "sah",
    "bibliographic": "",
    "name": "Yakut"
  },
  {
    "alpha2": "",
    "alpha3": "sai",
    "bibliographic": "",
    "name": "South American Indian languages"
  },
  {
    "alpha2": "",
    "alpha3": "sal",
    "bibliographic": "",
    "name": "Salishan languages"
  },
  {
    "alpha2": "",
    "alpha3": "sam",
    "bibliographic": "",
    "name": "Samaritan Aramaic"
  },
  {
    "alpha2": "sa",
    "alpha3": "san",
    "bibliographic": "",
    "name": "Sanskrit"
  },
  {
    "alpha2": "",
    "alpha3": "sas",
    "bibliographic": "",
    "name": "Sasak"
  },
  {
    "alpha2": "",
    "alpha3": "sat",
    "bibliographic": "",
    "name": "Santali"
  },
  {
    "alpha2": "",
    "alpha3": "scn",
    "bibliographic": "",
    "name": "Sicilian"
  },
  {
    "alpha2": "",
    "alpha3": "sco",
    "bibliographic": "",
    "name": "Scots"
  },
  {
    "alpha2": "",
    "alpha3": "sel",
    "bibliographic": "",
    "name": "Selkup"
  },
  {
    "alpha2": "",
    "alpha3": "sem",
    "bibliographic": "",
    "name": "Semitic languages"
  },
  {
    "alpha2": "",
    "alpha3": "sga",
    "bibliographic": "",
    "name": "Irish, Old (to 900)"
  },
  {
    "alpha2": "",
    "alpha3": "sgn",
    "bibliographic": "",
    "name": "Sign Languages"
  },
  {
    "alpha2": "",
    "alpha3": "shn",
    "bibliographic": "",
    "name": "Shan"
  },
  {
    "alpha2": "",
    "alpha3": "sid",
    "bibliographic": "",
    "name": "Sidamo"
  },
  {
    "alpha2": "si",
    "alpha3": "sin",
    "bibliographic": "",
    "name": "Sinhala"
  },
  {
    "alpha2": "si",
    "alpha3": "sin",
    "bibliographic": "",
    "name": "Sinhalese"
  },
  {
    "alpha2": "",
    "alpha3": "sio",
    "bibliographic": "",
    "name": "Siouan languages"
  },
  {
    "alpha2": "",
    "alpha3": "sit",
    "bibliographic": "",
    "name": "Sino-Tibetan languages"
  },
  {
    "alpha2": "",
    "alpha3": "sla",
    "bibliographic": "",
    "name": "Slavic languages"
  },
  {
    "alpha2": "sk",
    "alpha3": "slk",
    "bibliographic": "slo",
    "name": "Slovak"
  },
  {
    "alpha2": "sl",
    "alpha3": "slv",
    "bibliographic": "",
    "name": "Slovenian"
  },
  {
    "alpha2": "",
    "alpha3": "sma",
    "bibliographic": "",
    "name": "Southern Sami"
  },
  {
    "alpha2": "se",
    "alpha3": "sme",
    "bibliographic": "",
    "name": "Northern Sami"
  },
  {
    "alpha2": "",
    "alpha3": "smi",
    "bibliographic": "",
    "name": "Sami languages"
  },
  {
    "alpha2": "",
    "alpha3": "smj",
    "bibliographic": "",
    "name": "Lule Sami"
  },
  {
    "alpha2": "",
    "alpha3": "smn",
    "bibliographic": "",
    "name": "Inari Sami"
  },
  {
    "alpha2": "sm",
    "alpha3": "smo",
    "bibliographic": "",
    "name": "Samoan"
  },
  {
    "alpha2": "",
    "alpha3": "sms",
    "bibliographic": "",
    "name": "Skolt Sami"
  },
  {
    "alpha2": "sn",
    "alpha3": "sna",
    "bibliographic": "",
    "name": "Shona"
  },
  {
    "alpha2": "sd",
    "alpha3": "snd",
    "bibliographic": "",
    "name": "Sindhi"
  },
  {
    "alpha2": "",
    "alpha3": "snk",
    "bibliographic": "",
    "name": "Soninke"
  },
  {
    "alpha2": "",
    "alpha3": "sog",
    "bibliographic": "",
    "name": "Sogdian"
  },
  {
    "alpha2": "so",
    "alpha3": "som",
    "bibliographic": "",
    "name": "Somali"
  },
  {
    "alpha2": "",
    "alpha3": "son",
    "bibliographic": "",
    "name": "Songhai languages"
  },
  {
    "alpha2": "st",
    "alpha3": "sot",
    "bibliographic": "",
    "name": "Sotho, Southern"
  },
  {
    "alpha2": "es",
    "alpha3": "spa",
    "bibliographic": "",
    "name": "Castilian"
  },
  {
    "alpha2": "es",
    "alpha3": "spa",
    "bibliographic": "",
    "name": "Spanish"
  },
  {
    "alpha2": "sq",
    "alpha3": "sqi",
    "bibliographic": "alb",
    "name": "Albanian"
  },
  {
    "alpha2": "sc",
    "alpha3": "srd",
    "bibliographic": "",
    "name": "Sardinian"
  },
  {
    "alpha2": "",
    "alpha3": "srn",
    "bibliographic": "",
    "name": "Sranan Tongo"
  },
  {
    "alpha2": "sr",
    "alpha3": "srp",
    "bibliographic": "",
    "name": "Serbian"
  },
  {
    "alpha2": "",
    "alpha3": "srr",
    "bibliographic": "",
    "name": "Serer"
  },
  {
    "alpha2": "",
    "alpha3": "ssa",
    "bibliographic": "",
    "name": "Nilo-Saharan languages"
  },
  {
    "alpha2": "ss",
    "alpha3": "ssw",
    "bibliographic": "",
    "name": "Swati"
  },
  {
    "alpha2": "",
    "alpha3": "suk",
    "bibliographic": "",
    "name": "Sukuma"
  },
  {
    "alpha2": "su",
    "alpha3": "sun",
    "bibliographic": "",
    "name": "Sundanese"
  },
  {
    "alpha2": "",
    "alpha3": "sus",
    "bibliographic": "",
    "name": "Susu"
  },
  {
    "alpha2": "",
    "alpha3": "sux",
    "bibliographic": "",
    "name": "Sumerian"
  },
  {
    "alpha2": "sw",
    "alpha3": "swa",
    "bibliographic": "",
    "name": "Swahili"
  },
  {
    "alpha2": "sv",
    "alpha3": "swe",
    "bibliographic": "",
    "name": "Swedish"
  },
  {
    "alpha2": "",
    "alpha3": "syc",
    "bibliographic": "",
    "name": "Classical Syriac"
  },
  {
    "alpha2": "",
    "alpha3": "syr",
    "bibliographic": "",
    "name": "Syriac"
  },
  {
    "alpha2": "ty",
    "alpha3": "tah",
    "bibliographic": "",
    "name": "Tahitian"
  },
  {
    "alpha2": "",
    "alpha3": "tai",
    "bibliographic": "",
    "name": "Tai languages"
  },
  {
    "alpha2": "ta",
    "alpha3": "tam",
    "bibliographic": "",
    "name": "Tamil"
  },
  {
    "alpha2": "tt",
    "alpha3": "tat",
    "bibliographic": "",
    "name": "Tatar"
  },
  {
    "alpha2": "te",
    "alpha3": "tel",
    "bibliographic": "",
    "name": "Telugu"
  },
  {
    "alpha2": "",
    "alpha3": "tem",
    "bibliographic": "",
    "name": "Timne"
  },
  {
    "alpha2": "",
    "alpha3": "ter",
    "bibliographic": "",
    "name": "Tereno"
  },
  {
    "alpha2": "",
    "alpha3": "tet",
    "bibliographic": "",
    "name": "Tetum"
  },
  {
    "alpha2": "tg",
    "alpha3": "tgk",
    "bibliographic": "",
    "name": "Tajik"
  },
  {
    "alpha2": "tl",
    "alpha3": "tgl",
    "bibliographic": "",
    "name": "Tagalog"
  },
  {
    "alpha2": "th",
    "alpha3": "tha",
    "bibliographic": "",
    "name": "Thai"
  },
  {
    "alpha2": "",
    "alpha3": "tig",
    "bibliographic": "",
    "name": "Tigre"
  },
  {
    "alpha2": "ti",
    "alpha3": "tir",
    "bibliographic": "",
    "name": "Tigrinya"
  },
  {
    "alpha2": "",
    "alpha3": "tiv",
    "bibliographic": "",
    "name": "Tiv"
  },
  {
    "alpha2": "",
    "alpha3": "tkl",
    "bibliographic": "",
    "name": "Tokelau"
  },
  {
    "alpha2": "",
    "alpha3": "tlh",
    "bibliographic": "",
    "name": "Klingon"
  },
  {
    "alpha2": "",
    "alpha3": "tlh",
    "bibliographic": "",
    "name": "tlhIngan-Hol"
  },
  {
    "alpha2": "",
    "alpha3": "tli",
    "bibliographic": "",
    "name": "Tlingit"
  },
  {
    "alpha2": "",
    "alpha3": "tmh",
    "bibliographic": "",
    "name": "Tamashek"
  },
  {
    "alpha2": "",
    "alpha3": "tog",
    "bibliographic": "",
    "name": "Tonga (Nyasa)"
  },
  {
    "alpha2": "to",
    "alpha3": "ton",
    "bibliographic": "",
    "name": "Tonga (Tonga Islands)"
  },
  {
    "alpha2": "",
    "alpha3": "tpi",
    "bibliographic": "",
    "name": "Tok Pisin"
  },
  {
    "alpha2": "",
    "alpha3": "tsi",
    "bibliographic": "",
    "name": "Tsimshian"
  },
  {
    "alpha2": "tn",
    "alpha3": "tsn",
    "bibliographic": "",
    "name": "Tswana"
  },
  {
    "alpha2": "ts",
    "alpha3": "tso",
    "bibliographic": "",
    "name": "Tsonga"
  },
  {
    "alpha2": "tk",
    "alpha3": "tuk",
    "bibliographic": "",
    "name": "Turkmen"
  },
  {
    "alpha2": "",
    "alpha3": "tum",
    "bibliographic": "",
    "name": "Tumbuka"
  },
  {
    "alpha2": "",
    "alpha3": "tup",
    "bibliographic": "",
    "name": "Tupi languages"
  },
  {
    "alpha2": "tr",
    "alpha3": "tur",
    "bibliographic": "",
    "name": "Turkish"
  },
  {
    "alpha2": "",
    "alpha3": "tut",
    "bibliographic": "",
    "name": "Altaic languages"
  },
  {
    "alpha2": "",
    "alpha3": "tvl",
    "bibliographic": "",
    "name": "Tuvalu"
  },
  {
    "alpha2": "tw",
    "alpha3": "twi",
    "bibliographic": "",
    "name": "Twi"
  },
  {
    "alpha2": "",
    "alpha3": "tyv",
    "bibliographic": "",
    "name": "Tuvinian"
  },
  {
    "alpha2": "",
    "alpha3": "udm",
    "bibliographic": "",
    "name": "Udmurt"
  },
  {
    "alpha2": "",
    "alpha3": "uga",
    "bibliographic": "",
    "name": "Ugaritic"
  },
  {
    "alpha2": "ug",
    "alpha3": "uig",
    "bibliographic": "",
    "name": "Uighur"
  },
  {
    "alpha2": "ug",
    "alpha3": "uig",
    "bibliographic": "",
    "name": "Uyghur"
  },
  {
    "alpha2": "uk",
    "alpha3": "ukr",
    "bibliographic": "",
    "name": "Ukrainian"
  },
  {
    "alpha2": "",
    "alpha3": "umb",
    "bibliographic": "",
    "name": "Umbundu"
  },
  {
    "alpha2": "",
    "alpha3": "und",
    "bibliographic": "",
    "name": "Undetermined"
  },
  {
    "alpha2": "ur",
    "alpha3": "urd",
    "bibliographic": "",
    "name": "Urdu"
  },
  {
    "alpha2": "uz",
    "alpha3": "uzb",
    "bibliographic": "",
    "name": "Uzbek"
  },
  {
    "alpha2": "",
    "alpha3": "vai",
    "bibliographic": "",
    "name": "Vai"
  },
  {
    "alpha2": "ve",
    "alpha3": "ven",
    "bibliographic": "",
    "name": "Venda"
  },
  {
    "alpha2": "vi",
    "alpha3": "vie",
    "bibliographic": "",
    "name": "Vietnamese"
  },
  {
    "alpha2": "vo",
    "alpha3": "vol",
    "bibliographic": "",
    "name": "Volapük"
  },
  {
    "alpha2": "",
    "alpha3": "vot",
    "bibliographic": "",
    "name": "Votic"
  },
  {
    "alpha2": "",
    "alpha3": "wak",
    "bibliographic": "",
    "name": "Wakashan languages"
  },
  {
    "alpha2": "",
    "alpha3": "wal",
    "bibliographic": "",
    "name": "Wolaitta"
  },
  {
    "alpha2": "",
    "alpha3": "wal",
    "bibliographic": "",
    "name": "Wolaytta"
  },
  {
    "alpha2": "",
    "alpha3": "war",
    "bibliographic": "",
    "name": "Waray"
  },
  {
    "alpha2": "",
    "alpha3": "was",
    "bibliographic": "",
    "name": "Washo"
  },
  {
    "alpha2": "",
    "alpha3": "wen",
    "bibliographic": "",
    "name": "Sorbian languages"
  },
  {
    "alpha2": "wa",
    "alpha3": "wln",
    "bibliographic": "",
    "name": "Walloon"
  },
  {
    "alpha2": "wo",
    "alpha3": "wol",
    "bibliographic": "",
    "name": "Wolof"
  },
  {
    "alpha2": "",
    "alpha3": "xal",
    "bibliographic": "",
    "name": "Kalmyk"
  },
  {
    "alpha2": "",
    "alpha3": "xal",
    "bibliographic": "",
    "name": "Oirat"
  },
  {
    "alpha2": "xh",
    "alpha3": "xho",
    "bibliographic": "",
    "name": "Xhosa"
  },
  {
    "alpha2": "",
    "alpha3": "yao",
    "bibliographic": "",
    "name": "Yao"
  },
  {
    "alpha2": "",
    "alpha3": "yap",
    "bibliographic": "",
    "name": "Yapese"
  },
  {
    "alpha2": "yi",
    "alpha3": "yid",
    "bibliographic": "",
    "name": "Yiddish"
  },
  {
    "alpha2": "yo",
    "alpha3": "yor",
    "bibliographic": "",
    "name": "Yoruba"
  },
  {
    "alpha2": "",
    "alpha3": "ypk",
    "bibliographic": "",
    "name": "Yupik languages"
  },
  {
    "alpha2": "",
    "alpha3": "zap",
    "bibliographic": "",
    "name": "Zapotec"
  },
  {
    "alpha2": "",
    "alpha3": "zbl",
    "bibliographic": "",
    "name": "Bliss"
  },
  {
    "alpha2": "",
    "alpha3": "zbl",
    "bibliographic": "",
    "name": "Blissymbolics"
  },
  {
    "alpha2": "",
    "alpha3": "zbl",
    "bibliographic": "",
    "name": "Blissymbols"
  },
  {
    "alpha2": "",
    "alpha3": "zen",
    "bibliographic": "",
    "name": "Zenaga"
  },
  {
    "alpha2": "",
    "alpha3": "zgh",
    "bibliographic": "",
    "name": "Standard Moroccan Tamazight"
  },
  {
    "alpha2": "za",
    "alpha3": "zha",
    "bibliographic": "",
    "name": "Chuang"
  },
  {
    "alpha2": "za",
    "alpha3": "zha",
    "bibliographic": "",
    "name": "Zhuang"
  },
  {
    "alpha2": "zh",
    "alpha3": "zho",
    "bibliographic": "chi",
    "name": "Chinese"
  },
  {
    "alpha2": "",
    "alpha3": "znd",
    "bibliographic": "",
    "name": "Zande languages"
  },
  {
    "alpha2": "zu",
    "alpha3": "zul",
    "bibliographic": "",
    "name": "Zulu"
  },
  {
    "alpha2": "",
    "alpha3": "zun",
    "bibliographic": "",
    "name": "Zuni"
  },
  {
    "alpha2": "",
    "alpha3": "zxx",
    "bibliographic": "",
    "name": "No linguistic content"
  },
  {
    "alpha2": "",
    "alpha3": "zxx",
    "bibliographic": "",
    "name": "Not applicable"
  },
  {
    "alpha2": "",
    "alpha3": "zza",
    "bibliographic": "",
    "name": "Dimili"
  },
  {
    "alpha2": "",
    "alpha3": "zza",
    "bibliographic": "",
    "name": "Dimli"
  },
  {
    "alpha2": "",
    "alpha3": "zza",
    "bibliographic": "",
    "name": "Kirdki"
  },
  {
    "alpha2": "",
    "alpha3": "zza",
    "bibliographic": "",
    "name": "Kirmanjki"
  },
  {
    "alpha2": "",
    "alpha3": "zza",
    "bibliographic": "",
    "name": "Zaza"
  },
  {
    "alpha2": "",
    "alpha3": "zza",
    "bibliographic": "",
    "name": "Zazaki"
  }
]
