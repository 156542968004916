export const formatCurrency = (value, isMobile = false) => {
  if (isMobile) {
    return Math.abs(value) > 999
      ? (Math.abs(value) / 1000).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ',') + 'k'
      : Math.abs(value)
  } else {
    return Math.trunc(value)
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  }
}

export const getRelativeTime = (inputDate, currentDate = new Date()) => {
  const past = new Date(inputDate)
  const diffInSeconds = Math.floor((currentDate - past) / 1000)

  const intervals = [
    { label: 'year', seconds: 31536000 },
    { label: 'month', seconds: 2592000 },
    { label: 'day', seconds: 86400 },
    { label: 'hour', seconds: 3600 },
    { label: 'minute', seconds: 60 },
    { label: 'second', seconds: 1 },
  ]

  for (const interval of intervals) {
    const count = Math.floor(diffInSeconds / interval.seconds)
    if (count >= 1) {
      return `${count} ${interval.label}${count !== 1 ? 's' : ''} ago`
    }
  }

  return 'just now'
}
