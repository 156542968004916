import { useState, useEffect, createContext, useContext, PropsWithChildren } from 'react'
import PropTypes from 'prop-types'
import decodeJWT, { JwtPayload } from 'jwt-decode'
import { useHistory } from 'react-router-dom'
import { refreshUserToken } from './api'

export interface AuthTokenRole {
  r: string
  id?: string
  t?: string
  p?: string[]
}

export interface AuthToken extends JwtPayload {
  roles: AuthTokenRole[]
}

export interface AuthState {
  token: string | null
  claims: AuthToken | null
  expired: boolean
  expiration: number
  roles: { [key: string]: AuthTokenRole[] }
  email?: string
}

export interface AuthContextState {
  auth: AuthState
  tokenKey: string
  setToken: (token: string, email: string) => void
}
/** High-level component that provides an authentication context - i.e. all nested
 * components have access to information about whether the current user is authenticated
 * or not. Useful at a top level in the app hierarchy to create an authentication system.
 * Lower-level components then use the `useAuth` hook to access this authentication information. */
export const AuthProvider = ({ children, tokenKey }: PropsWithChildren<{ tokenKey: string }>) => {
  // Manage 'auth' data in state - initialised from storage if any
  const [auth, setAuth] = useState<AuthState>(getAuthFromStorage(tokenKey))
  const history = useHistory() // To push user to login page etc

  useEffect(() => {
    const interval = setInterval(() => {
      if (document.visibilityState === 'visible') {
        const token = auth?.token ?? ''
        // Check how long till our token expires - no need to refresh too early
        const secondsTillExpiration = (auth?.expiration ?? 0) - Date.now() / 1000

        // If we don't have a current token; or if we have more than 3 minutes left, don't refresh
        // Server will only provide a fresh token if age is less than 5 minutes.
        if (!token || secondsTillExpiration > 180) return

        refreshUserToken(auth.token).then((res) => {
          if (res && typeof res == 'object') {
            // API will return existing token if it's unnecessary to refresh
            if (res.new_token != auth.token) {
              setToken(res.new_token, auth.email)
            }
          }
        })
      }
    }, 30000)

    return () => clearInterval(interval)
  }, [auth?.token || ''])

  useEffect(() => {
    window.onstorage = (e) => {
      // This function only executes on tabs that are not in focus and grabs the storage event once updated
      if (e.key == `${tokenKey}:auth` && e?.newValue) {
        /* We check the key to see if the key matches the token key as well as if there is a new value within the 
        object. It then gets parsed as JSON before being sent to the setToken method to update the token and also 
        if there are any errors with decoding the token then it gets handled by the same method */
        const parsed = JSON.parse(e.newValue) as { token: string; email: string }
        setToken(parsed?.token ?? '', parsed?.email)
      }
    }
  }, [])

  // A new token was obtained, such as by logging in or out
  const setToken = (token: string, email?: string) => {
    if (token && email) {
      // Non-empty token received
      try {
        const claims = decodeJWT<AuthToken>(token)
        const roles = tokenToRolesMap(claims)
        const expired = false
        setAuth({ ...auth, token, claims, expired, roles, email, expiration: claims?.exp ?? 0 })
        setAuthInStorage(tokenKey, token, email)
      } catch (e) {
        // Bad token - what can we do?
        console.error(e)
        window.alert('Your authentication token was damaged. Please sign in again to restore it.')
        setAuth(emptyAuth.auth)
        if (history) {
          history.push('/sign-in')
        }
      }
    } else {
      // Token removed
      setAuth(emptyAuth.auth)
      clearAuthInStorage(tokenKey)
    }
  }

  return <Auth.Provider value={{ auth, setToken, tokenKey }}>{children}</Auth.Provider>
}
AuthProvider.propTypes = {
  children: PropTypes.any.isRequired,
  /** Key used to store or access the user's auth token in durable storage,
   * such as with window.localStorage or cookie. */
  tokenKey: PropTypes.string.isRequired,
}

/** React hook to access information about the currently-authenticated
 * user. Returns an object of:
 * {
 *  token: string,  // Raw JWT
 *  claims: {}, // Unpacked JWT containing claims
 *  roles: { roleName: []context }, // Which roles the user has, and optionally for 'who'
 *  setToken: jwt -> (), // Function to change / overwrite the token, such as in a login page
 * }
 */
export const useAuth = () => {
  const { auth, setToken } = useContext<AuthContextState>(Auth)
  return { ...auth, setToken }
}

/** The structure of the `auth` value, returned
 * and managed by the `Auth` context. */
const emptyAuth: AuthContextState = {
  auth: {
    token: null,
    claims: null,
    expired: true,
    roles: {},
    email: '',
    expiration: 0,
  },
  tokenKey: '',
  setToken: () => {},
}

/** Auth is a react context that makes the current user authentication available to
 * child components. */
const Auth = createContext<AuthContextState>(emptyAuth) // FIXME: Make private
Auth.displayName = 'Auth'

// Remove existing auth from storage
const clearAuthInStorage = (tokenKey: string) => {
  localStorage.removeItem(`${tokenKey}:auth`)
}

// Set new auth in storage
const setAuthInStorage = (tokenKey: string, token: string, email: string) => {
  const payload = JSON.stringify({ token, email })
  localStorage.setItem(`${tokenKey}:auth`, payload)
}

/** Produces auth data by reading stored state in the browser. Useful
 * to initialise auth the first time. */
const getAuthFromStorage = (tokenKey: string) => {
  const payload = localStorage.getItem(`${tokenKey}:auth`)

  const parsed = payload ? (JSON.parse(payload) as { token: string; email: string }) : undefined
  const claims = parsed?.token ? decodeJWT<AuthToken>(parsed.token) : { roles: [] }
  const roles = claims ? tokenToRolesMap(claims) : {}
  const expired = parsed?.email && claims && claims.exp ? claims.exp < Date.now() / 1000 : true
  const auth: AuthState = {
    token: parsed?.token ?? '',
    claims,
    expired,
    roles,
    email: parsed?.email,
    expiration: claims?.exp ?? 0,
  }
  return auth
}

/** tokenToRolesMap accepts a decoded token, containing a nested `roles` list
 * of context roles, and produces a simple { roleName: [context]} map, ignoring
 * context type etc. It's assumed that context type will be simple enough and
 * never be mixed, for the purposes of this UI (e.g. if you are a `back-office`
 * the values will always be merchant IDs)*/
export const tokenToRolesMap = ({ roles = [] }: { roles: AuthTokenRole[] }) =>
  roles
    ? roles.reduce<{ [key: string]: AuthTokenRole[] }>((m, ctxRole) => {
        const name = ctxRole.r
        if (name) {
          if (!m[name]) {
            m[name] = []
          }
          m[name].push(ctxRole)
        }
        return m
      }, {})
    : {}
