import { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { pathOr, toPairs, omit, reduce } from 'ramda'
import classnames from 'classnames'
import { useAuth } from '../auth'
import { isController, isControllerNoMerchant, isMintMyne, isOperator } from '../auth-roles'
import Country from '../Country'
import AmountWithCurrency from '../AmountWithCurrency'
import {
  statsSortedByCount,
  statsSortedByValueSettledEUR,
  hasAnyValueSettledEUR,
  statsSortedByAcceptance,
} from './stats'
import AcceptanceRateSettledValue from '../AcceptanceRateSettledValue'
import CountByStatus from '../CountByStatus'
import { useOrgScope } from '../org-scope'
import questionAnsweredSuccessImage from '../assets/questions-answered-success.png'
import questionAnsweredFailedImage from '../assets/questions-answered-failed.png'
import verificationFailedImage from '../assets/verification-failed.png'
import verificationSuccessImage from '../assets/vefirication-success.png'
import { getKycStatusByEmail } from '../api'

// Embedded filters
import EmailFilter from './filters/EmailFilter'

const TxnsDashboardHeader = ({ filter, setFilter, stats = {}, merchantByID = {} }) => {
  const { roles, email } = useAuth()
  const isUserOperator = isOperator(roles)
  const isUserController = isController(roles) || isControllerNoMerchant(roles)
  const isUserMintMyne = isMintMyne(email)

  return (
    <header className='dashboard'>
      {/* Modules - note, they are adaptive, each may elect to produce
        no output if not relevant to the given stats. */}
      <AcceptanceRateSettledValue {...stats} />
      {/* <CardHolderEmail filter={filter} setFilter={setFilter} /> */}
      {(isUserOperator || isUserController) && <CountByStatus {...stats} />}
      {!isUserMintMyne && <TopCountries {...stats} />}
      {!isUserMintMyne && <TopMerchants merchantByID={merchantByID} {...stats} />}
    </header>
  )
}

TxnsDashboardHeader.propTypes = {
  // Status / summary object from which primary display is derived
  stats: PropTypes.object,
  // Full transactions list (probably not needed though!)
  txns: PropTypes.array,
  // Used to support any nested filtering operations, e.g.
  // click something to "isolate" the data for that thing.
  filter: PropTypes.object.isRequired,
  // Used to support any nested filtering operations, e.g.
  // click something to "isolate" the data for that thing.
  setFilter: PropTypes.func.isRequired,
  // map of visible merchants: {id : { id, registered_name }} - TODO: Replace with context
  merchantByID: PropTypes.object,
}

/** CardHolderEmail is a filter by email, rendered as a dashboard header module */
const CardHolderEmail = ({ filter, setFilter }) => {
  const [verificationStatus, setVerificationStatus] = useState(false)
  const [questionAnsweredStatus, setQuestionAnsweredStatus] = useState(false)
  const { token } = useAuth()

  const fetchKycStatus = async () => {
    try {
      const kycStatus = await getKycStatusByEmail(token, filter.email)

      if (kycStatus?.kyc_level > 0) {
        setVerificationStatus(true)
      } else {
        setVerificationStatus(false)
      }
      if (kycStatus?.kyc_questions) {
        setQuestionAnsweredStatus(true)
      } else {
        setQuestionAnsweredStatus(false)
      }
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    if (filter.email) {
      fetchKycStatus()
    }
  }, [filter.email])

  return (
    <div className='module cardholder-email'>
      <div className='content user-email-container'>
        <div>
          <label htmlFor='email-filter-input'>Find by customer email:</label>
          <EmailFilter filter={filter} setFilter={setFilter} />
        </div>
        {filter.email && (
          <div className='email-kyc-status-container'>
            {verificationStatus && <img src={verificationSuccessImage} width='64' height='64' />}
            {!verificationStatus && <img src={verificationFailedImage} width='64' height='64' />}
            {questionAnsweredStatus && (
              <img src={questionAnsweredSuccessImage} width='64' height='64' />
            )}
            {!questionAnsweredStatus && (
              <img src={questionAnsweredFailedImage} width='64' height='64' />
            )}
          </div>
        )}
      </div>
    </div>
  )
}
CardHolderEmail.propTypes = {
  filter: PropTypes.object.isRequired,
  setFilter: PropTypes.func.isRequired,
}

/** TopCountries displays a small dashboard widget that summarises the top contries
 * (volumes, amounts, and a per-status breakdown). It displays a "Top X" and allows
 * the user to expand "the rest" which is rendered e.g. as a scrollable modal dialog
 * with CSS defined elsewhere. */
const TopCountries = ({ stats_by_bill_cntry = {}, displayCount = 5 }) => {
  // TODO: User-selectable "which countries" - use stats_by_ip_cntry and stats_by_bin_cntry

  const stats = stats_by_bill_cntry

  // User-selectable 'rank by'
  const [rankBy, setRankBy] = useState('count')
  const onChangeRankBy = (e) => {
    setRankBy(e.target.value)
  }
  // Produce ranked list of top countries
  const topCountries =
    rankBy === 'count'
      ? statsSortedByCount(stats)
      : rankBy === 'value-settled-eur'
      ? statsSortedByValueSettledEUR(stats)
      : rankBy === 'acceptance'
      ? statsSortedByAcceptance(stats)
      : []

  // Trim to 'top X' which is displayed, with optional "Y more" button
  const topCountriesShow = topCountries.slice(0, displayCount)
  const moreCount = topCountries.length - topCountriesShow.length
  // Is user busy 'viewing more'
  const [viewMore, setViewMore] = useState(false)
  const toggleViewMore = () => setViewMore(!viewMore)

  // Adaptive - only render if there are 2+ countries
  if (topCountries.length <= 1) {
    return null
  }

  const rankSelector = (
    <select value={rankBy} onChange={onChangeRankBy}>
      <option value='count'>Transaction count</option>
      {hasAnyValueSettledEUR(stats) && <option value='value-settled-eur'>Settled value</option>}
      <option value='acceptance'>Acceptance rate</option>
    </select>
  )

  return (
    <div className='module count-by-country'>
      <label>
        Top countries
        <span className='controls'>{rankSelector}</span>
      </label>
      {topCountriesShow.map(([country, val, byStatus = {}]) => (
        <div key={country} className={classnames({ count: true, country: true })}>
          <Country iso={country} />
          <span className='val'>
            {val == null ? (
              '0'
            ) : rankBy == 'value-settled-eur' ? (
              <AmountWithCurrency amount={val} currency='EUR' />
            ) : rankBy == 'acceptance' ? (
              val.toLocaleString(undefined, { style: 'percent' })
            ) : (
              val.toLocaleString()
            )}
          </span>
          <InlineStatusSummary statusCounts={byStatus.value} />
        </div>
      ))}
      {viewMore && (
        /* TODO: Close with ESC key */ <div className='view-more'>
          <h1>
            Top countries
            <span className='controls'>
              {rankSelector}
              <i className='fas fa-times clickable close' title='Hide' onClick={toggleViewMore} />
            </span>
          </h1>
          {/* TODO: Controls to switch mode */}
          <div className='content'>
            {topCountries.map(([country, val, byStatus = {}], n) => (
              <div key={country} className={classnames({ count: true, country: true })}>
                <span className='rank'>{n + 1}.</span>
                <Country iso={country} />
                <span className='val'>
                  {val == null ? (
                    '0'
                  ) : rankBy == 'value-settled-eur' ? (
                    <AmountWithCurrency amount={val} currency='EUR' />
                  ) : rankBy == 'acceptance' ? (
                    val.toLocaleString(undefined, { style: 'percent' })
                  ) : (
                    val.toLocaleString()
                  )}
                </span>
                <InlineStatusSummary statusCounts={byStatus.value} />
              </div>
            ))}
          </div>
        </div>
      )}
      {moreCount > 0 && (
        <div className='count country more-indicator clickable' onClick={toggleViewMore}>
          {viewMore ? 'Hide' : 'See all'} ({moreCount} more)
        </div>
      )}
    </div>
  )
}
TopCountries.propTypes = {
  //stats_by_ip_cntry: PropTypes.object,
  //stats_by_bin_cntry: PropTypes.object,
  stats_by_bill_cntry: PropTypes.object,
  // How many to display (rest is rolled into a "more" display...)
  displayCount: PropTypes.number,
}

/** TopMerchants displays a small dashboard widget that summarises the top merchants
 * (volumes, amounts, and a per-status breakdown). It displays a "Top X" and allows
 * the user to expand "the rest" which is rendered e.g. as a scrollable modal dialog
 * with CSS defined elsewhere. */
const TopMerchants = ({ stats_by_merch_id = {}, displayCount = 5 }) => {
  const { byID } = useOrgScope()
  const stats = stats_by_merch_id || {}

  // TODO: Use context of 'all merchants'

  // User-selectable 'rank by'
  const [rankBy, setRankBy] = useState('count')
  const onChangeRankBy = (e) => {
    setRankBy(e.target.value)
  }
  // Produce ranked list of top merchants
  const top =
    rankBy === 'count'
      ? statsSortedByCount(stats)
      : rankBy === 'value-settled-eur'
      ? statsSortedByValueSettledEUR(stats)
      : rankBy === 'acceptance'
      ? statsSortedByAcceptance(stats)
      : []

  // Trim to 'top X' which is displayed, with optional "Y more" button
  const topShow = top.slice(0, displayCount)
  const moreCount = top.length - topShow.length
  // Is user busy 'viewing more'
  const [viewMore, setViewMore] = useState(false)
  const toggleViewMore = () => setViewMore(!viewMore)

  // Adaptive - only render if there are 2+ merchants
  if (top.length <= 1) {
    return null
  }

  const rankSelector = (
    <select value={rankBy} onChange={onChangeRankBy}>
      <option value='count'>Transaction count</option>
      {hasAnyValueSettledEUR(stats) && <option value='value-settled-eur'>Settled value</option>}
      <option value='acceptance'>Acceptance rate</option>
    </select>
  )

  return (
    <div className='module count-by-merchant'>
      <label>
        Top merchants
        <span className='controls'>{rankSelector}</span>
      </label>
      {topShow.map(([mid, val, byStatus = {}]) => {
        return (
          <div key={mid} className={classnames({ count: true, merchant: true })}>
            <span className='merchant'>{pathOr(mid, [mid, 'name'], byID)}</span>
            <span className='val'>
              {val == null ? (
                '0'
              ) : rankBy == 'value-settled-eur' ? (
                <AmountWithCurrency amount={val} currency='EUR' />
              ) : rankBy == 'acceptance' ? (
                val.toLocaleString(undefined, { style: 'percent' })
              ) : (
                val.toLocaleString()
              )}
            </span>
            <InlineStatusSummary statusCounts={byStatus.value} />
          </div>
        )
      })}
      {viewMore && (
        /* TODO: Close with ESC key */ <div className='view-more'>
          <h1>
            Top merchants
            <span className='controls'>
              {rankSelector}
              <i className='fas fa-times clickable close' title='Hide' onClick={toggleViewMore} />
            </span>
          </h1>
          {/* TODO: Controls to switch mode */}
          <div className='content'>
            {top.map(([mid, val, byStatus = {}], n) => {
              return (
                <div key={mid} className={classnames({ count: true, merchant: true })}>
                  <span className='rank'>{n + 1}.</span>
                  <span className='merchant'>{pathOr(mid, [mid, 'name'], byID)}</span>
                  <span className='val'>
                    {val == null ? (
                      '0'
                    ) : rankBy == 'value-settled-eur' ? (
                      <AmountWithCurrency amount={val} currency='EUR' />
                    ) : rankBy == 'acceptance' ? (
                      val.toLocaleString(undefined, { style: 'percent' })
                    ) : (
                      val.toLocaleString()
                    )}
                  </span>
                  <InlineStatusSummary statusCounts={byStatus.value} />
                </div>
              )
            })}
          </div>
        </div>
      )}
      {moreCount > 0 && (
        <div className='count merchant more-indicator clickable' onClick={toggleViewMore}>
          {viewMore ? 'Hide' : 'See all'} ({moreCount} more)
        </div>
      )}
    </div>
  )
}
TopMerchants.propTypes = {
  stats_by_merch_id: PropTypes.object,
  // How many to display (rest is rolled into a "more" display...)
  displayCount: PropTypes.number,
  // map of visible merchants: {id : { id, registered_name }} - TODO: Replace with context
  merchantByID: PropTypes.object,
}

/* Displays a small, inline summary of status counts,
 * e.g. 435 ok | 123 failed | 362 other
 */
const InlineStatusSummary = ({ statusCounts = {} }) => {
  const ok = statusCounts.ok
  const failed = statusCounts.failed
  const other = reduce(
    (total, pair) => {
      return total + pair[1]
    },
    0,
    toPairs(omit(['ok', 'failed'], statusCounts))
  )
  return (
    <span className='count-by-status'>
      <span className='ok' title='Successful transactions'>
        <i className='fas fa-check' />
        <span className='val'>{ok || '0'}</span>
      </span>
      <span className='failed' title='Failed transactions'>
        <i className='fas fa-times' />
        <span className='val'>{failed || '0'}</span>
      </span>
      <span className='other' title='Transactions with other status (pending, 3ds, etc)'>
        <i className='fas fa-ellipsis-h' />
        <span className='val'>{other || '0'}</span>
      </span>
    </span>
  )
}
InlineStatusSummary.propTypes = {
  statusCounts: PropTypes.object.isRequired,
}

export default TxnsDashboardHeader
