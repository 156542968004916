// map of failureCode to description
export const majorErrorCodes = new Map()
majorErrorCodes.set(0, 'General failure') // these should be extremely rare
majorErrorCodes.set(1, 'Invalid card information')
majorErrorCodes.set(2, 'Stolen card')
majorErrorCodes.set(3, 'Expired card')
majorErrorCodes.set(4, 'Declined by upstream (post-verification)')
majorErrorCodes.set(5, 'Invalid order amount')
majorErrorCodes.set(6, 'Scoring failed')
majorErrorCodes.set(7, 'Currency not supported')
majorErrorCodes.set(8, 'Fraudulent transaction')
majorErrorCodes.set(9, 'Declined by upstream (pre-verification)')
majorErrorCodes.set(10, 'Merchant not active')
majorErrorCodes.set(11, 'Invalid customer destination URL')
majorErrorCodes.set(13, 'Invalid callback URL')
majorErrorCodes.set(14, 'Un-routable transaction / no upstream bank willing to accept')
majorErrorCodes.set(15, 'Invalid card holder details')
majorErrorCodes.set(16, 'Currency not configured')
majorErrorCodes.set(17, 'Already processed')
majorErrorCodes.set(18, 'Insufficient information')
//majorErrorCodes.set(22, 'Abandoned') // Abandoned not used as major code since we alias it as a full status

export const minorErrorCodes = new Map()
minorErrorCodes.set(1, 'Decline 3DS check failed')
minorErrorCodes.set(2, 'Invalid CVV')
minorErrorCodes.set(3, 'Communication error')
minorErrorCodes.set(4, 'Exceeds frequency limit')
minorErrorCodes.set(5, 'Exceeds acquirer limit')
minorErrorCodes.set(6, 'Unsupported brand')
minorErrorCodes.set(7, 'Limit exceeded')
minorErrorCodes.set(8, 'Suspected fraud')
minorErrorCodes.set(9, 'Do not honor')
minorErrorCodes.set(11, 'Declined by external scoring')
minorErrorCodes.set(12, 'Blocked BIN')
minorErrorCodes.set(13, 'Invalid PAN')
minorErrorCodes.set(14, 'Restricted customer')
minorErrorCodes.set(15, 'Blocked customer')
minorErrorCodes.set(16, 'Customer requires verification')
minorErrorCodes.set(41, 'Test card')
minorErrorCodes.set(42, 'Transaction deleted or refunded')
minorErrorCodes.set(43, 'Data issue')
minorErrorCodes.set(44, 'Authorization required')
minorErrorCodes.set(45, 'Authorization failure')
minorErrorCodes.set(46, 'Invalid transaction')
minorErrorCodes.set(47, 'Transaction cancelled')
minorErrorCodes.set(48, 'Bad payload')
minorErrorCodes.set(49, 'Invalid currency')
minorErrorCodes.set(100, 'Decline (general, no comments)')
minorErrorCodes.set(101, 'Decline, expired card')
minorErrorCodes.set(104, 'Decline, restricted card')
minorErrorCodes.set(109, 'Decline, invalid merchant')
minorErrorCodes.set(110, 'Decline, invalid amount')
minorErrorCodes.set(111, 'Decline, invalid card number')
minorErrorCodes.set(116, 'Decline, not sufficient funds')
minorErrorCodes.set(117, 'Decline, incorrect PIN')
minorErrorCodes.set(119, 'Decline, transaction not permitted to cardholder')
minorErrorCodes.set(124, 'Decline, violation of law')
minorErrorCodes.set(141, 'Decline, system malfunction')
minorErrorCodes.set(142, 'Unknown payment route')
minorErrorCodes.set(182, 'Decline, blocked card')
minorErrorCodes.set(208, 'Pick-up, lost card')
minorErrorCodes.set(221, 'Abandoned, fingerprinting')
minorErrorCodes.set(222, 'Abandoned, KYC')
minorErrorCodes.set(223, 'Abandoned, 3DS')
minorErrorCodes.set(224, 'Abandoned, settlement')
