import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { path } from 'ramda'
import { getPSPMIDs } from '../../api'
import { useAuth } from '../../auth'

const PSPMIDFilter = ({ filter, setFilter }) => {
  // Quick simple version to filter based on one PSP
  const { token, email } = useAuth()
  const [loading, setLoading] = useState(false)
  const [failed, setFailed] = useState(false)
  const [psps, setPsps] = useState()
  const [pspMIDName, setPspMIDName] = useState(path(['psp_mid_id'], filter))

  const changed = (e) => {
    const newPSP = e.target.value
    setFilter({ ...filter, psp_mid_id: newPSP ? [newPSP] : [null] })
    setPspMIDName(e.target.value)
  }

  const fetchPspMIDs = async () => {
    try {
      let result = await getPSPMIDs(token, email, filter.psp_any)
      result = result.filter((x) => x.active && !x.test)

      setPsps(result.sort((a, b) => a.pspCredentialId.localeCompare(b.pspCredentialId)))
      setFailed(false)
    } catch (failed) {
      setFailed(true)
    }
    setLoading(false)
  }

  useEffect(() => {
    ;(async () => {
      if (filter.psp_any && filter.psp_any.length > 0) {
        fetchPspMIDs()
      }
    })()
  }, [filter.psp_any])

  return (
    <div className='filter status'>
      <select
        value={pspMIDName || ''}
        onChange={changed}
        className='psp-filter-select'
        disabled={filter.psp_any.length == 0}
      >
        <option value=''>Any</option>
        {!loading &&
          !failed &&
          psps &&
          psps.map((s) => (
            <option key={s.pspCredentialId} value={s.pspCredentialId}>
              {s.pspCredentialLabel}
            </option>
          ))}
      </select>
    </div>
  )
}

PSPMIDFilter.propTypes = {
  filter: PropTypes.object.isRequired,
  setFilter: PropTypes.func.isRequired,
}

export default PSPMIDFilter
