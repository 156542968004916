import { useState, useRef, useEffect } from 'react'
import PropTypes from 'prop-types'

const MultiSelect = ({ options, selectedOptions, onChange }) => {
  const [isOpen, setIsOpen] = useState(false)
  const [searchTerm, setSearchTerm] = useState('')
  const dropdownRef = useRef(null)
  const inputRef = useRef(null)

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false)
      }
    }
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  const handleOptionClick = (option) => {
    const updatedOptions = selectedOptions.includes(option.value)
      ? selectedOptions.filter((item) => item !== option.value)
      : [...selectedOptions, option.value]
    onChange(updatedOptions)
  }

  const handleRemoveOption = (option) => {
    const updatedOptions = selectedOptions.filter((item) => item !== option)
    onChange(updatedOptions)
  }

  const handleSearch = (event) => {
    setSearchTerm(event.target.value)
  }

  const handleFocus = () => {
    setIsOpen(true)
  }

  const filteredOptions = options.filter((option) =>
    option.name.toLowerCase().includes(searchTerm.toLowerCase())
  )

  return (
    <div className='multi-select' ref={dropdownRef}>
      <div className='multi-select-chips'>
        {selectedOptions.map((option) => {
          const selectedOption = options.find((opt) => opt.value === option)
          return (
            <div key={option} className='multi-select-chip'>
              {selectedOption.value}
              <button onClick={() => handleRemoveOption(option)}>x</button>
            </div>
          )
        })}
      </div>
      <div className='multi-select-input' onClick={() => setIsOpen(true)}>
        <input
          ref={inputRef}
          type='text'
          placeholder='Search or select...'
          value={searchTerm}
          onChange={handleSearch}
          onFocus={handleFocus}
          className='multi-select-search'
        />
      </div>
      {isOpen && (
        <ul className='multi-select-options'>
          {filteredOptions.map((option) => (
            <li key={option.value} className='multi-select-option'>
              <label>
                <input
                  type='checkbox'
                  checked={selectedOptions.includes(option.value)}
                  onChange={() => handleOptionClick(option)}
                />
                {option.name} ({option.value})
              </label>
            </li>
          ))}
        </ul>
      )}
    </div>
  )
}

MultiSelect.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    })
  ).isRequired,
  selectedOptions: PropTypes.arrayOf(PropTypes.string).isRequired,
  onChange: PropTypes.func.isRequired,
}

export default MultiSelect
