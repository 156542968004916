import { useState } from 'react'
import { useDebouncedCallback } from 'use-debounce'
import { assocPath } from 'ramda'

const GenericDropdownFilter = ({ filter, filterName, setFilter, options }) => {
  const [filterValue, setFilterValue] = useState(filter[filterName] ? filter[filterName] : '')

  const updateParentFilter = () => {
    setFilter(assocPath([filterName], filterValue, filter))
  }

  const updateParentFilterDebounced = useDebouncedCallback(updateParentFilter, 0)

  const changed = (e) => {
    setFilterValue(e.target.value)
    updateParentFilterDebounced.callback()
  }

  return (
    <div className='filter card'>
      <div className='filter'>
        <select value={filterValue.toString() || ''} onChange={changed} className='full-width'>
          {Object.keys(options).map((key) => (
            <option value={options[key]} key={key}>
              {key}
            </option>
          ))}
        </select>
      </div>
    </div>
  )
}

export default GenericDropdownFilter
