import { useState } from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import {
  pathOr,
  values,
  propOr,
  prop,
  sortBy,
  compose,
  toLower,
  includes,
  propEq,
  filter as rFilter,
} from 'ramda'

/**
 * MerchantFilter lets the user pick one or more merchants.
 */
const MerchantFilter = ({ filter, setFilter, orgsByID = {} }) => {
  const [includeBrands, setIncludeBrands] = useState(false)

  const availableMerchants =
    sortBy(
      compose(toLower, propOr(prop('id'), 'name')),
      compose(rFilter(propEq('type', 'merchant')), values)(orgsByID)
    ) || []
  const availableContracts =
    sortBy(
      compose(toLower, propOr(prop('id'), 'name')),
      compose(rFilter(propEq('type', 'contract')), values)(orgsByID)
    ) || []
  const availableOrganisations =
    sortBy(
      compose(toLower, propOr(prop('id'), 'name')),
      compose(rFilter(propEq('type', 'organisation')), values)(orgsByID)
    ) || []
  let selected = filter.merchant_any || []
  let nonMerchantSelected = false
  if (filter.contract_id) {
    selected = [filter.contract_id]
    nonMerchantSelected = true
  }
  if (filter.organisation_id) {
    selected = [filter.organisation_id]
    nonMerchantSelected = true
  }

  // The sequence of merchant IDs driving the UI (one per line)
  const uiseq = selected && selected.length > 0 ? selected : ['']

  const onChangeAtIndex = (index) => (e) => {
    if (e.target.value == '') {
      setFilter({ ...filter, contract_id: null, organisation_id: null, merchant_any: [] })
    } else if (availableContracts?.find((x) => x.id == e.target.value)) {
      setFilter({ ...filter, contract_id: e.target.value, organisation_id: null, merchant_any: [] })
    } else if (availableOrganisations?.find((x) => x.id == e.target.value)) {
      setFilter({ ...filter, organisation_id: e.target.value, contract_id: null, merchant_any: [] })
    } else {
      const newSel = [...filter.merchant_any]
      newSel[index] = e.target.value
      setFilter({ ...filter, contract_id: null, organisation_id: null, merchant_any: newSel })
    }
    resetIncludeBrands()
  }

  const onRemoveAtIndex = (index) => () => {
    setFilter({
      ...filter,
      merchant_any: [
        ...filter.merchant_any.slice(0, index),
        ...filter.merchant_any.slice(index + 1),
      ],
    })
    resetIncludeBrands()
  }

  const onAddOption = () => {
    setFilter({ ...filter, merchant_any: [...filter.merchant_any, ''] })
    resetIncludeBrands()
  }

  const handleIncludeBrandsChange = (e) => {
    const isChecked = e.target.checked
    setIncludeBrands(isChecked)

    if (isChecked) {
      const selectedMerchantIds = filter.merchant_any.slice(0, 1)
      const brandIds = availableMerchants
        .filter((merchant) => selectedMerchantIds.includes(merchant.id) && merchant.children)
        .flatMap((merchant) => merchant.children.map((child) => child.id))
      setFilter({ ...filter, merchant_any: [...selectedMerchantIds, ...brandIds] })
    } else {
      const brandIds = availableMerchants.flatMap((merchant) =>
        merchant.children ? merchant.children.map((child) => child.id) : []
      )
      setFilter({
        ...filter,
        merchant_any: filter.merchant_any.filter((id) => !brandIds.includes(id)),
      })
    }
  }

  const hasChildren = (id) => {
    const merchant = availableMerchants.find((m) => m.id === id)
    return merchant ? merchant.children && merchant.children.length > 0 : false
  }

  const resetIncludeBrands = () => {
    setIncludeBrands(false)
  }

  return (
    <div className='filter merchants'>
      {uiseq.map((sel, i) => {
        // If this is the last item, produce a set of available options that
        // excludes any selected options already. Note: Expected for selected options
        // to always be small, hence inefficient list traversal.
        const availableGivenSelected = [...availableMerchants].filter(
          ({ id }) => id == sel || !includes(id, selected)
        )
        return (
          <div
            className={classnames({ 'merchant-option': true, unchosen: !sel && uiseq.length > 1 })}
            key={sel}
          >
            {i > 0 && <span className='or'>or</span>}
            {/* Only the last option is editable */}
            {i == uiseq.length - 1 ? (
              <select
                value={sel}
                onChange={onChangeAtIndex(i)}
                disabled={availableMerchants.length <= 1 || includeBrands}
              >
                {selected.length <= 1 && <option value=''>All Transactions</option>}
                {availableOrganisations.length > 1 && (
                  <optgroup label='Organisations'>
                    {availableOrganisations?.map((m) => (
                      <option value={m.id} key={m.id}>
                        {m.name}
                      </option>
                    ))}
                  </optgroup>
                )}
                {availableContracts.length > 1 && (
                  <optgroup label='Contracts'>
                    {availableContracts?.map((m) => (
                      <option value={m.id} key={m.id}>
                        {m.name}
                      </option>
                    ))}
                  </optgroup>
                )}
                {availableMerchants.length > 0 && (
                  <optgroup label='Merchants'>
                    {availableGivenSelected.map((m) => (
                      <option value={m.id} key={m.id}>
                        {m.name}
                      </option>
                    ))}
                  </optgroup>
                )}
              </select>
            ) : (
              <span className='selected'>{pathOr(sel, [sel, 'name'], orgsByID)}</span>
            )}
            {selected.length > 1 && !includeBrands && (
              <button
                className='remove'
                onClick={onRemoveAtIndex(i)}
                title='Remove this merchant from selection'
              >
                <i className='fas fa-times' />
              </button>
            )}
            {i === uiseq.length - 1 && !nonMerchantSelected && sel && !includeBrands && (
              <button
                className='add'
                onClick={onAddOption}
                title='Add another merchant to your selection'
              >
                <i className='fas fa-plus' />
              </button>
            )}
          </div>
        )
      })}
      {((uiseq.length > 0 && hasChildren(uiseq[uiseq.length - 1])) || includeBrands) && (
        <div className='include-brands'>
          <label>
            <input type='checkbox' checked={includeBrands} onChange={handleIncludeBrandsChange} />
            Include brands
          </label>
        </div>
      )}
    </div>
  )
}

MerchantFilter.propTypes = {
  filter: PropTypes.object.isRequired,
  setFilter: PropTypes.func.isRequired,
  orgsByID: PropTypes.object.isRequired,
}

export default MerchantFilter
