import Header from '.././Header'
import Helmet from 'react-helmet'
import { useEffect, useState, useCallback } from 'react'
import { useHistory, Link } from 'react-router-dom'
import { useAuth } from '../auth'
import DateTime from '../DateTime'
import AmountWithCurrency from '../AmountWithCurrency'
import DownloadCSVLink from './DownloadCSVLink'
import { isOperator } from '../auth-roles'
import { DateTime as DT } from 'luxon'
import { parseRefundFilter, updateSearchParamsWithFilters } from '../txns/filters'
import TimeFilter from '../filters/TimeFilter'
import PSPFilter from '../txns/filters/PSPFilter'
import Footer from '../Footer'

import { getRefundedTransactions } from '../api'

const RefundList = () => {
  const history = useHistory()
  const inputDebounceTime = 2000
  const urlParams = new URLSearchParams(location.search)
  const filter = parseRefundFilter(urlParams)
  updateSearchParamsWithFilters(urlParams, filter)

  const { token, roles } = useAuth()
  const isUserOperator = isOperator(roles)
  const [entriesList, setEntriesList] = useState({})
  const [loading, setLoading] = useState(true)
  const [statusFilter, setStatusFilter] = useState(filter.status || '')
  const [txnIdInput, setTxnIdInput] = useState(filter.txn_id || '')
  const [refundIdInput, setPspTxnIdInput] = useState(filter.psp_txn_id || '')

  const setFilter = useCallback(
    (newFilter = {}) => {
      const updatedUrlParams = updateSearchParamsWithFilters(urlParams, newFilter)
      history.replace({ pathname: location.pathname, search: updatedUrlParams })
    },
    [history, location.pathname, urlParams]
  )

  const dt = DT.local()
  const timezone = dt.toFormat('ZZZZ')

  const fetchRefunds = async () => {
    setEntriesList({})
    setLoading(true)

    try {
      const queryResults = await getRefundedTransactions(token, { filter })
      setEntriesList(queryResults)
      setLoading(false)
    } catch (e) {
      setLoading(false)
    }
  }

  const handleLoadMore = () => {
    const lastID =
      entriesList && entriesList.results && entriesList.results.length
        ? entriesList.results[entriesList.results.length - 1].refund_id
        : undefined
    const page = { after: lastID, count: loadableCount }
    showMore(page)
  }

  const showMore = async (page) => {
    setLoading(true)
    try {
      const addTxns = await getRefundedTransactions(token, { filter: filter, page })
      const newTxns = {
        ...entriesList,
        results: [...entriesList.results, ...addTxns.results],
      }
      setEntriesList(newTxns)
    } catch (failed) {
      // setFailed(failed)
    }
    setLoading(false)
  }

  const handleTxnIdChange = (e) => setTxnIdInput(e.target.value)
  const handleRefundIdChange = (e) => setPspTxnIdInput(e.target.value)
  const handleStatusChange = (e) => setStatusFilter(e.target.value)

  const loadableCount = Math.min(
    100,
    entriesList && entriesList.results ? entriesList.total_count - entriesList.results.length : 100
  )

  const reset = () => {
    setFilter({
      from: 'm24h',
      to: 'now',
      txn_id: null,
      psp_txn_id: null,
      psp_id: [],
      refund_id: null,
      status: null,
    })
    setTxnIdInput('')
    setPspTxnIdInput('')
    setStatusFilter('')
  }

  useEffect(() => {
    setFilter({ ...filter, status: statusFilter })
  }, [statusFilter])

  useEffect(() => {
    const debounceTimer = setTimeout(() => {
      setFilter({ ...filter, txn_id: txnIdInput })
    }, inputDebounceTime)

    return () => clearTimeout(debounceTimer)
  }, [txnIdInput])

  useEffect(() => {
    const debounceTimer = setTimeout(() => {
      setFilter({ ...filter, refund_id: refundIdInput })
    }, inputDebounceTime)

    return () => clearTimeout(debounceTimer)
  }, [refundIdInput])

  useEffect(() => {
    fetchRefunds()
  }, [location.search])

  return (
    <section className='refunds'>
      <Header />
      <Helmet>
        <title>Refunds</title>
      </Helmet>
      <div className='content'>
        {!entriesList.results && <p className='loading'>Loading...</p>}
        {entriesList.results && (
          <>
            <header className='controls'>
              <span className='summary'>
                {entriesList && (
                  <span className='summary'>
                    Showing{' '}
                    <strong className='count page_count'>{entriesList?.results?.length}</strong>{' '}
                    {entriesList && entriesList?.results?.length !== entriesList.total_count && (
                      <span>
                        of
                        <strong className='count total_count'>
                          {entriesList?.total_count?.toLocaleString()}
                        </strong>
                      </span>
                    )}
                    {entriesList?.loading && <span className='loading'>updating...</span>}
                  </span>
                )}
              </span>
              <button className='remove' title='Clear Filters' onClick={reset} disabled={false}>
                <i className='fas fa-undo-alt' />
                {' Clear Filters'}
              </button>
            </header>
            <table className='txns'>
              <thead>
                <tr className='table-filter-row'>
                  <th className='time'>
                    <h6>Time ({timezone})</h6>
                    <TimeFilter filter={filter} setFilter={setFilter} />
                  </th>
                  <th>
                    <h6>Refund ID</h6>
                    <div className='filter'>
                      <input
                        type='text'
                        value={refundIdInput}
                        onChange={handleRefundIdChange}
                        placeholder='Refund ID'
                      />
                    </div>
                  </th>
                  <th>
                    <h6>Transaction ID</h6>
                    <div className='filter'>
                      <input
                        type='text'
                        value={txnIdInput}
                        onChange={handleTxnIdChange}
                        placeholder='Transaction ID'
                      />
                    </div>
                  </th>
                  <th>
                    <h6>PSP</h6>
                    <PSPFilter filter={filter} setFilter={setFilter} field={'psp_id'} />
                  </th>
                  <th>
                    <h6>Status</h6>
                    <div className='filter status'>
                      <select value={statusFilter} onChange={handleStatusChange}>
                        <option value=''>All</option>
                        <option value='failed'>Failed</option>
                        <option value='success'>Success</option>
                        <option value='pending'>Pending</option>
                      </select>
                    </div>
                  </th>
                  <th>
                    <h6>Amount</h6>
                  </th>
                  <th>
                    <h6>By</h6>
                  </th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {entriesList.results.map((refund, index) => (
                  <tr className='txn' key={index}>
                    <td className='time clickable'>
                      <DateTime at={refund.created_at} />
                    </td>
                    <td className='refund-id'>
                      {refund.refund_id || '-'}
                      <br />
                      <span>PSP ID: </span>
                      {refund.psp_refund_id || '-'}
                    </td>
                    <td className='refund-txn-id'>
                      {refund.transaction_id || '-'}
                      <br />
                      <span>PSP Txn ID:</span> {refund.psp_transaction_id || '-'}
                    </td>
                    <td>
                      <span>PSP:</span> {refund.psp_id || '-'}
                      <br />
                      <span>MID:</span> {refund.psp_credential_id || '-'}
                    </td>
                    <td className={`refund-txn-${refund.status}`}>
                      {['failed', 'success'].includes(refund.status) && (
                        <>
                          {refund.type == 'chargeback' && (
                            <i
                              className='fas fa-comment-dollar refund-txn-chargeback'
                              title={refund.type}
                            ></i>
                          )}
                          {refund.type == 'refund' && (
                            <i
                              className='fas fa-credit-card refund-txn-refund'
                              title={refund.type}
                            ></i>
                          )}{' '}
                        </>
                      )}
                      {refund.status}
                    </td>
                    <td>
                      <AmountWithCurrency
                        amount={refund.amount.amount}
                        currency={refund.amount.currency}
                      />
                    </td>
                    <td>
                      {isUserOperator && refund.requested_by != '' && (
                        <Link to={`/user/${refund.requested_by}`}>
                          <i
                            className='fas fa-user-alt'
                            title={refund.requested_by_displayname}
                          ></i>
                        </Link>
                      )}
                      {!isUserOperator && refund.requested_by != '' && (
                        <i className='fas fa-user-alt' title={refund.requested_by_displayname}></i>
                      )}
                      {refund.requested_by == '' && (
                        <i className='fas fa-user-alt' title='operator'></i>
                      )}
                    </td>
                    <td>
                      <Link to={`/transaction/${refund.transaction_id}`}>Detail</Link>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            {loadableCount > 0 && entriesList?.results?.length > 0 && (
              <footer className='actions'>
                <>
                  <button onClick={() => handleLoadMore()} disabled={loading}>
                    {loading ? 'Loading...' : `Show ${loadableCount} more`}
                  </button>
                  &nbsp;&nbsp;
                </>
                <DownloadCSVLink displayCount={entriesList?.total_count} filter={filter} />
              </footer>
            )}
          </>
        )}
        {entriesList?.results && entriesList?.results.length == 0 && (
          <div className='no-results'>There are no refunds to show</div>
        )}
        <Footer />
      </div>
    </section>
  )
}

export default RefundList
