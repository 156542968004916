import { useState } from 'react'
import Header from '../Header'
import Helmet from 'react-helmet'
import { useParams, useHistory, Link } from 'react-router-dom'
import { getTransaction, updateTransactionStatus } from '../api'
import { useAuth } from '../auth'
import { isController, isOperator } from '../auth-roles'
//import { isOperator, hasContextContractID } from '../auth-roles'
import { useInterval } from '../timing/use-interval'
import TxnStatus from './TxnStatus'
import AmountWithCurrency from '../AmountWithCurrency'

const LIVE_UPDATE_INTERVAL = 30000 // ms

/** EditTxnStatus allows a controller or operator to change the status of a transaction, such as
 * capturing a refund. Links back to ViewTxn upon success or cancellation. */
const EditTxnStatus = () => {
  const { token, roles } = useAuth()
  const isUserOperator = isOperator(roles)
  const isUserController = isController(roles)
  const params = useParams()
  const history = useHistory()

  // Form: Change status
  const [changeStatus, setChangeStatus] = useState({
    req: { status: '', transaction_id: params.id },
    res: null,
    saving: false,
    failed: null,
  })

  // Transaction data
  const [loading, setLoading] = useState(false)
  const [failed, setFailed] = useState()
  const [txn, setTxn] = useState()
  // Operator can set any status
  const availStatus = ['ok', 'failed']

  const fetchTxn = async () => {
    if (!params.id) {
      return
    }
    setLoading(true)
    setFailed(false)
    try {
      const result = await getTransaction(token, params.id)
      setTxn(result)
      // Pre-set status in form
      if (!changeStatus.req.status) {
        setChangeStatus({ ...changeStatus, req: { ...changeStatus.req, status: result.status } })
      }
    } catch (failed) {
      setFailed(failed)
    }
    setLoading(false)
  }

  useInterval(fetchTxn, LIVE_UPDATE_INTERVAL, true)

  // Status change in local request
  const handleChange = (e) => {
    setChangeStatus({ ...changeStatus, req: { ...changeStatus.req, status: e.target.value } })
  }

  // Submit button
  const handleSubmit = async () => {
    try {
      setChangeStatus({ ...changeStatus, saving: true, failed: null })
      const res = await updateTransactionStatus(token, changeStatus.req)
      setChangeStatus({ ...changeStatus, saving: false, res })
      // Auto-navigate to transactoin detail view
      history.push(`/transaction/${changeStatus.req?.transaction_id}`)
    } catch (failed) {
      setChangeStatus({ ...changeStatus, saving: false, failed })
    }
  }

  if (!isUserOperator && !isUserController) {
    return (
      <section className='transaction edit-status'>
        <Header />
        <Helmet>
          <title>Transaction {txn ? txn.transaction_id : ''}</title>
        </Helmet>
        <div className='content'>
          <p className='error'>You are not authorized to change transaction status.</p>
        </div>
      </section>
    )
  }

  return (
    <section className='transaction edit-status'>
      <Header />
      <Helmet>
        <title>Transaction {txn ? txn.transaction_id : ''}</title>
      </Helmet>
      <div className='content'>
        {loading && !txn && <p className='loading'>Loading...</p>}
        {failed && (
          <p className='error'>
            Something went wrong - please try again, or <Link to='/support'>contact support</Link>.
          </p>
        )}
        {!failed && txn && (
          <>
            <div className='txn-status-change'>
              <h1>
                <i className='fas fa-database'></i> Change transaction status
              </h1>
              <p>
                Change the status of transaction{' '}
                <strong className='txn-id'>{txn.transaction_id}</strong> from customer{' '}
                <strong className='cardholder-email'>
                  {txn.sale_request?.card_holder?.contact?.email || '(unknown)'}
                </strong>{' '}
                to:
              </p>
              <div className='statuses'>
                {availStatus.map((status) => (
                  <div key={status}>
                    <label>
                      <input
                        type='radio'
                        value={status}
                        checked={status == changeStatus.req.status}
                        onChange={handleChange}
                      />
                      <TxnStatus status={status} />
                      {status === 'refunded' && (
                        <span>
                          {' - '}
                          <AmountWithCurrency {...txn.total_requested} />
                        </span>
                      )}
                    </label>
                  </div>
                ))}
              </div>
              <p className='note'>
                Note: This action simply captures a new status for reporting purposes, and will{' '}
                <em>not</em> contact the upstream PSP. <br /> It will, however, attempt to notify
                the downstream merchant via the originally-provided callback URL.
              </p>

              {changeStatus.failed && (
                <p className='error'>
                  That didn&apos; work - please try again or{' '}
                  <Link to='/support'>contact support</Link>.
                </p>
              )}
            </div>
            <footer className='actions'>
              <Link to={`/transaction/${txn.transaction_id}`}>
                <button className='cancel'>Cancel</button>
              </Link>
              <button
                onClick={handleSubmit}
                disabled={changeStatus.req.status === txn.status || changeStatus.saving}
              >
                {changeStatus.saving ? 'Saving...' : 'Save new status'}
              </button>
            </footer>
          </>
        )}
      </div>
    </section>
  )
}

export default EditTxnStatus
