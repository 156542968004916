import PropTypes from 'prop-types'
import { minorErrorCodes } from '../error-codes'

const MinorCodeFilter = ({ filter, setFilter }) => {
  const changed = (e) => {
    const newStatus = e.target.value
    setFilter({ ...filter, error_code_minor: newStatus })
  }
  const errorCodes = Array.from(minorErrorCodes.keys()).sort((a, b) => a - b) // Numeric sort

  const getMinorCodeDetails = (failure_code) => {
    const detail = minorErrorCodes.get(failure_code)
    return detail
  }

  return (
    <div className='filter status'>
      <select value={filter.error_code_minor || ''} onChange={changed}>
        <option value=''>Any minor code</option>
        <optgroup label='Minor Codes'>
          {errorCodes.map((code) => (
            <option key={code} value={code}>
              {`failed (${code} - ${getMinorCodeDetails(code) || '(no description)'})`}
            </option>
          ))}
        </optgroup>
      </select>
    </div>
  )
}

MinorCodeFilter.propTypes = {
  filter: PropTypes.object.isRequired,
  setFilter: PropTypes.func.isRequired,
}

export default MinorCodeFilter
