import { createContext, useContext } from 'react'
import { Helmet } from 'react-helmet'

export const ThemeContext = createContext()

export const ThemeProvider = ({ children }) => {
  const theme = resolveTheme()

  return (
    <>
      <Head theme={theme}></Head>
      <ThemeContext.Provider value={theme}>{children}</ThemeContext.Provider>
    </>
  )
}

/**
 * @returns {{
 *  id: string,
 *  className: string,
 *  name: string,
 *  supportEmail: string,
 *  apiDocsUrl: string
 * }}
 */
export const useTheme = () => {
  return useContext(ThemeContext)
}

export const themeConfigs = {
  pagora: {
    id: 'pagora',
    className: 'pagora',
    name: 'Pagora',
    supportEmail: 'support@pagora.eu',
    apiDocsUrl: 'https://docs.pagora.eu/',
    showRequestUserAccount: true,
    graphColours: {
      ok: '#009245',
      failed: '#C1272D',
      abandoned: '#006683',
      pending: '#333333',
    },
  },
  luminarpay: {
    id: 'luminarpay',
    className: 'luminarpay',
    name: 'Luminarpay',
    supportEmail: 'support@luminarpay.com',
    apiDocsUrl: 'https://docs.luminarpay.com/',
    showRequestUserAccount: false,
    graphColours: {
      ok: '#048752',
      failed: '#ed1c24',
      abandoned: '#006683',
      pending: '#333333',
    },
  },
  leikur: {
    id: 'leikur',
    className: 'leikur',
    name: 'Leikur',
    supportEmail: 'support@leikur.com',
    apiDocsUrl: 'https://docs.leikur.com',
    showRequestUserAccount: true,
    graphColours: {
      ok: '#7BB65E',
      failed: '#CA5045',
      abandoned: '#006683',
      pending: '#777777',
    },
  },
  cleanpay: {
    id: 'cleanpay',
    className: 'cleanpay',
    name: 'CleanPay',
    supportEmail: 'support@cleanpay.eco',
    apiDocsUrl: 'https://docs.cleanpay.eco',
    showRequestUserAccount: true,
    graphColours: {
      ok: '#7BB65E',
      failed: '#CA5045',
      abandoned: '#006683',
      pending: '#777777',
    },
  },
  cosmo: {
    id: 'cosmo',
    className: 'cosmo',
    name: 'Instanto',
    supportEmail: 'support@instanto.se',
    apiDocsUrl: 'https://docs.instanto.se',
    showRequestUserAccount: true,
    graphColours: {
      ok: '#7BB65E',
      failed: '#CA5045',
      abandoned: '#006683',
      pending: '#777777',
    },
  },
}

// resolveTheme determines the correct theme from the
export const resolveTheme = () => {
  if (window.location.hostname.includes('pagora')) {
    return themeConfigs['pagora']
  }
  if (window.location.hostname.includes('leikur')) {
    return themeConfigs['leikur']
  }
  if (window.location.hostname.includes('luminarpay')) {
    return themeConfigs['luminarpay']
  }
  if (window.location.hostname.includes('instanto')) {
    return themeConfigs['cosmo']
  }
  return themeConfigs['cleanpay']

  // Correct default - switch to this once we can move users off cosmo.tech as their back-office domain
  // return themeConfigs['cosmo']
}

const Head = ({ theme }) => {
  switch (theme.id) {
    case 'pagora':
      return (
        <Helmet>
          <title>{theme.name}</title>
          <link rel='icon' type='image/x-icon' href='/styles/themes/pagora/pagora-favicon.svg' />
          <link rel='stylesheet' href='/styles/themes/pagora/style.pagora.css' />
        </Helmet>
      )
    case 'cleanpay':
      return (
        <Helmet>
          <title>{theme.name}</title>
          <link rel='icon' type='image/x-icon' href='/styles/themes/cleanpay/cleanpay-logo.svg' />
          <link rel='stylesheet' href='/styles/themes/cleanpay/style.cleanpay.css' />
        </Helmet>
      )
    case 'leikur':
      return (
        <Helmet>
          <title>{theme.name}</title>
          <link rel='icon' type='image/x-icon' href='/styles/themes/leikur/leikur-fav.svg' />
          <link rel='stylesheet' href='/styles/themes/leikur/style.leikur.css' />
        </Helmet>
      )
    case 'luminarpay':
      return (
        <Helmet>
          <title>{theme.name}</title>
          <link
            rel='icon'
            type='image/x-icon'
            href='/styles/themes/luminarpay/luminarpay-favicon.svg'
          />
          <link rel='stylesheet' href='/styles/themes/luminarpay/style.luminarpay.css' />
        </Helmet>
      )
    default:
    case 'cosmo':
      return (
        <Helmet>
          <title>{theme.name}</title>
          <link rel='icon' type='image/x-icon' href='/styles/themes/cosmo/cosmo-logo.svg' />
          <link rel='stylesheet' href='/styles/themes/cosmo/style.cosmo.css' />
        </Helmet>
      )
  }
}
