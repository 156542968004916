import { useState } from 'react'
import PropTypes from 'prop-types'

const GroupMerchantFilter = ({ merchants, filter, setFilter }) => {
  // Track updates in local state for a smooth typing experience.
  const [id, setID] = useState(filter['merchant_id'])

  const parentIDs = merchants
    .filter((merchant) => merchant?.part_of_id)
    .map((merchant) => merchant.part_of_id)
  const parents = merchants.filter((merchant) => parentIDs.includes(merchant.id))

  // Called when the value changes
  const changed = (e) => {
    const id = e.target.value
    const newFilter = { ...filter, ['merchant_id']: id }
    setID(id)
    setFilter(newFilter)
  }

  return (
    <div className='filter merchant_id'>
      <select value={id || ''} onChange={changed}>
        <option value=''>All</option>
        {parents.map((merchant) => (
          <option key={merchant.id} value={merchant.id}>
            {merchant.trading_as}
          </option>
        ))}
      </select>
    </div>
  )
}
GroupMerchantFilter.propTypes = {
  merchants: PropTypes.array.isRequired,
  filter: PropTypes.object.isRequired,
  setFilter: PropTypes.func.isRequired,
}

export default GroupMerchantFilter
