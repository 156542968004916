const TxnEmail = ({ contact }) => {
  return (
    <div className='email-wrapper'>
      {contact && contact.email && (
        <span className='truncate-text' title={contact.email}>
          {contact.email}
        </span>
      )}
    </div>
  )
}

export default TxnEmail
