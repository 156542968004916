import Header from '../Header'
import Helmet from 'react-helmet'
import { useEffect, useState } from 'react'
import { useAuth } from '../auth'
import {
  getListOfMerchantCredentials,
  addMerchantCredentials,
  getAllMerchants,
  getContracts,
} from '../api'
import PropTypes from 'prop-types'
import { useOrgScope } from '../org-scope'

const MerchantCredentials = () => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [modalMode, setModalMode] = useState('add')
  const [merchantCredentials, setMerchantCredentials] = useState()
  const [selectedCredential, setSelectedCredential] = useState(null)
  const [merchants, setMerchants] = useState([])
  const [contracts, setContracts] = useState([])
  const { token } = useAuth()
  const { byID } = useOrgScope()

  const toggleModal = (credential = null) => {
    if (credential) {
      setModalMode('update')
      setSelectedCredential(credential)
    } else {
      setModalMode('add')
      setSelectedCredential(null)
    }
    setIsModalOpen(!isModalOpen)
  }

  const refreshCredentials = async () => {
    try {
      await getMerchantCredentials()
      setIsModalOpen(false)
    } catch (error) {
      console.error('Error refreshing credentials:', error)
    }
  }

  const getMerchantCredentials = async () => {
    try {
      const res = await getListOfMerchantCredentials(token)

      let sortedCreds = res.credentials.sort(function (cA, cB) {
        let keyA = (byID[cA.owner_id]?.name ?? '-') + cA.psp_id
        let keyB = (byID[cB.owner_id]?.name ?? '-') + cB.psp_id
        return keyA < keyB ? -1 : keyA > keyB ? 1 : 0
      })
      setMerchantCredentials(sortedCreds)
    } catch (error) {
      console.error('Error fetching merchant credentials:', error)
    }
  }

  const getMerchants = async () => {
    const result = await getAllMerchants(token)
    if (result?.results) {
      setMerchants(result.results)
    }
  }

  const getAllContracts = async () => {
    const result = await getContracts(token)
    if (result) {
      setContracts(result)
    }
  }

  const contractDescriptionLookup = (contractId) => {
    const contract = contracts.find((x) => x.id === contractId)
    return contract?.description
  }

  useEffect(() => {
    getMerchants()
    getAllContracts()
    getMerchantCredentials()
  }, [])

  return (
    <section>
      <Header />
      <Helmet>
        <title>Merchant Credentials</title>
      </Helmet>
      <div className='merchant-credential-container'>
        <header className='controls'>
          <button onClick={() => toggleModal()}>Add Merchant Credentials</button>
        </header>
        <table cellSpacing={0} cellPadding={0} className='merchant-credential-table'>
          <thead>
            <tr>
              <th>Owner</th>
              <th>PSP</th>
              <th>Created At</th>
              <th>Contract</th>
              <th>Active</th>
              <th>Test</th>
            </tr>
          </thead>
          <tbody>
            {merchantCredentials?.map((x) => {
              return (
                <tr key={`${x.owner_id}-${x.psp_id}-${x.credential.is_test}`}>
                  <td>{byID[x.owner_id]?.name ?? '-'}</td>
                  <td>{x.psp_id}</td>
                  <td>{x.credential.created_at}</td>
                  <td>{contractDescriptionLookup(x.credential.contract_id)}</td>
                  <td>
                    <input type='checkbox' disabled checked={x.credential.is_active}></input>
                  </td>
                  <td>
                    <input type='checkbox' disabled checked={x.credential.is_test}></input>
                  </td>
                  <td className='actions'>
                    <p onClick={() => toggleModal(x)}>Detail</p>
                  </td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
      {isModalOpen && (
        <Modal
          key={`modal-${selectedCredential?.owner_id}-${selectedCredential?.psp_id}-${selectedCredential?.credential.is_test}`}
          mode={modalMode}
          credential={selectedCredential}
          onClose={toggleModal}
          refreshCredentials={refreshCredentials}
          token={token}
          merchants={merchants}
          contracts={contracts}
        />
      )}
    </section>
  )
}

const Modal = ({ mode, credential, onClose, refreshCredentials, token, merchants, contracts }) => {
  //This will probably be replace with endpoint to get the psp names and keys
  const pspOptions = [
    { pspName: '', keys: [] },
    {
      pspName: 'quickbit-pay',
      keys: [
        { name: 'merchant-id', type: 'string' },
        { name: 'api-key', type: 'string' },
        {
          name: 'use-wallet',
          type: 'select',
          options: [
            { label: 'Yes', value: 'true' },
            { label: 'No', value: 'false' },
          ],
        },
      ],
    },
  ]

  const initializeFormData = () => {
    return {
      action: mode,
      credential_details: credential
        ? {
            owner_id: credential.owner_id,
            psp_id: credential.psp_id,
            credential: {
              contract_id: credential.credential.contract_id,
              is_test: credential.credential.is_test,
              is_active: credential.credential.is_active,
              data: credential.credential.data,
            },
          }
        : {
            owner_id: '',
            psp_id: '',
            credential: {
              contract_id: '',
              is_test: false,
              is_active: true,
              data: {},
            },
          },
    }
  }

  const [formData, setFormData] = useState(initializeFormData)
  const [errorMessage, setErrorMessage] = useState('')

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target
    if (name === 'owner_id') {
      const selectedMerchant = merchants.find((merchant) => merchant.id === value)
      setFormData((prevFormData) => ({
        ...prevFormData,
        credential_details: {
          ...prevFormData.credential_details,
          owner_id: value,
          credential: {
            ...prevFormData.credential_details.credential,
            contract_id: selectedMerchant ? selectedMerchant.contract_id : '',
          },
        },
      }))
    } else {
      setFormData((prevFormData) => {
        if (name in prevFormData.credential_details.credential) {
          return {
            ...prevFormData,
            credential_details: {
              ...prevFormData.credential_details,
              credential: {
                ...prevFormData.credential_details.credential,
                [name]: type === 'checkbox' ? checked : value,
              },
            },
          }
        } else {
          return {
            ...prevFormData,
            credential_details: {
              ...prevFormData.credential_details,
              [name]: value,
            },
          }
        }
      })
    }
  }

  const handleDataChange = (key, newValue) => {
    setFormData((prevFormData) => {
      const updatedData = {
        ...prevFormData.credential_details.credential.data,
        [key]: newValue,
      }

      return {
        ...prevFormData,
        credential_details: {
          ...prevFormData.credential_details,
          credential: {
            ...prevFormData.credential_details.credential,
            data: updatedData,
          },
        },
      }
    })
  }

  const handlePSPChange = (e) => {
    const selectedPSP = e.target.value
    const selectedPSPConfig = pspOptions.find((psp) => psp.pspName === selectedPSP)

    if (selectedPSPConfig) {
      const selectedKeys = selectedPSPConfig.keys || []
      const defaultData = selectedKeys.reduce((acc, key) => {
        if (key.type === 'select') {
          const optionsValues = Object.values(key.options)
          acc[key.name] = optionsValues.length > 0 ? optionsValues[0].value : ''
        } else {
          acc[key.name] = ''
        }
        return acc
      }, {})

      setFormData((prevFormData) => ({
        ...prevFormData,
        credential_details: {
          ...prevFormData.credential_details,
          psp_id: selectedPSP,
          credential: {
            ...prevFormData.credential_details.credential,
            data: {
              ...defaultData,
              ...prevFormData.credential_details.credential.data,
            },
          },
        },
      }))
    }
  }

  const isSubmitEnabled = () => {
    return (
      formData.credential_details.owner_id &&
      formData.credential_details.credential.contract_id &&
      formData.credential_details.psp_id
    )
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    try {
      const results = await addMerchantCredentials(token, formData)
      if (results?.message) {
        setErrorMessage(results.message)
      } else {
        refreshCredentials()
      }
    } catch (error) {
      console.error('Error submitting credentials:', error)
      setErrorMessage(error)
    }
  }

  useEffect(() => {
    setErrorMessage('')
  }, [formData])

  return (
    <div className='modal-overlay'>
      <div className='modal-content'>
        <span className='close' onClick={onClose}>
          &times;
        </span>
        <h2>Add Merchant Credentials</h2>
        <form onSubmit={handleSubmit}>
          <div className='merchant-credentials-input-container'>
            <label htmlFor='owner_id'>Merchant</label>
            <select
              type='text'
              name='owner_id'
              disabled={mode === 'update'}
              value={formData.credential_details.owner_id}
              onChange={handleChange}
              placeholder='Merchant'
            >
              <option value=''>Select Merchant</option>
              {merchants.map((merchant) => (
                <option key={merchant?.id} value={merchant.id}>
                  {merchant?.registered_name}
                </option>
              ))}
            </select>
          </div>
          <div className='merchant-credentials-input-container'>
            <label htmlFor='psp_id'>PSP</label>
            <select
              name='psp_id'
              value={formData.credential_details.psp_id}
              onChange={handlePSPChange}
              disabled={mode === 'update'}
            >
              {pspOptions.map((psp) => (
                <option key={psp.pspName} value={psp.pspName}>
                  {psp?.pspName}
                </option>
              ))}
            </select>
          </div>
          <div className='merchant-credentials-input-container'>
            <label htmlFor='contract_id'>Contract</label>
            <select
              type='text'
              name='contract_id'
              value={formData.credential_details.credential.contract_id}
              onChange={handleChange}
              disabled={formData.credential_details.owner_id === ''}
              placeholder='Contract ID'
            >
              <option value=''></option>
              {contracts.map((contract) => {
                return (
                  <option key={contract.id} value={contract.id}>
                    {contract?.description}
                  </option>
                )
              })}
            </select>
          </div>
          <div className='merchant-credentials-checkbox-container'>
            <div>
              <label htmlFor='is_active'>Is Active</label>
              <input
                type='checkbox'
                name='is_active'
                checked={formData.credential_details.credential.is_active}
                onChange={handleChange}
              />
            </div>
            <div>
              <label htmlFor='is_test'>Is Test</label>
              <input
                type='checkbox'
                name='is_test'
                disabled={mode === 'update'}
                checked={formData.credential_details.credential.is_test}
                onChange={handleChange}
              />
            </div>
          </div>
          {formData.credential_details.psp_id && (
            <div>
              <h3>Data</h3>
              {pspOptions
                .find((option) => option.pspName === formData.credential_details.psp_id)
                ?.keys.map((keyObj, index) => {
                  if (keyObj.type === 'select') {
                    return (
                      <div key={`${keyObj.name}`} className='merchant-credentials-input-container'>
                        <label htmlFor={`key-${index}-${keyObj.name}`}>{keyObj.name}</label>
                        <select
                          id={`key-${index}`}
                          name={keyObj.name}
                          value={formData.credential_details.credential.data[keyObj.name]}
                          onChange={(e) => handleDataChange(keyObj.name, e.target.value)}
                        >
                          {keyObj.options.map((option) => (
                            <option key={option.value} value={option.value}>
                              {option.label}
                            </option>
                          ))}
                        </select>
                      </div>
                    )
                  } else {
                    return (
                      <div key={`${keyObj.name}`} className='merchant-credentials-input-container'>
                        <label htmlFor={`key-${index}`}>{keyObj.name}</label>
                        <input
                          type='text'
                          id={`key-${index}`}
                          name={keyObj.name}
                          value={formData.credential_details.credential.data[keyObj.name]}
                          onChange={(e) => handleDataChange(keyObj.name, e.target.value)}
                          placeholder={keyObj.name}
                        />
                      </div>
                    )
                  }
                })}
            </div>
          )}
          <h4>{errorMessage}</h4>
          <div className='merchant-credential-submit'>
            <button type='submit' disabled={!isSubmitEnabled()}>
              {mode.charAt(0).toUpperCase() + mode.slice(1)} Credentials
            </button>
          </div>
        </form>
      </div>
    </div>
  )
}

Modal.propTypes = {
  mode: PropTypes.string.isRequired,
  credential: PropTypes.shape({
    owner_id: PropTypes.string,
    psp_id: PropTypes.string,
    credential: PropTypes.shape({
      contract_id: PropTypes.string,
      is_test: PropTypes.bool,
      is_active: PropTypes.bool,
      data: PropTypes.object,
    }),
  }),
  onClose: PropTypes.func.isRequired,
  refreshCredentials: PropTypes.func.isRequired,
  token: PropTypes.string.isRequired,
  merchants: PropTypes.array,
  contracts: PropTypes.array,
}

export default MerchantCredentials
