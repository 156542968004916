import { useRef, useState } from 'react'
import { Rule } from './interfaces'
import { useAutosize, validateTerms } from './utils'

type UpdateRulesFunc = (rules: Rule[]) => void
type UpdateRuleFunc = (rule: Rule) => void
type MoveRuleFunc = (rule: Rule, move: 'up' | 'down' | 'top' | 'bottom') => void
type AddRuleFunc = (rule: Rule, where: 'before' | 'after') => void
type DeleteRuleFunc = (rule: Rule) => void

export const GruleEditor = ({
  rules,
  updateRules,
}: {
  rules: Rule[]
  updateRules: UpdateRulesFunc
}) => {
  const updateRule: UpdateRuleFunc = (rule: Rule) => {
    updateRules(rules.map((x) => (x.salience == rule.salience ? rule : x)))
  }

  const moveRule: MoveRuleFunc = (rule: Rule, move: 'up' | 'down' | 'top' | 'bottom') => {
    let updated: Rule[]
    switch (move) {
      case 'up': {
        const currentIndex = rules.findIndex((x) => x == rule)
        if (currentIndex == 0) {
          return // already at top, cannot move
        }
        updated = [
          ...rules.slice(0, currentIndex - 1),
          rule,
          rules[currentIndex - 1],
          ...rules.slice(currentIndex + 1),
        ]
        break
      }
      case 'top': {
        updated = [rule, ...rules.filter((x) => x != rule)]
        break
      }
      case 'down': {
        const currentIndex = rules.findIndex((x) => x == rule)
        if (currentIndex == rules.length - 1) {
          return // already at bottom, cannot move
        }
        updated = [
          ...rules.slice(0, currentIndex),
          rules[currentIndex + 1],
          rule,
          ...rules.slice(currentIndex + 2),
        ]
        break
      }
      case 'bottom': {
        updated = [rule, ...rules.filter((x) => x != rule)]
        break
      }
    }

    let salience = updated.length * 1000
    for (let update of updated) {
      update.salience = salience
      salience -= 1000
    }

    updateRules(updated)
  }

  const newRule = () => {
    const rule: Rule = {
      title: 'New rule',
      description: '',
      when: '',
      then: [''],
      salience: 0,
      id: rules.map((x) => x.id).reduce((a, b) => Math.max(a, b), 0) + 1,
    }
    return rule
  }

  const addRule: AddRuleFunc = (rule: Rule, where: 'before' | 'after') => {
    let updated: Rule[]
    const currentIndex = rules.findIndex((x) => x == rule)
    switch (where) {
      case 'after': {
        updated = [...rules.slice(0, currentIndex + 1), newRule(), ...rules.slice(currentIndex + 1)]
        break
      }
      case 'before': {
        updated = [...rules.slice(0, currentIndex), newRule(), ...rules.slice(currentIndex)]
        break
      }
    }

    let salience = updated.length * 1000
    for (let update of updated) {
      update.salience = salience
      salience -= 1000
    }

    updateRules(updated)
  }

  const deleteRule: DeleteRuleFunc = (rule: Rule) => {
    updateRules(rules.filter((x) => x != rule))
  }

  return (
    <>
      {(rules ?? []).map((x, i) => (
        <RuleEditor
          key={x.id}
          rule={x}
          updateRule={updateRule}
          moveRule={moveRule}
          addRule={addRule}
          deleteRule={deleteRule}
        />
      ))}
    </>
  )
}

const RuleEditor = ({
  rule,
  updateRule,
  moveRule,
  addRule,
  deleteRule,
}: {
  rule: Rule
  updateRule: UpdateRuleFunc
  moveRule: MoveRuleFunc
  addRule: AddRuleFunc
  deleteRule: DeleteRuleFunc
}) => {
  const [deleting, setDeleting] = useState(false)
  const whenTextAreaRef = useRef<HTMLTextAreaElement>(null)
  useAutosize(whenTextAreaRef, rule.when, 4)
  const [unexpectedTerm, setUnexpectedTerm] = useState<string>()

  const checkRule = () => {
    const text = [rule.when, ...rule.then].join('\r\n')
    const unexpectedTerm = validateTerms(text)
    setUnexpectedTerm(unexpectedTerm)
  }

  const deleteRuleConfirm = () => {
    if (deleting) {
      deleteRule(rule)
    } else {
      setDeleting(true)
      setTimeout(() => setDeleting(false), 3000)
    }
  }

  return (
    <table className='grule-rule-table'>
      <tbody>
        <tr>
          <td className='grule-rule-label'>
            <label>Title</label>
          </td>
          <td colSpan={1}>
            <input
              type='text'
              pattern='^[a-zA-Z0-9_\-]+$'
              required
              value={rule.title}
              placeholder='Rule title, e.g. VISA_Routing'
              onChange={(e) => updateRule({ ...rule, title: e.target.value })}
            />
          </td>
          <td className='grule-rule-title-button'>
            <button
              className={deleting ? 'small-button cancel' : 'small-button'}
              onClick={() => deleteRuleConfirm()}
            >
              <i className='fas fa-trash'></i>
            </button>
            <button className='small-button' onClick={() => moveRule(rule, 'down')}>
              <i className='fas fa-arrow-down'></i>
            </button>
            <button className='small-button' onClick={() => moveRule(rule, 'up')}>
              <i className='fas fa-arrow-up'></i>
            </button>
          </td>
        </tr>
        <tr>
          <td className='grule-rule-label'>
            <label>Description</label>
          </td>
          <td colSpan={2}>
            <input
              type='text'
              value={rule.description ?? ''}
              placeholder='Rule description, e.g. Route VISA transactions to MID 1234567890'
              onChange={(e) => updateRule({ ...rule, description: e.target.value })}
            />
          </td>
        </tr>
        <tr>
          <td className='grule-rule-label'>
            <label>When</label>
          </td>
          <td colSpan={2}>
            <textarea
              className='rule-code'
              ref={whenTextAreaRef}
              value={rule.when}
              spellCheck={false}
              onBlur={checkRule}
              onChange={(e) => updateRule({ ...rule, when: e.target.value })}
            ></textarea>
          </td>
        </tr>
        {rule.then.map((x, i) => (
          <tr key={i}>
            {i == 0 ? (
              <td rowSpan={rule.then.length} className='grule-rule-label'>
                <label>Then</label>
              </td>
            ) : null}
            <td>
              <input
                className='rule-code'
                type='text'
                value={x}
                onBlur={checkRule}
                onChange={(e) =>
                  updateRule({
                    ...rule,
                    then: rule.then.map((y, yi) => (yi == i ? e.target.value : y)),
                  })
                }
              />
            </td>
            <td className='grule-rule-then-button'>
              {rule.then[i].trim() == '' && rule.then.length > 1 ? (
                <button
                  className='small-button cancel'
                  onClick={() => {
                    updateRule({
                      ...rule,
                      then: rule.then.filter((x, xi) => xi != i),
                    })
                  }}
                >
                  <i className='fas fas fa-times-circle'></i>
                </button>
              ) : null}
              {rule.then[i].trim() != '' && i == rule.then.length - 1 ? (
                <button
                  className='small-button'
                  onClick={() => {
                    updateRule({ ...rule, then: [...rule.then, ''] })
                  }}
                >
                  <i className='fas fa-plus-circle'></i>
                </button>
              ) : null}
            </td>
          </tr>
        ))}
        {unexpectedTerm ? (
          <tr>
            <td></td>
            <td>
              <span className='warning'>{unexpectedTerm} was not a recognised term.</span>
            </td>
            <td></td>
          </tr>
        ) : null}
        <tr>
          <td colSpan={2} align='right'>
            <a
              href='#'
              onClick={(e) => {
                addRule(rule, 'after')
                e.preventDefault()
                return false
              }}
            >
              <em>add new rule below</em>
            </a>
          </td>
          <td></td>
        </tr>
      </tbody>
    </table>
  )
}
