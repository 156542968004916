import { useState, useEffect } from 'react'
import Header from '.././Header'
import Helmet from 'react-helmet'
import { Link, useLocation, useHistory, useParams } from 'react-router-dom'
import { useAuth } from '../auth'
import { getKycStatusByEmail, getTransactionStats, getKycOwners, shareKYCWithOwner } from '../api'
import DateTime from '../DateTime'
import LoadingSpinner from '../loading-spinner/loading-spinner'
import { updateSearchParamsWithFilters, parseTransactionFilters } from '../txns/filters'
import StatusCount from './StatusCount'
import TimeFilter from '../filters/TimeFilter'
import AcceptanceRate from './AcceptanceRate'
import ToastMessage from '../components/ToastMessage'

const ViewKyc = () => {
  const params = useParams()
  const { email } = useAuth()
  const [loading, setLoading] = useState(false)
  const [failed, setFailed] = useState(false)
  const [dashboardLoading, setDashboardLoading] = useState(false)
  const [dashboardFailed, setDashboardFailed] = useState(false)
  const [kycDetail, setKycDetail] = useState()
  const [txns, setTxns] = useState()
  const location = useLocation()
  const history = useHistory()
  const urlParams = new URLSearchParams(location.search)
  const filter = parseTransactionFilters(urlParams)
  const [ownerOrgs, setOwnerOrgs] = useState([])
  const [toastMessages, setToastMessages] = useState([])
  const [chosenOrg, setChosenOrg] = useState('')

  useEffect(() => {
    const fetchData = async () => {
      await fetchKycDetails()
    }
    fetchData()
  }, [params.owner, params.email])

  useEffect(() => {
    if (kycDetail?.email) {
      fetchTxns()
    }
  }, [kycDetail])

  useEffect(async () => {
    if (ownerOrgs.length == 0) {
      let data = await getKycOwners(token)
      data = data
        .toSorted((a, b) => a.name.localeCompare(b.name))
        .filter((x) => x.type == 'organisation')

      let orgs = {}

      orgs[''] = '-'
      data.forEach((org) => {
        orgs[org.name] = org.id
      })

      setOwnerOrgs(orgs)
    }
  }, [ownerOrgs])

  const { token } = useAuth()

  const orgSelected = (selectedOrgID) => {
    setChosenOrg(selectedOrgID.target.value)
  }

  const fetchKycDetails = async () => {
    if (!params.owner || !params.email) {
      return
    }
    setLoading(true)
    setFailed(false)
    try {
      const result = await getKycStatusByEmail(token, params.owner, params.email)
      setKycDetail(result)
    } catch (failed) {
      setFailed(failed)
    }
    setLoading(false)
  }

  const fetchTxns = async () => {
    setDashboardLoading(true)
    setDashboardFailed(false)
    try {
      filter.email = kycDetail.email
      const result = await getTransactionStats(token, { email, filter })
      setTxns(result)
    } catch (failed) {
      setDashboardFailed(failed)
    }
    setDashboardLoading(false)
  }

  const shareKYC = async () => {
    // Make sure something was selected
    if (chosenOrg.length <= 1) {
      return
    }

    try {
      const result = await shareKYCWithOwner(token, params.owner, params.email, chosenOrg)

      if (result.status == 200) {
        setToastMessages([
          {
            title: 'Success',
            description: 'Verification Shared Successfully',
          },
        ])
      }
    } catch (failed) {
      setFailed(failed)
    }
    setLoading(false)
  }

  const setFilter = (filter = {}) => {
    const updUrlParams = updateSearchParamsWithFilters(urlParams, filter)
    history.replace({ pathname: location.pathname, search: updUrlParams })
  }

  const questions =
    kycDetail?.answered_question_profiles && kycDetail?.answered_question_profiles.length > 0
      ? kycDetail.answered_question_profiles[0]
      : null

  const identity =
    kycDetail?.identity_verifications && kycDetail?.identity_verifications.length > 0
      ? kycDetail.identity_verifications[kycDetail.identity_verifications.length - 1]
      : null

  const screening =
    kycDetail?.screenings && kycDetail?.screenings.length > 0
      ? kycDetail.screenings[kycDetail.screenings.length - 1]
      : null

  return (
    <section>
      <Header />
      <Helmet>
        <title>Know Your Customer</title>
      </Helmet>
      <div className='view-kyc-wrapper'>
        {failed && (
          <div className='kyc-spinner-wrapper'>
            <LoadingSpinner color='red'></LoadingSpinner>
            <div className='kyc-loading-text'>Could not load KYC information</div>
          </div>
        )}
        {loading && (
          <div className='kyc-spinner-wrapper'>
            <LoadingSpinner></LoadingSpinner>
          </div>
        )}
        {!failed && !loading && kycDetail && (
          <div className='kyc-view-wrapper'>
            <div className='kyc-view-customer'>
              <h1>
                <i className='fas fa-user-shield view-kyc-header-icon'></i> Customer
              </h1>
              <div className='view-kyc-row'>
                <div className='view-kyc-key'>Source</div>
                <div>{kycDetail.initiator ? kycDetail.initiator : '-'}</div>
              </div>
              <div className='view-kyc-row'>
                <div className='view-kyc-key'>Email</div>
                <div className='view-kyc-val'>
                  {kycDetail.email ? (
                    <Link to={`/transactions?email=${kycDetail.email}`}>{kycDetail.email}</Link>
                  ) : (
                    '-'
                  )}
                </div>
                <i className='fas fa-search kyc-search-icon' />
              </div>
              <div className='view-kyc-row'>
                <div className='view-kyc-key'>Email is verified</div>
                <div>
                  {/* email verification not yet implemented or returned in API */}
                  {kycDetail.email_is_verified ? (
                    <i className='kyc-check fas fa-check'></i>
                  ) : (
                    <i className='kyc-cross fas fa-times'></i>
                  )}
                </div>
              </div>
              <div className='view-kyc-row'>
                <div className='view-kyc-key'>KYC Last updated at</div>
                <div>{kycDetail.updated_at ? <DateTime at={kycDetail.updated_at} /> : '-'}</div>
              </div>
              <div className='view-kyc-row'>
                <div className='view-kyc-key'>Initial transaction ID</div>
                <div className='text-id'>
                  {kycDetail.init_transaction_id ? (
                    <Link className='kyc-link' to={`/transaction/${kycDetail.init_transaction_id}`}>
                      {kycDetail.init_transaction_id}
                    </Link>
                  ) : (
                    '-'
                  )}
                </div>
              </div>
              {kycDetail.email_sent_count && (
                <div className='view-kyc-row'>
                  <div className='view-kyc-key'>Number of emails sent</div>
                  <div>{kycDetail.email_sent_count || '-'}</div>
                </div>
              )}
            </div>
            <div className='kyc-title-wrapper'>
              <h1>
                <i className='fas fa-chart-line view-kyc-header-icon'></i> Transaction history
              </h1>
              <TimeFilter filter={filter} setFilter={setFilter}></TimeFilter>
              <div className='view-kyc-dashboard-wrapper'>
                {dashboardFailed && (
                  <div className='kyc-spinner-wrapper'>
                    <LoadingSpinner color='red'></LoadingSpinner>
                    <div className='kyc-loading-text'>Could not load KYC Dashboard</div>
                  </div>
                )}

                {dashboardLoading && (
                  <div className='kyc-spinner-wrapper'>
                    <LoadingSpinner></LoadingSpinner>
                  </div>
                )}

                {!dashboardFailed && !dashboardLoading && txns?.stats && (
                  <>
                    <div className='ac-wrapper'>
                      <AcceptanceRate {...txns?.stats} />
                    </div>

                    <div className='view-kyc-divider'></div>
                    <div className='ac-wrapper'>
                      <StatusCount {...txns?.stats} />
                    </div>
                  </>
                )}

                {!dashboardFailed && !dashboardLoading && !txns?.stats && (
                  <span>There are no transactions falling within the given search parameter</span>
                )}
              </div>
            </div>
            {questions && (
              <div className='kyc-view-questions'>
                <h1>
                  <i className='fas fa-question-circle view-kyc-header-icon'></i> KYC Questions
                </h1>
                <div className='view-kyc-row'>
                  <div className='view-kyc-key'>National ID number</div>
                  <div className={questions.national_id ? 'text-id' : ''}>
                    {questions.answers.national_id || '-'}
                  </div>
                </div>
                <div className='view-kyc-row'>
                  <div className='view-kyc-key'>Country residence</div>
                  <div> {questions.answers.country_residence || '-'}</div>
                </div>
                <div className='view-kyc-row'>
                  <div className='view-kyc-key'>Countries citizenship</div>
                  <div>{questions.answers.countries_citizenship?.toString() || '-'}</div>
                </div>
                <div className='view-kyc-row'>
                  <div className='view-kyc-key'> Politically exposed</div>
                  <div>
                    {questions.answers.politically_exposed ? (
                      <div>
                        Yes <i className='kyc-cross fas fa-times'></i>
                      </div>
                    ) : (
                      <div>
                        No <i className='kyc-check fas fa-check'></i>
                      </div>
                    )}
                  </div>
                </div>
                <div className='view-kyc-row'>
                  <div className='view-kyc-key'>Using on behalf</div>
                  <div>
                    {questions.answers.using_on_behalf ? (
                      <div>
                        Yes <i className='kyc-cross fas fa-times'></i>
                      </div>
                    ) : (
                      <div>
                        No <i className='kyc-check fas fa-check'></i>
                      </div>
                    )}
                  </div>
                </div>
                <div className='view-kyc-row'>
                  <div className='view-kyc-key'>Usage reason</div>
                  <div>
                    {questions.answers.usage_reason || '-'}
                    {questions.answers.usage_reason === 'Make payments' ? (
                      <span>
                        {' '}
                        <i className='kyc-check fas fa-check'></i>
                      </span>
                    ) : (
                      ''
                    )}
                  </div>
                </div>
                <div className='view-kyc-row'>
                  <div className='view-kyc-key'>Estimated monthly transaction frequency:</div>
                  <div>{questions.answers.est_monthly_transact_frequency || '-'}</div>
                </div>
                <div className='view-kyc-row'>
                  <div className='view-kyc-key'>Monthly transaction amount</div>
                  <div>{questions.answers.monthly_transaction_amount || '-'}</div>
                </div>
                <div className='view-kyc-row'>
                  <div className='view-kyc-key'>Main source of funds:</div>
                  <div> {questions.answers.main_source_of_funds || '-'}</div>
                </div>
                <div className='view-kyc-row'>
                  <div className='view-kyc-key'>Monthly income:</div>
                  <div> {questions.answers.monthly_income || '-'}</div>
                </div>
              </div>
            )}
            <div className='kyc-view-verification'>
              <h1>
                <i className='fas fa-id-card view-kyc-header-icon'></i> Identity Verification
              </h1>
              <div className='view-kyc-row'>
                <div className='view-kyc-key'>At</div>
                <div> {identity?.at || '-'}</div>
              </div>
              <div className='view-kyc-row'>
                <div className='view-kyc-key'>Source</div>
                <div> {identity?.source || '-'}</div>
              </div>

              {identity && (
                <>
                  {identity.detected?.name && (
                    <>
                      <div className='view-kyc-row'>
                        <div className='view-kyc-key'>First name</div>
                        <div>{identity.detected.name.first || '-'}</div>
                      </div>
                      <div className='view-kyc-row'>
                        <div className='view-kyc-key'>Last name</div>
                        <div>{identity.detected.name.last || '-'}</div>
                      </div>
                    </>
                  )}
                  {identity.detected?.address && (
                    <>
                      <div className='view-kyc-row'>
                        <div className='view-kyc-key'>Address lines</div>
                        <div>{identity.detected.address.line1 || '-'}</div>
                      </div>
                      <div className='view-kyc-row'>
                        <div className='view-kyc-key'>Postal Code</div>
                        <div>{identity.detected.address.postal_code || '-'}</div>
                      </div>
                      <div className='view-kyc-row'>
                        <div className='view-kyc-key'>City</div>
                        <div>{identity.detected.address.city || '-'}</div>
                      </div>
                      <div className='view-kyc-row'>
                        <div className='view-kyc-key'>Country</div>
                        <div>{identity.detected.address.country || '-'}</div>
                      </div>
                    </>
                  )}
                  {identity.detected?.contact && (
                    <>
                      <div className='view-kyc-row'>
                        <div className='view-kyc-key'>Phone</div>
                        <div>{identity.detected.contact.phone || '-'}</div>
                      </div>
                    </>
                  )}
                  <div className='view-kyc-row'>
                    <div className='view-kyc-key'>Date of birth</div>
                    <div>{identity.detected?.date_of_birth || '-'}</div>
                  </div>
                  {identity.detected?.identity_number && (
                    <div className='view-kyc-row'>
                      <div className='view-kyc-key'>Identity number</div>
                      <div>{identity.detected.identity_number || '-'}</div>
                    </div>
                  )}
                  {identity.detected?.identity_number_proxy && (
                    <div className='view-kyc-row'>
                      <div className='view-kyc-key'>Identity number proxy</div>
                      <div>{identity.detected.identity_number_proxy || '-'}</div>
                    </div>
                  )}
                  {identity.detected?.identification_document && (
                    <>
                      <div className='view-kyc-row'>
                        <div className='view-kyc-key'>Identification document type</div>
                        <div>{identity.detected.identification_document.type || '-'}</div>
                      </div>
                      {identity.detected?.identification_document.number && (
                        <div className='view-kyc-row'>
                          <div className='view-kyc-key'>Number / identifier</div>
                          <div>{identity.detected.identification_document.number || '-'}</div>
                        </div>
                      )}
                      {identity.detected?.identification_document.issuingCountry && (
                        <div className='view-kyc-row'>
                          <div className='view-kyc-key'>Issuing country</div>
                          <div>
                            {identity.detected.identification_document.issuingCountry || '-'}
                          </div>
                        </div>
                      )}
                      {identity.detected.identification_document.dateOfIssue && (
                        <div className='view-kyc-row'>
                          <div className='view-kyc-key'>Date of issue</div>
                          <div>{identity.detected.identification_document.dateOfIssue || '-'}</div>
                        </div>
                      )}
                      {identity.detected.identification_document.dateOfExpiry && (
                        <div className='view-kyc-row'>
                          <div className='view-kyc-key'>Date of expiry</div>
                          <div>{identity.detected.identification_document.dateOfExpiry || '-'}</div>
                        </div>
                      )}
                    </>
                  )}
                </>
              )}
              <div className='view-kyc-row'>
                Share KYC verification with &nbsp;
                <select value={chosenOrg} onChange={orgSelected}>
                  {Object.keys(ownerOrgs).map((key) => (
                    <option value={ownerOrgs[key]} key={key}>
                      {key}
                    </option>
                  ))}
                </select>
                &nbsp;
                <button onClick={shareKYC} className='action-button standard-button-link'>
                  Share
                </button>
                <ToastMessage toastList={toastMessages} />
              </div>
            </div>
            <div className='kyc-view-screening'>
              <h1>
                <i className='fas fa-user-shield view-kyc-header-icon'></i> AML/PEP Screening
              </h1>
              <div className='view-kyc-row'>
                <div className='view-kyc-key'>At</div>
                <div> {screening?.at ? screening?.at : '-'}</div>
              </div>
              <div className='view-kyc-row'>
                <div className='view-kyc-key'>Provider</div>
                <div>{screening?.provider ? screening?.provider : '-'}</div>
              </div>

              <div className='view-kyc-row'>
                <div className='view-kyc-key'>Result</div>
                <div> {screening?.result ? screening?.result : '-'}</div>
              </div>
            </div>
          </div>
        )}
      </div>
    </section>
  )
}

export default ViewKyc
