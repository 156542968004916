import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { getOrgByType } from '../org-scope'
import { getMyOrgScopeTree } from '../api'

const UserScopes = ({ token, byID, onScopeSelect }) => {
  const [loading, setLoading] = useState(false)
  const [failed, setFailed] = useState(false)
  const [selected, setSelected] = useState('')
  const [activeUserScope, setActiveUserScope] = useState('')
  const orgsByType = getOrgByType(byID)

  const userRoles = Object.hasOwn(activeUserScope, 'roles') ? activeUserScope.roles : []

  const organisations =
    Object.hasOwn(orgsByType, 'organisation') &&
    !userRoles.find((x) => x.r === 'back-office' && x.t === 'MerchantID')
      ? orgsByType['organisation'].filter((x) => !userRoles.find((y) => y.id === x.id))
      : []
  const contracts =
    Object.hasOwn(orgsByType, 'contract') &&
    !userRoles.find((x) => x.r === 'back-office' && x.t === 'MerchantID')
      ? orgsByType['contract'].filter((x) => !userRoles.find((y) => y.id === x.id))
      : []
  const merchants =
    Object.hasOwn(orgsByType, 'merchant') &&
    !userRoles.find(
      (x) => x.r === 'back-office' && (x.t === 'ContractID' || x.t === 'OrganisationID')
    )
      ? orgsByType['merchant'].filter((x) => !userRoles.find((y) => y.id === x.id))
      : []

  const getOrgTreeScope = async (token) => {
    setLoading(true)
    setFailed(false)
    try {
      const orgTreeScope = await getMyOrgScopeTree(token)
      setActiveUserScope(orgTreeScope)
    } catch (failed) {
      setFailed(failed)
    }
    setLoading(false)
  }

  useEffect(() => {
    getOrgTreeScope(token)
  }, [token])

  const handleSelectChange = (e) => {
    const newValue = e.target.value
    setSelected(newValue)
    onScopeSelect(newValue)
  }

  return (
    <div className='user-add-scope'>
      {!loading && !failed && activeUserScope && (
        <>
          <label className='label-header'>Select User Scope:</label>
          <select value={selected} onChange={handleSelectChange} style={{ width: '100%' }}>
            <option value=''>select a scope</option>
            {organisations.length > 0 ? (
              <optgroup label='Organisations'>
                {organisations.map((x) => (
                  <option value={x.id} key={x.id}>
                    {x.name}
                  </option>
                ))}
              </optgroup>
            ) : null}
            {contracts.length > 0 ? (
              <optgroup label='Contracts'>
                {contracts.map((x) => (
                  <option value={x.id} key={x.id}>
                    {x.name}
                  </option>
                ))}
              </optgroup>
            ) : null}
            {merchants.length > 0 ? (
              <optgroup label='Merchants'>
                {merchants.map((x) => (
                  <option value={x.id} key={x.id}>
                    {x.name}
                  </option>
                ))}
              </optgroup>
            ) : null}
          </select>
        </>
      )}
    </div>
  )
}

UserScopes.propTypes = {
  byID: PropTypes.string.isRequired,
  token: PropTypes.string.isRequired,
  onScopeSelect: PropTypes.func.isRequired,  // Add this prop type
}

export default UserScopes
