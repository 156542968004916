import PropTypes from 'prop-types'

const FtdFilter = ({ filter, setFilter }) => {
  const mode = filter.ftds ?? ''

  const changed = (e) => {
    setFilter({ ...filter, ftds: e.target.value })
  }

  const options = [
    { label: 'Any', value: '' },
    { label: 'Only', value: 'only' },
    { label: 'Exclude', value: 'exclude' },
  ]

  return (
    <div className='filter'>
      <select value={mode || ''} onChange={changed} className='ftds-filter-select'>
        {options.map((s, i) => (
          <option key={`ftds-${i}`} value={s.value}>
            {s.label}
          </option>
        ))}
      </select>
    </div>
  )
}

FtdFilter.propTypes = {
  filter: PropTypes.object.isRequired,
  setFilter: PropTypes.func.isRequired,
}

export default FtdFilter
