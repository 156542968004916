import PropTypes from 'prop-types'
import classnames from 'classnames'
import { DateTime } from 'luxon'
import { getRelativeTime } from '../utils/Formatters'

const MerchantDateTime = ({ at, relativeTime = false }) => {
  const now = DateTime.local()
  const dt = at ? DateTime.fromISO(at) : null
  const isToday = dt.hasSame(now, 'day')
  const isSameHour = isToday && dt.hasSame(now, 'hour')
  return (
    <span className={classnames({ 'date-time': true, today: isToday, 'this-hour': isSameHour })}>
      {relativeTime ? (
        <span className='date'> {getRelativeTime(at)}</span>
      ) : (
        <>
          <span className='date'> {dt.toISODate()}</span>{' '}
          <strong className='time'>{dt.toLocaleString(DateTime.TIME_24_WITH_SHORT_OFFSET)}</strong>
        </>
      )}
    </span>
  )
}

MerchantDateTime.propTypes = {
  at: PropTypes.string.isRequired,
  relativeTime: PropTypes.bool,
}

export default MerchantDateTime
