import { useEffect, useState } from 'react'
import { getOrgByType, useOrgScope } from '../../org-scope'
import { useAuth } from '../../auth'
import { getPSPMIDs } from '../../api'
import LoadingSpinner from '../../loading-spinner/loading-spinner'

interface MID {
  pspCredentialLabel: string
  pspCredentialId: string
  psp: string
  ownerID: string
  active: boolean
  test: boolean
}

export const RouteListHelper = ({}) => {
  const { token, email } = useAuth()
  const { byID } = useOrgScope()

  const [selected, setSelected] = useState<string[]>([])
  const [userMIDs, setUserMIDs] = useState<MID[]>([])

  // Fetch all the MIDs the user has access to from the API.
  // TODO this should probably be retrieved upfront (as with org tree) and cached for some duration.
  useEffect(() => {
    ;(async () => {
      let mids = (await getPSPMIDs(token, email, 'all')) as MID[]
      mids = mids.filter((x) => x.active && !x.test)

      mids.sort((a, b) =>
        a.pspCredentialId.localeCompare(b.pspCredentialId, undefined, { numeric: true })
      )
      setUserMIDs(mids)
    })()
  }, [])

  if (userMIDs.length === 0) {
    return (
      <div className='routing-spinner'>
        <LoadingSpinner />
      </div>
    )
  }

  return (
    <div>
      <h1>Routes</h1>
      <table className='route-list std-table'>
        <thead>
          <tr>
            <th>ID</th>
            <th>Label</th>
            <th>PSP</th>
            <th>Owner</th>
          </tr>
        </thead>
        <tbody>
          {userMIDs.map((x) => (
            <tr
              id={x.pspCredentialId}
              className='std-row'
              onClick={() => {
                // add or remove from selected on checkbox change
                if (selected.includes(x.pspCredentialId)) {
                  setSelected(selected.filter((y) => y !== x.pspCredentialId))
                } else {
                  setSelected([...selected, x.pspCredentialId])
                }
              }}
            >
              <td>
                <input
                  type='checkbox'
                  checked={selected.includes(x.pspCredentialId)}
                  value={x.pspCredentialId}
                  readOnly={true}
                  onChange={(e) => {
                    return false
                  }}
                ></input>{' '}
                {x.pspCredentialId}
              </td>
              <td>{x.pspCredentialLabel}</td>
              <td>{x.psp}</td>
              <td>{byID[x.ownerID]?.name ?? '-'}</td>
            </tr>
          ))}
        </tbody>
      </table>

      <label className='route-list-helper'>
        Route List:{' '}
        <input type='text' readOnly={true} value={selected.map((x) => `"${x}"`).join(', ')}></input>
      </label>
    </div>
  )
}
