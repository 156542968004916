import PropTypes from 'prop-types'
import classnames from 'classnames'

// TODO: This will be replaced by an API call
export const types = {
  email: 'Email (customer)',
  pan: 'Card PAN',
  ip_cust: 'IP (customer)',
  cntry_bin: 'Country (card issue)',
  cntry_ip_cust: 'Country (customer IP)',
  cardid: 'Card ID',
}

/* Given a `type` for an allow/deny entry, renders it visually. */
const Type = ({ type }) => (
  <span className={classnames({ type: true, unknown: !type, [type]: type })}>
    {types[type] || '?'}
  </span>
)

Type.propTypes = {
  type: PropTypes.string,
}

export default Type

export const TypeSelector = ({ value, onChange }) => (
  <select value={value} onChange={(e) => onChange(e.target.value)}>
    <option value=''>Type:</option>
    <option value='email'>Email (customer)</option>
    <option value='pan'>Card PAN</option>
    <option value='cardid'>Card ID</option>
    <option value='ip_cust'>IP (customer)</option>
    <option value='cntry_bin'>Country (card issue)</option>
    <option value='cntry_ip_cust'>Country (customer IP)</option>
  </select>
)
TypeSelector.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
}
