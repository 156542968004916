import PropTypes from 'prop-types'
import { useRef } from 'react'
import { useAuth } from '../auth'
import { getTransactionsCSVURL } from '../api'

/** Renders a widget that, given a `filter` specification, allows the current user to download
 * transactions matching the filter, using the user's auth token. */
const DownloadCSVLink = ({ filter = {}, displayCount }) => {
  const { token } = useAuth()
  const formRef = useRef()
  let csvURL = getTransactionsCSVURL({ filter })
  if (displayCount === 0) {
    return null
  }
  return (
    <span className='download-csv'>
      <form method='POST' action={csvURL} target='_blank' ref={formRef}>
        <input type='hidden' name='auth_token' value={token} />
        <a
          href=''
          title='Download all transactions matching the current filter as CSV'
          onClick={(e) => {
            e.preventDefault()
            if (formRef.current) {
              formRef.current.submit()
            }
            return false
          }}
        >
          Download {displayCount != null && displayCount.toLocaleString()} as CSV
        </a>
      </form>
    </span>
  )
}
DownloadCSVLink.propTypes = {
  filter: PropTypes.object,
  displayCount: PropTypes.number,
}
export default DownloadCSVLink
