import React, { useEffect, useState } from 'react'
import PSPExcelSummaryTable from '../excel-templates//PSPExcelSummaryTable'
import PropTypes from 'prop-types'
import { formatCurrency } from '../../utils/Formatters'

const PSPTransactionSpendTable = ({ data, failed, loading, title }) => {
  const [tableData, setTableData] = useState([])
  const [tableTotals, setTableTotals] = useState()
  const isMobile = window.matchMedia('(max-width: 420px)').matches

  const transformData = () => {
    if (data) {
      let tempArray = []
      const totals = {
        in_progress: 0,
        failed: 0,
        debounce_failed: 0,
        ok: 0,
        abandoned: 0,
        total: 0,
        requested: 0,
        settled: 0,
        ar: 0,
      }
      for (const [key, value] of Object.entries(data)) {
        const parentObject = {
          name: key,
          in_progress: 0,
          failed: 0,
          debounce_failed: 0,
          ok: 0,
          abandoned: 0,
          total: 0,
          requested: 0,
          settled: 0,
          ar: 0,
          children: [],
        }
        for (const [key, childObjectValue] of Object.entries(value)) {
          let childObject = {
            name: key,
            midid: childObjectValue.midid,
            in_progress: childObjectValue.in_progress,
            failed: childObjectValue.failed,
            debounce_failed: childObjectValue.debounce_failed ?? 0,
            ok: childObjectValue.ok,
            abandoned: childObjectValue.abandoned,
            total: childObjectValue.ok + childObjectValue.in_progress + childObjectValue.failed,
            requested: childObjectValue.sum_Requested_Amount_EUR,
            settled: childObjectValue.sum_Settled_Amount_EUR,
            ar:
              childObjectValue.ok + childObjectValue.debounce_failed
                ? childObjectValue.ok / (childObjectValue.ok + childObjectValue.debounce_failed)
                : 0,
          }
          parentObject.children.push(childObject)
          parentObject.in_progress += childObjectValue.in_progress
          parentObject.failed += childObjectValue.failed
          parentObject.debounce_failed += childObjectValue.debounce_failed
          parentObject.ok += childObjectValue.ok
          parentObject.abandoned += childObjectValue.abandoned
          parentObject.total +=
            childObjectValue.in_progress + childObjectValue.failed + childObjectValue.ok
          parentObject.requested += childObjectValue.sum_Requested_Amount_EUR
          parentObject.settled += childObjectValue.sum_Settled_Amount_EUR
          parentObject.ar =
            parentObject.ok + parentObject.debounce_failed
              ? parentObject.ok / (parentObject.ok + parentObject.debounce_failed)
              : 0
        }
        totals.in_progress += parentObject.in_progress
        totals.failed += parentObject.failed
        totals.debounce_failed += parentObject.debounce_failed
        totals.ok += parentObject.ok
        totals.abandoned += parentObject.abandoned
        totals.total += parentObject.total
        totals.requested += parentObject.requested
        totals.settled += parentObject.settled
        totals.ar =
          totals.ok + totals.debounce_failed ? totals.ok / (totals.ok + totals.debounce_failed) : 0
        tempArray.push(parentObject)
      }
      setTableTotals(totals)
      setTableData(tempArray)
    }
  }

  useEffect(() => {
    transformData()
  }, [data])

  return data && Object.keys(data).length && !loading && !failed ? (
    <>
      <div className={'sub-header-table-view-wrapper'}>
        <div className='graph-title-container'>
          {!isMobile && <PSPExcelSummaryTable data={data} />}
          <span className='table-title'>{title}</span>
        </div>
        <table className='psp-transaction-spend-table' cellPadding='0' cellSpacing='0'>
          <thead className='header-row'>
            <tr>
              <th>MID</th>
              {!isMobile && <th>In Progress</th>}
              {!isMobile && <th>Failed</th>}
              {!isMobile && <th>Ok</th>}
              <th>Total</th>
              {!isMobile && <th>Requested (EUR)</th>}
              <th>Settled (EUR)</th>
              <th>AR</th>
            </tr>
          </thead>
          <tbody>
            {tableData.map((s, index) => {
              return (
                <React.Fragment key={index}>
                  <tr className='sub-header-row' key={index + s.settled}>
                    <td className={`table-data-left-alignment`}>{s.name}</td>
                    {!isMobile && (
                      <td className='table-data-number-format'>
                        {formatCurrency(s.in_progress, isMobile)}
                      </td>
                    )}
                    {!isMobile && (
                      <td className='table-data-number-format'>
                        {formatCurrency(s.failed + s.abandoned, isMobile)}
                      </td>
                    )}
                    {!isMobile && (
                      <td className='table-data-number-format'>{formatCurrency(s.ok, isMobile)}</td>
                    )}
                    <td className={`table-data-number-format`}>
                      {formatCurrency(s.total, isMobile)}
                    </td>
                    {!isMobile && (
                      <td className='table-data-number-format'>
                        € {formatCurrency(s.requested, isMobile)}
                      </td>
                    )}
                    <td className={'table-data-number-format'}>
                      € {formatCurrency(s.settled, isMobile)}
                    </td>
                    <td className={`table-data-number-format`}>{(s.ar * 100).toFixed(1)}%</td>
                  </tr>
                  {s.children.map((childItem, index) => {
                    let ar = (childItem.ar * 100).toFixed(1)
                    return (
                      <tr key={index}>
                        <td className='table-data-left-alignment'>
                          {isMobile ? childItem.midid : childItem.name}
                        </td>
                        {!isMobile && (
                          <td className='table-data-number-format'>
                            {formatCurrency(childItem.in_progress, isMobile)}
                          </td>
                        )}
                        {!isMobile && (
                          <td className='table-data-number-format'>
                            {formatCurrency(childItem.failed, isMobile)}
                          </td>
                        )}
                        {!isMobile && (
                          <td className='table-data-number-format'>
                            {formatCurrency(childItem.ok, isMobile)}
                          </td>
                        )}
                        <td className='table-data-number-format'>
                          {formatCurrency(childItem.total, isMobile)}
                        </td>
                        {!isMobile && (
                          <td className='table-data-number-format'>
                            {formatCurrency(childItem.requested, isMobile)}
                          </td>
                        )}
                        <td className='table-data-number-format'>
                          € {formatCurrency(childItem.settled, isMobile)}
                        </td>
                        <td
                          className={`table-data-number-format ${
                            ar < 70 && ar > 49
                              ? 'warning-percentage-threshold'
                              : ar < 49
                              ? 'failed-percentage-threshold'
                              : null
                          }`}
                        >
                          {(childItem.ar * 100).toFixed(isMobile ? 0 : 1)}%
                        </td>
                      </tr>
                    )
                  })}
                </React.Fragment>
              )
            })}
          </tbody>
          <tfoot>
            <tr className='header-row'>
              <th>TOTAL</th>
              {!isMobile && (
                <th className='table-data-number-format'>
                  {formatCurrency(tableTotals?.in_progress, isMobile)}
                </th>
              )}
              {!isMobile && (
                <th className='table-data-number-format'>
                  {formatCurrency(tableTotals?.failed ?? 0 + tableTotals?.abandoned ?? 0, isMobile)}
                </th>
              )}
              {!isMobile && (
                <th className='table-data-number-format'>
                  {formatCurrency(tableTotals?.ok, isMobile)}
                </th>
              )}
              <th className='table-data-number-format'>
                {formatCurrency(tableTotals?.total, isMobile)}
              </th>
              {!isMobile && (
                <th className='table-data-number-format'>
                  € {formatCurrency(tableTotals?.requested)}
                </th>
              )}
              <th className='table-data-number-format'>€ {formatCurrency(tableTotals?.settled)}</th>
              <th className='table-data-number-format'>
                {(tableTotals.ar * 100).toFixed(isMobile ? 0 : 1)}%
              </th>
            </tr>
          </tfoot>
        </table>
      </div>
    </>
  ) : !data?.length && !loading && !failed ? (
    <div className='stacked-bar-chart-wrapper'>
      <span className='table-title'>{title}</span>
      <div className='no-data-dashboard'>
        <h1>NO DATA</h1>
      </div>
    </div>
  ) : null
}

PSPTransactionSpendTable.propTypes = {
  data: PropTypes.object,
  failed: PropTypes.bool,
  loading: PropTypes.bool,
  title: PropTypes.string,
}

export default PSPTransactionSpendTable
