import PropTypes from 'prop-types'
import classnames from 'classnames'
import { pathOr } from 'ramda'
import { useOrgScope } from '../org-scope'
import { Link } from 'react-router-dom'

/**
 * TODO replace with tree from context when its available
 */
const MerchantName = ({ id }) => {
  const { byID } = useOrgScope()
  // if we can find the merchant id, try and return merchant registered name otherwise fallback to showing only id
  const merchantDescription = (id) => {
    if (!id) {
      return '-'
    }

    return pathOr(id, [id, 'name'], byID)
  }

  return (
    <span className={classnames({ merchant: true })}>
      <span className='name'>
        <Link to={`/merchant/${id}`}> {merchantDescription(id)}</Link>
      </span>
    </span>
  )
}

MerchantName.propTypes = {
  id: PropTypes.string,
  merchants: PropTypes.array,
}

export default MerchantName
