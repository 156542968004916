import PropTypes from 'prop-types'
import classnames from 'classnames'

const rendering = {
  a: {
    icon: 'fas fa-check',
    text: 'Allow',
  },
  d: {
    icon: 'fas fa-ban',
    text: 'Deny',
  },
  unknown: {
    icon: 'fas fa-question',
    text: 'Unset',
  },
}

/* Given a `choice` for an allow/deny entry, renders it visually. */
const Choice = ({ choice }) => (
  <span className={classnames({ choice: true, unknown: !choice, [choice]: choice })}>
    {rendering[choice]?.text || rendering.unknown.text}{' '}
    <i className={rendering[choice]?.icon || rendering.unknown.icon} />
  </span>
)

Choice.propTypes = {
  choice: PropTypes.oneOf(['a', 'd']),
}

export default Choice

export const ChoiceSelector = ({ value, onChange }) => (
  <select value={value} onChange={(e) => onChange(e.target.value)}>
    <option value=''>Allow/Deny:</option>
    <option value='a'>Allow</option>
    <option value='deny_fraud'>Deny - Fraud Block</option>
    <option value='deny_compliance'>Deny - Compliance Block</option>
    <option value='deny_chargeback'>Deny - Chargeback Block</option>
  </select>
)
ChoiceSelector.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
}
