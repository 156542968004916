import { useState } from 'react'
import PropTypes from 'prop-types'

const MerchantOrganisationIDFilter = ({ filter, setFilter, organisations }) => {
  // Track updates in local state for a smooth typing experience.
  const [id, setID] = useState(filter['organisation_id'])

  // Called when the value changes
  const changed = (e) => {
    const id = e.target.value
    const newFilter = { ...filter, ['organisation_id']: id }
    setID(id)
    setFilter(newFilter)
  }

  return (
    <div className='filter merchant_id'>
      <select value={id || ''} onChange={changed}>
        <option value=''>All</option>
        {organisations.map((org) => (
          <option key={org.id} value={org.id}>
            {org.name}
          </option>
        ))}
      </select>
      {/* <input
        type='text'
        value={id || ''}
        onKeyPress={keyPressed}
        onChange={changed}
        placeholder={'ID'}
      /> */}
    </div>
  )
}
MerchantOrganisationIDFilter.propTypes = {
  filter: PropTypes.object.isRequired,
  setFilter: PropTypes.func.isRequired,
  textEntryDebounceMs: PropTypes.number,
  organisations: PropTypes.object,
}

export default MerchantOrganisationIDFilter
