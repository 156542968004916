import { useEffect, useState } from 'react'
import Header from '../Header'
import Helmet from 'react-helmet'
import { getKycProfile, uploadKYCIdentity } from '../api'
import { useAuth } from '../auth'
import { countries } from '../utils/countries'
import ToastMessage from '../components/ToastMessage'
import { useParams } from 'react-router-dom'
import KycOwnerSelect from './KycOwnerSelect'
import { getKycOwners } from '../api'

const KYCProfile = () => {
  const initialForm = {
    at: new Date().toISOString(),
    source: '',
    name: {
      last: '',
      first: '',
    },
    date_of_birth: {
      day: '',
      month: '',
      year: '',
    },
    identification_document: {
      type: '',
      number: '',
      issuing_country: '',
      issue_date: {
        day: '',
        month: '',
        year: '',
      },
      expiry_date: {
        day: '',
        month: '',
        year: '',
      },
    },
  }

  const [profileType, setProfileType] = useState('new')
  const [formErrors, setFormErrors] = useState({})
  const [isSubmit, setIsSubmit] = useState(false)
  const [cloneProfileEmail, setCloneProfileEmail] = useState('')
  const [email, setEmail] = useState()
  const [toastMessages, setToastMessages] = useState([])
  const [owners, setOwners] = useState({ owners: [] })
  const [owner, setOwner] = useState('')
  const params = useParams()

  const [userProfileForm, setUserProfileForm] = useState(initialForm)

  const { token } = useAuth()

  const convertDateToComponentFormat = (date) => {
    if (date) {
      const newDate = date.split('-')
      return {
        year: newDate[0],
        month: newDate[1],
        day: newDate[2],
      }
    } else {
      return ''
    }
  }

  const convertDateToAPIFormat = (date) => {
    if (date) {
      return `${date.year}-${date.month}-${date.day}`
    } else {
      return ''
    }
  }

  const setProfile = (type) => {
    clearForm()
    setProfileType(type)
  }

  const clearForm = () => {
    setEmail('')
    setCloneProfileEmail('')
    setFormErrors({})
    setUserProfileForm(initialForm)
  }

  const handleSubmit = async () => {
    const result = await validateForm(userProfileForm)
    if (JSON.stringify(result) === '{}') {
      const formattedBirthDate = convertDateToAPIFormat(userProfileForm?.date_of_birth)
      const formattedIssueDate = convertDateToAPIFormat(
        userProfileForm.identification_document?.issue_date
      )
      const formattedExpiryDate = convertDateToAPIFormat(
        userProfileForm.identification_document?.expiry_date
      )

      let requestObject = Object.assign({}, userProfileForm)
      requestObject.date_of_birth = formattedBirthDate
      requestObject.identification_document.issue_date = formattedIssueDate
      requestObject.identification_document.expiry_date = formattedExpiryDate

      const response = await uploadKYCIdentity(token, owner, email, requestObject)
      //There should probably have a generic toast message which caters for success and failed responses
      if (response.success) {
        setToastMessages([
          {
            title: 'Success',
            description: 'Successfully created KYC profile',
          },
        ])
        setEmail('')
        setUserProfileForm(initialForm)
      } else {
        setToastMessages([
          {
            title: 'Danger',
            description: 'Error creating KYC profile',
          },
        ])
      }
    } else {
      setIsSubmit(true)
      setFormErrors(result)
    }
  }

  const validateDate = (date, type) => {
    const ListofDays = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31]

    if (isNaN(date.year) || isNaN(date.month) || isNaN(date.day)) {
      return false
    }

    if (type == 'date_of_birth') {
      if (date?.year < 1900 || date?.year > new Date().getFullYear()) {
        return false
      }
    }

    if (type == 'issue_date') {
      if (new Date(`${date.year}-${date.month}-${date.day}`) > new Date()) {
        return false
      }
    }

    if (date?.month < 1 || date?.month > 12) {
      return false
    }
    if (date?.day < 0 || date?.day > ListofDays[date?.month - 1]) {
      return false
    }
    return true
  }

  const validateForm = async (values) => {
    const errors = {}
    const emailRegexPattern = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,})+$/

    if (!email) {
      errors.email = 'Email is required'
    }
    if (!emailRegexPattern.test(email)) {
      errors.email = 'Invalid email address'
    }
    if (email == cloneProfileEmail) {
      errors.email = 'Email Address can not be same as clone email'
    }

    if (!values.date_of_birth) {
      errors.dateOfBirth = 'Invalid date of birth format'
    }
    if (!values.name.first) {
      errors.firstName = 'Name is required'
    }
    if (!values.name.last) {
      errors.lastName = 'Surname is required'
    }
    if (!values.source) {
      errors.source = 'Please enter a valid source'
    }
    if (!values.identification_document.number) {
      errors.document_number = 'Please enter a document number'
    }
    if (values.identification_document.type == '') {
      errors.document_type = 'Please select a valid document type'
    }
    if (values.identification_document.issuing_country == '') {
      errors.issuing_country = 'Please select a valid country'
    }
    if (!validateDate(values.date_of_birth, 'date_of_birth')) {
      errors.date_of_birth = 'Please enter a valid date birth date'
    }
    if (!validateDate(values.identification_document.issue_date, 'issue_date')) {
      errors.issue_date = 'Please enter a valid date issue date'
    }
    if (!validateDate(values.identification_document.expiry_date, 'expiry_date')) {
      errors.expiry_date = 'Please enter a valid date expiry date'
    }
    return errors
  }

  const errorMessage = (key) => {
    return (
      <tr>
        <td></td>
        <td className='kyc-error-message'>
          {formErrors?.[key] && <span>{formErrors[key]}</span>}
          {!formErrors?.[key] && <span style={{ visibility: 'hidden' }}>No Error</span>}
        </td>
      </tr>
    )
  }

  const kycProfileInformation = async (value) => {
    if (value) {
      setCloneProfileEmail(value)
      const delayDebounceFn = setTimeout(async () => {
        const kycUserProfile = await getKycProfile(token, value)
        let identityObject = kycUserProfile?.identity_verifications[0]?.detected
        userProfileForm.name.first = identityObject.name.first
        userProfileForm.name.last = identityObject.name?.last
        userProfileForm.date_of_birth = convertDateToComponentFormat(identityObject?.date_of_birth)
        userProfileForm.identification_document.type = identityObject?.identification_document?.type
        userProfileForm.identification_document.issue_date = convertDateToComponentFormat(
          identityObject?.identification_document?.issueDate
        )
        userProfileForm.identification_document.expiry_date = convertDateToComponentFormat(
          identityObject?.identification_document?.dateOfExpiry
        )
        userProfileForm.identification_document.issuing_country =
          identityObject?.identification_document?.issuingCountry
        userProfileForm.identification_document.number =
          identityObject?.identification_document?.number
        userProfileForm.source = kycUserProfile?.identity_verifications[0]?.source
        setEmail(kycUserProfile?.email)
      }, 1000)
      return () => clearTimeout(delayDebounceFn)
    } else {
      clearForm()
    }
  }

  const loadKycOwners = async () => {
    let data = await getKycOwners(token)
    data = data.toSorted((a, b) => a.name.localeCompare(b.name))
    if (data.length > 0) {
      setOwners({ owners: data })
    }
  }

  useEffect(async () => {
    setIsSubmit(false)
    setFormErrors({})
  }, [email, userProfileForm])

  useEffect(async () => {
    loadKycOwners()
  }, [])

  useEffect(() => {
    setOwner(params.owner)
  }, [params?.owner])

  return (
    <section className='list-kyc-section'>
      <Header />
      <Helmet>
        <title>Know Your Customer</title>
      </Helmet>
      <div className='profile-kyc-wrapper'>
        <div className='profile-kyc-header-container'>
          <KycOwnerSelect owner={params?.owner} owners={owners.owners} />
          <div className='kyc-radio-group'>
            <input
              className='radio-select'
              type='radio'
              id='scratch'
              value='new'
              checked={profileType == 'new'}
              onChange={(e) => setProfile(e.target.value)}
              style={{ marginLeft: '0px !important' }}
            />
            <label htmlFor='scratch' className='radio-select-label'>
              From Scratch
            </label>
            <input
              className='radio-select'
              type='radio'
              id='clone'
              value='clone'
              disabled
              checked={profileType == 'clone'}
              onChange={(e) => setProfile(e.target.value)}
            />
            <label htmlFor='clone' className='radio-select-label'>
              Clone Profile
            </label>
            {profileType == 'clone' && (
              <input
                placeholder='Profile email'
                value={cloneProfileEmail}
                onChange={(e) => kycProfileInformation(e.target.value)}
                type='text'
              ></input>
            )}
          </div>
        </div>
        <div className='kyc-content-wrapper'>
          <form>
            <table>
              <tbody>
                <tr>
                  <td>
                    <span>
                      <strong>Email</strong>
                    </span>
                  </td>
                  <td>
                    <input
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      className='kyc-input'
                      type='text'
                      placeholder='Email Address'
                    ></input>
                  </td>
                </tr>
                {errorMessage('email')}
                <tr>
                  <td>
                    <span>
                      <strong>First Name</strong>
                    </span>
                  </td>
                  <td>
                    <input
                      value={userProfileForm.name.first}
                      onChange={(e) =>
                        setUserProfileForm({
                          ...userProfileForm,
                          name: { ...userProfileForm.name, ['first']: e.target.value },
                        })
                      }
                      className='kyc-input'
                      placeholder='First name'
                      type='text'
                      disabled={profileType == 'clone'}
                    ></input>
                  </td>
                </tr>
                {errorMessage('firstName')}
                <tr>
                  <td>
                    <span>
                      <strong>Last Name</strong>
                    </span>
                  </td>
                  <td>
                    <input
                      value={userProfileForm.name.last}
                      onChange={(e) =>
                        setUserProfileForm({
                          ...userProfileForm,
                          name: { ...userProfileForm.name, ['last']: e.target.value },
                        })
                      }
                      className='kyc-input'
                      placeholder='Last name'
                      type='text'
                      disabled={profileType == 'clone'}
                    ></input>
                  </td>
                </tr>
                {errorMessage('lastName')}
                <tr>
                  <td>
                    <span>
                      <strong>Date of Birth</strong>
                    </span>
                  </td>
                  <td>
                    <div>
                      <input
                        className='kyc-input'
                        value={userProfileForm.date_of_birth?.year}
                        onChange={(e) =>
                          setUserProfileForm({
                            ...userProfileForm,
                            date_of_birth: {
                              ...userProfileForm.date_of_birth,
                              ['year']: e.target.value,
                            },
                          })
                        }
                        placeholder='YYYY'
                        type='text'
                        disabled={profileType == 'clone'}
                      />
                      <input
                        className='kyc-input'
                        value={userProfileForm.date_of_birth?.month}
                        onChange={(e) =>
                          setUserProfileForm({
                            ...userProfileForm,
                            date_of_birth: {
                              ...userProfileForm.date_of_birth,
                              ['month']: e.target.value,
                            },
                          })
                        }
                        placeholder='MM'
                        type='text'
                        disabled={profileType == 'clone'}
                      />
                      <input
                        className='kyc-input'
                        value={userProfileForm.date_of_birth?.day}
                        onChange={(e) =>
                          setUserProfileForm({
                            ...userProfileForm,
                            date_of_birth: {
                              ...userProfileForm.date_of_birth,
                              ['day']: e.target.value,
                            },
                          })
                        }
                        placeholder='DD'
                        type='text'
                        disabled={profileType == 'clone'}
                      />
                    </div>
                  </td>
                </tr>
                {errorMessage('date_of_birth')}
                <tr>
                  <td>
                    <span>
                      <strong>Document Type</strong>
                    </span>
                  </td>
                  <td>
                    <select
                      className='kyc-input'
                      onChange={(e) =>
                        setUserProfileForm({
                          ...userProfileForm,
                          identification_document: {
                            ...userProfileForm.identification_document,
                            ['type']: e.target.value,
                          },
                        })
                      }
                      name='type'
                      disabled={profileType == 'clone'}
                      value={userProfileForm?.identification_document?.type}
                      placeholder='Document Type'
                      defaultValue=''
                    >
                      <option value='' hidden></option>
                      <option value='NationalIdentityCard'>National Identity Card</option>
                      <option value='DrivingLicence'>Driving License</option>
                      <option value='Passport'>Passport</option>
                    </select>
                  </td>
                </tr>
                {errorMessage('document_type')}
                <tr>
                  <td>
                    <span>
                      <strong>Issuing Country</strong>
                    </span>
                  </td>
                  <td>
                    <select
                      className='kyc-input'
                      color='white'
                      disabled={profileType == 'clone'}
                      value={userProfileForm.identification_document.issuing_country}
                      onChange={(e) =>
                        setUserProfileForm({
                          ...userProfileForm,
                          identification_document: {
                            ...userProfileForm.identification_document,
                            ['issuing_country']: e.target.value,
                          },
                        })
                      }
                      placeholder='Filter by country'
                      defaultValue=''
                    >
                      <option value='' hidden></option>
                      {countries.map((country) => (
                        <option value={country.alpha3code} key={country.alpha3code}>
                          {country.name}
                        </option>
                      ))}
                    </select>
                  </td>
                </tr>
                {errorMessage('issuing_country')}
                <tr>
                  <td>
                    <span>
                      <strong>Document Number</strong>
                    </span>
                  </td>
                  <td>
                    <input
                      value={userProfileForm.identification_document.number}
                      onChange={(e) =>
                        setUserProfileForm({
                          ...userProfileForm,
                          identification_document: {
                            ...userProfileForm.identification_document,
                            ['number']: e.target.value,
                          },
                        })
                      }
                      className='kyc-input'
                      placeholder='Document number'
                      type='text'
                      disabled={profileType == 'clone'}
                    ></input>
                  </td>
                </tr>
                {errorMessage('document_number')}
                <tr>
                  <td>
                    <span>
                      <strong>Issue Date</strong>
                    </span>
                  </td>
                  <td>
                    <div>
                      <input
                        className='kyc-input'
                        value={userProfileForm.identification_document?.issue_date?.year}
                        onChange={(e) =>
                          setUserProfileForm({
                            ...userProfileForm,
                            identification_document: {
                              ...userProfileForm.identification_document,
                              issue_date: {
                                ...userProfileForm.identification_document.issue_date,
                                ['year']: e.target.value,
                              },
                            },
                          })
                        }
                        placeholder='YYYY'
                        type='text'
                        disabled={profileType == 'clone'}
                      />
                      <input
                        className='kyc-input'
                        value={userProfileForm.identification_document?.issue_date?.month}
                        onChange={(e) =>
                          setUserProfileForm({
                            ...userProfileForm,
                            identification_document: {
                              ...userProfileForm.identification_document,
                              issue_date: {
                                ...userProfileForm.identification_document.issue_date,
                                ['month']: e.target.value,
                              },
                            },
                          })
                        }
                        placeholder='MM'
                        type='text'
                        disabled={profileType == 'clone'}
                      />
                      <input
                        className='kyc-input'
                        value={userProfileForm.identification_document?.issue_date?.day}
                        onChange={(e) =>
                          setUserProfileForm({
                            ...userProfileForm,
                            identification_document: {
                              ...userProfileForm.identification_document,
                              issue_date: {
                                ...userProfileForm.identification_document.issue_date,
                                ['day']: e.target.value,
                              },
                            },
                          })
                        }
                        placeholder='DD'
                        type='text'
                        disabled={profileType == 'clone'}
                      />
                    </div>
                  </td>
                </tr>
                {errorMessage('issue_date')}
                <tr>
                  <td>
                    <span>
                      <strong>Expiry Date</strong>
                    </span>
                  </td>
                  <td>
                    <div>
                      <input
                        className='kyc-input'
                        value={userProfileForm.identification_document?.expiry_date?.year}
                        onChange={(e) =>
                          setUserProfileForm({
                            ...userProfileForm,
                            identification_document: {
                              ...userProfileForm.identification_document,
                              expiry_date: {
                                ...userProfileForm.identification_document.expiry_date,
                                ['year']: e.target.value,
                              },
                            },
                          })
                        }
                        placeholder='YYYY'
                        type='text'
                        disabled={profileType == 'clone'}
                      />
                      <input
                        className='kyc-input'
                        value={userProfileForm.identification_document?.expiry_date?.month}
                        onChange={(e) =>
                          setUserProfileForm({
                            ...userProfileForm,
                            identification_document: {
                              ...userProfileForm.identification_document,
                              expiry_date: {
                                ...userProfileForm.identification_document.expiry_date,
                                ['month']: e.target.value,
                              },
                            },
                          })
                        }
                        placeholder='MM'
                        type='text'
                        disabled={profileType == 'clone'}
                      />
                      <input
                        className='kyc-input'
                        value={userProfileForm.identification_document?.expiry_date?.day}
                        onChange={(e) =>
                          setUserProfileForm({
                            ...userProfileForm,
                            identification_document: {
                              ...userProfileForm.identification_document,
                              expiry_date: {
                                ...userProfileForm.identification_document.expiry_date,
                                ['day']: e.target.value,
                              },
                            },
                          })
                        }
                        placeholder='DD'
                        type='text'
                        disabled={profileType == 'clone'}
                      />
                    </div>
                  </td>
                </tr>
                {errorMessage('expiry_date')}
                <tr>
                  <td>
                    <span>
                      <strong>Source</strong>
                    </span>
                  </td>
                  <td>
                    <input
                      value={userProfileForm.source}
                      onChange={(e) =>
                        setUserProfileForm({ ...userProfileForm, ['source']: e.target.value })
                      }
                      className='kyc-input'
                      placeholder='Source'
                      type='text'
                      disabled={profileType == 'clone'}
                    />
                  </td>
                </tr>
                {errorMessage('source')}
              </tbody>
            </table>
          </form>
          <button className='kyc-profile-button' disabled={isSubmit} onClick={handleSubmit}>
            Submit
          </button>
        </div>
      </div>
      <ToastMessage toastList={toastMessages} />
    </section>
  )
}

export default KYCProfile
