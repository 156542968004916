import { useState, useEffect } from 'react'
import Header from '.././Header'
import Helmet from 'react-helmet'
import { useAuth } from '../auth'
import { getKycList } from '../api'
import { Link, useLocation, useHistory, useParams } from 'react-router-dom'
import { parseKycFilters, updateSearchParamsWithFilters } from '../merchants/filters'
import LoadingSpinner from '../loading-spinner/loading-spinner'
import { isControllerNoMerchant, isOperator } from '../auth-roles'
import './kyc.scss'
import GenericTextFilter from '../filters/GenericTextFilter'
import GenericDropdownFilter from './filters/GenericDropdownFilter'
import TimeFilter from '../filters/TimeFilter'
import DateTime from '../DateTime'
import KycVerifiedEmailFilter from './filters/KycVerifiedEmailFilter'
import DownloadKYCCSVLink from './DownloadCSVLink'
import { path } from 'ramda'
import KycOwnerSelect from './KycOwnerSelect'

const ListKyc = ({ owners }) => {
  const params = useParams()
  const location = useLocation()
  const history = useHistory()

  const urlParams = new URLSearchParams(location.search)
  const filter = parseKycFilters(urlParams)
  updateSearchParamsWithFilters(urlParams, filter)

  const { token, roles } = useAuth()
  const canSeeMerchants = !isControllerNoMerchant(roles)
  const isUserOperator = isOperator(roles)

  const [kycList, setKycList] = useState({ loading: true, failed: false, data: {} })

  const fetchKycList = async () => {
    setKycList({ ...kycList, loading: true, failed: false })
    try {
      console.log(filter)
      const data = await getKycList(token, params.owner, { filter })
      setKycList({ ...kycList, loading: false, failed: false, data })
    } catch (failed) {
      setKycList({ ...kycList, loading: false, failed })
    }
  }

  const kycHeaders = {
    email: 'Email',
    created_at: 'Created At',
    init_transaction_id: 'Transaction ID',
    auth_provider: 'Identity Provider',
    initiator: 'Initiator',
    kyc_questions_answered: 'KYC Answered',
    identity_verification_completed: 'ID Verified',
    screening_result: 'AML/PEP',
  }

  const handleLoadMore = () => {
    const email =
      kycList && kycList.data && kycList.data.results && kycList.data.results.length
        ? kycList.data.results[kycList.data.results.length - 1].email
        : undefined
    const page = { after: email, count: loadableCount }
    fetchMoreKyc(page)
  }

  const fetchMoreKyc = async (page) => {
    setKycList({ ...kycList, loading: true, failed: false })
    try {
      const addKyc = await getKycList(token, params.owner, { filter, page })
      const data = {
        ...kycList.data,
        results: [...kycList.data.results, ...addKyc.results],
      }
      setKycList({ ...kycList, loading: false, failed: false, data })
    } catch (failed) {
      setKycList({ ...kycList, loading: false, failed })
    }
  }

  const getKeyByValue = (object, value) => {
    return Object.keys(object).find((key) => object[key] === value)
  }

  const loadableCount = Math.min(
    100,
    kycList &&
      kycList.data &&
      kycList.data.results &&
      kycList.data.results.length &&
      kycList.data.page &&
      kycList.data.page.total_count
      ? kycList.data.page.total_count - kycList.data.results.length
      : 100
  )

  const setFilter = (filter = {}) => {
    const updUrlParams = updateSearchParamsWithFilters(urlParams, filter)
    history.replace({ pathname: location.pathname, search: updUrlParams })
  }

  useEffect(async () => {
    setKycList({ ...kycList, loading: true, failed: false, data: {} })
    fetchKycList()
  }, [params.owner, urlParams.toString()])

  return (
    <section className='list-kyc-section'>
      <Header />
      <Helmet>
        <title>Know Your Customer</title>
      </Helmet>
      <div className='list-kyc-wrapper'>
        {kycList.failed && !kycList.data.results && (
          <div className='kyc-spinner-wrapper'>
            <LoadingSpinner color='red'></LoadingSpinner>
            <div className='kyc-loading-text'>Could not load KYC information</div>
          </div>
        )}
        {kycList.loading && !kycList.failed && !kycList.data.results && (
          <div className='kyc-spinner-wrapper'>
            <LoadingSpinner></LoadingSpinner>
          </div>
        )}
        <div className='kyc-table'>
          <div>
            <header className='controls'>
              <span className='summary'>
                <KycOwnerSelect owner={params.owner} owners={owners} />
                Showing{' '}
                <strong className='count page_count'>{kycList?.data?.results?.length}</strong>
                {kycList?.data?.page &&
                  kycList?.data?.results?.length !== kycList?.data?.page?.total_count && (
                    <span className='total_count_transaction_header'>
                      of
                      <strong className='count total_count'>
                        {kycList?.data?.page?.total_count}
                      </strong>
                    </span>
                  )}
              </span>
              {isUserOperator ? (
                <>
                  <Link to={`/know-your-customer/profile/${params.owner}`} className='button-link'>
                    <button className='action-button standard-button-link'>Add Identity</button>
                  </Link>
                  <Link
                    to={`/know-your-customer/questions/${params.owner}`}
                    className='button-link'
                  >
                    <button className='action-button standard-button-link'>Add Questions</button>
                  </Link>
                </>
              ) : null}
            </header>
          </div>
          {kycList.data && kycList.data.results && (
            <div className='kyc-header'>
              {Object.values(kycHeaders).map((val) => (
                <div
                  key={val}
                  className={`kyc-item
                ${getKeyByValue(kycHeaders, val).includes('_id') ? 'kyc-wide-item' : ''}
                ${getKeyByValue(kycHeaders, val).includes('email') ? 'kyc-extra-wide-item' : ''}
                `}
                >
                  {getKeyByValue(kycHeaders, val) === 'email' && (
                    <div>
                      {val}
                      <div className='kyc-email-wrapper'>
                        <div className='kyc-email-filter'>
                          <KycVerifiedEmailFilter
                            filter={filter}
                            filterName='email_is_verified'
                            placeholder='Verified ?'
                            setFilter={setFilter}
                          ></KycVerifiedEmailFilter>
                        </div>

                        <GenericTextFilter
                          filter={filter}
                          filterName='email'
                          placeholder='user@host.com'
                          setFilter={setFilter}
                        ></GenericTextFilter>
                      </div>
                    </div>
                  )}

                  {getKeyByValue(kycHeaders, val) === 'created_at' && (
                    <div>
                      {val}

                      <TimeFilter filter={filter} setFilter={setFilter}></TimeFilter>
                    </div>
                  )}
                  {getKeyByValue(kycHeaders, val) === 'init_transaction_id' && (
                    <div>
                      {val}
                      <GenericTextFilter
                        filter={filter}
                        filterName='txn_id'
                        placeholder='ID'
                        setFilter={setFilter}
                      ></GenericTextFilter>
                    </div>
                  )}
                  {getKeyByValue(kycHeaders, val) === 'auth_provider' && (
                    <div>
                      {val}
                      <GenericDropdownFilter
                        filter={filter}
                        filterName='auth_provider'
                        placeholder='Provider'
                        setFilter={setFilter}
                        options={{
                          Any: '',
                          alice: 'alice',
                          dkNemID: 'dkNemID',
                          dkMitID: 'dkMitID',
                          fiTupas: 'fiTupas',
                          nlIDIN: 'nlIDIN',
                          noBankID: 'noBankID',
                          onfido: 'onfido',
                          seBankID: 'seBankID',
                        }}
                      ></GenericDropdownFilter>
                    </div>
                  )}
                  {getKeyByValue(kycHeaders, val) === 'initiator' && (
                    <div>
                      {val}
                      <GenericDropdownFilter
                        filter={filter}
                        filterName='initiator'
                        placeholder='Source'
                        setFilter={setFilter}
                        options={{
                          Any: '',
                          Transaction: 'Transaction',
                          Merchant: 'Merchant',
                        }}
                      ></GenericDropdownFilter>
                    </div>
                  )}
                  {getKeyByValue(kycHeaders, val) === 'kyc_questions_answered' && (
                    <div>
                      {val}

                      <GenericDropdownFilter
                        filter={filter}
                        filterName='kyc_questions_answered'
                        placeholder='Answered ?'
                        setFilter={setFilter}
                        options={{ Any: '', Answered: 'true', 'Not Answered': 'false' }}
                      ></GenericDropdownFilter>
                    </div>
                  )}
                  {getKeyByValue(kycHeaders, val) === 'identity_verification_completed' && (
                    <div>
                      {val}

                      <GenericDropdownFilter
                        filter={filter}
                        filterName='identity_verification_completed'
                        placeholder='ID Verified ?'
                        setFilter={setFilter}
                        options={{ Any: '', Verified: 'true', 'Not Verified': 'false' }}
                      ></GenericDropdownFilter>
                    </div>
                  )}
                  {getKeyByValue(kycHeaders, val) === 'screening_result' && (
                    <div>
                      {val}

                      <GenericDropdownFilter
                        filter={filter}
                        filterName='screening_result'
                        placeholder='AML/PEP ?'
                        setFilter={setFilter}
                        options={{
                          Any: '',
                          Clear: 'Clear',
                          PEP: 'PEP',
                          Sanctioned: 'Sanctioned',
                          Terrorist: 'Terrorist',
                        }}
                      ></GenericDropdownFilter>
                    </div>
                  )}
                </div>
              ))}
              <div className='kyc-item'></div>
            </div>
          )}
          {!kycList.failed && !kycList.loading && kycList.data.results.length === 0 && (
            <div>No records for given query</div>
          )}

          {!kycList.failed && kycList.data && kycList.data.results && (
            <div className='kyc-content-wrapper'>
              {kycList.data.results.map((kyc, i) => {
                const authProvider = kyc.provider
                  ? `${kyc.auth_provider} (${kyc.provider})`
                  : kyc.auth_provider

                return (
                  <div key={i}>
                    <div className='kyc-content-row'>
                      {Object.keys(kycHeaders).map((key, count) => (
                        <div
                          key={count.toString() + i.toString()}
                          className={`kyc-item  ${key.includes('_id') ? 'kyc-text-id' : ''} ${
                            key.includes('_id') ? 'kyc-wide-item' : ''
                          }           ${key.includes('email') ? 'kyc-extra-wide-item' : ''}`}
                        >
                          {key === 'created_at' && (kyc[key] ? <DateTime at={kyc[key]} /> : '-')}
                          {key === 'email' && kyc['email_is_verified'] && (
                            <i className='kyc-check fas fa-check kyc-margin-right'></i>
                          )}
                          {key === 'email' && !kyc['email_is_verified'] && (
                            <i className='kyc-cross fas fa-times kyc-margin-right'></i>
                          )}
                          {key === 'kyc_questions_answered' ? (
                            !!kyc[key] ? (
                              <i className='kyc-check fas fa-check'></i>
                            ) : (
                              <i className='kyc-cross fas fa-times'></i>
                            )
                          ) : (
                            ''
                          )}
                          {key === 'init_transaction_id' && (
                            <Link
                              className='kyc-link'
                              to={`/transaction/${kyc.init_transaction_id}`}
                            >
                              {kyc[key]}
                            </Link>
                          )}
                          {key === 'merchant' && canSeeMerchants && (
                            <span>{kyc.merchant ? kyc.merchant : '-'}</span>
                          )}
                          {key === 'identity_verification_completed' ? (
                            !!kyc[key] ? (
                              <i className='kyc-check fas fa-check'></i>
                            ) : (
                              <i className='kyc-cross fas fa-times'></i>
                            )
                          ) : (
                            ''
                          )}

                          {key === 'auth_provider' ? authProvider : null}

                          {!!kyc[key] &&
                          key !== 'identity_verification_completed' &&
                          key !== 'auth_provider' &&
                          key !== 'created_at' &&
                          key !== 'kyc_questions_answered' &&
                          key !== 'init_transaction_id' &&
                          key !== 'merchant'
                            ? kyc[key]
                              ? kyc[key].toString()
                              : '-'
                            : ''}
                        </div>
                      ))}

                      <div className='kyc-item'>
                        <Link to={`/know-your-customer/${params.owner}/${kyc.email}`}>Detail</Link>
                      </div>
                    </div>
                  </div>
                )
              })}
              {kycList.loading && kycList.data.results && (
                <div className='kyc-spinner-wrapper-new-records'>
                  <LoadingSpinner></LoadingSpinner>
                </div>
              )}
              <footer className='actions kyc-actions'>
                {kycList.data.page &&
                  kycList.data.results.length !== kycList.data.page.total_count && (
                    <>
                      <button onClick={handleLoadMore} disabled={kycList.loading}>
                        {kycList.loading ? 'Loading...' : `Show ${loadableCount} more`}
                      </button>
                      {' or '}
                    </>
                  )}
                <DownloadKYCCSVLink
                  displayCount={path(['page', 'total_count'], kycList.data)}
                  filter={filter}
                  owner={params.owner}
                />
              </footer>
            </div>
          )}
        </div>
      </div>
    </section>
  )
}

export default ListKyc
